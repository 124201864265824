import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "./constant";
import { useTranslation } from 'react-i18next'; 
// import { Interweave } from "interweave";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const HomeDonation = () => {
  const {t}=useTranslation();
    
    useEffect(() => {
        fetchtext();
        fetchDonation();
       
      }, []);
  
      const fetchDonation = async (topic) => {
          try {
              let url = `${BASE_URL}/api_donations.php`;
              
              const response = await axios.get(url);
              const DonationData = response.data;
              console.log(response.data);
              if (Array.isArray(DonationData)) {
                  setPeople(DonationData);
                  
              } else {
                setPeople([]);
                  console.error('Expected an array of donations');
              }
          } catch (error) {
              console.error('Error fetching donations:', error);
          }
      };
      const fetchtext = async () => {
        try {
            let url = `${BASE_URL}/api_donations.php/torah_study`;
            
            const response = await axios.get(url);
            const DonationData = response.data.torah_text;
            console.log(response.data);
            setTorah_text(DonationData);
                
           
        } catch (error) {
            console.error('Error fetching donations:', error);
        }
    };
    const [people,setPeople] = useState([ ]);

    const [torah_text,setTorah_text] = useState("");


    return (
        <div className="bg-white bg-opacity-70 border border-gray-300 rounded-lg shadow-md p-4 my-0 md:m-4">
            <h2 className="text-xl font-bold mb-4">{torah_text}</h2>
            <ul className="overflow-auto h-[250px]">
                {people.map(person => (
                    <li key={person.id} className="border-b border-gray-200">
                        <div className=" ql-editor " dangerouslySetInnerHTML={{ __html:  person.name }} />
                        {/* {person.name} */}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default HomeDonation;
