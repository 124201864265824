import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./Stripe.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import BASE_URL from "../constant";
import LoadingPopup from "./LoadingPopup";
import { t } from "i18next";
import Subscriptions from "./Subscriptions";

const Stripepayments = () => {
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("language") || "en"
  );
  const stripePromise = loadStripe(
  //  "pk_test_d4kYCdCRe6sjnxpIDsVdnmbT00uCgqiJo1",
    "pk_live_51PP4m3RsvBKMYM9NLV1ug7mjoTIT5MJvXM2jLAko30q5puQoJFZe4v9tOvhB9YPgRyMU1YAuwbfA6UXuqjBCD9bt00FxbYo2mC",
    {
      locale: savedLang,
    }
  );

  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setSaveLang(svLang);
    console.log(savedLang);
  });
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { getUserData } = useContext(AuthContext);

  const location = useLocation();

  const product = location.state.product;
  const type = location.state.type;
  const amount = location.state.amount;
  const currency = location.state.currency ?? "usd";

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    console.log(product);
    console.log(amount);
    if (amount) {
      axios
        .post(
          "https://api.stripe.com/v1/payment_intents",
          new URLSearchParams({
            amount: (amount * 100).toFixed(0).toString(),
            currency: currency, // ils fo israel
          }),
          {
            headers: {
              Authorization:
                // "Bearer sk_test_GrTtxC7YpQN9C5kYVruFhy1J00VbrqYVla",
               "Bearer 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",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setClientSecret(response.data.client_secret);
        })
        .catch((error) => {
          console.error("Error creating PaymentIntent:", error);
        });
    }
  }, [amount]);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };
  function generateTransactionId(length = 16) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let transactionId = "OIZ-WALLET-";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      transactionId += characters[randomIndex];
    }
    return transactionId;
  }
  const insetCartOrder = async (orderData) => {
    console.log(orderData["cart_id"]);
    try {
      const response = await axios.post(
        `${BASE_URL}/api_porders.php?action=create_or_copy_order`,
        orderData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(" Cart Order successfully placed:", response.data);
      const url = `${BASE_URL}/api_cart.php?action=cart&cart_id=${orderData["cart_id"]}`;
      const response_delete = await axios.delete(url);
      console.log(response_delete);

      return response_delete.data;
      // await deletmycart();
      //  setOrderPlaced(true);
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };
  const insetOrder = async (orderData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api_orders.php`,
        orderData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Order successfully placed:", response.data);
      return response.data;
      //  setOrderPlaced(true);
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };
  const paywithwallet = async () => {
    const svLang = localStorage.getItem("currency");
    if (svLang !== "ils") {
      setLoading(true);
      const productid =
        type === "subscription"
          ? product?.id
          : type === "cart"
          ? product.id
          : product.id;
      const userdata = getUserData();
      const tranx_id = generateTransactionId();

      axios
        .post(`${BASE_URL}/api_wallet.php`, {
          user_id: userdata.id,
          amount: amount,
          transaction_type: "debit",
          transaction_id: tranx_id,
          transaction_msg: `${product.title} Purchased`,
        })
        .then(async (response) => {
          if (response.data.message == "Transaction successful") {
            let orderData;
            let ret_val;
            if (type === "subscription") {
              orderData = {
                user_id: userdata.id, // Replace with dynamic user data if available
                total_price: amount,
                transaction_id: tranx_id,
                order_details: [
                  {
                    book_id: null,
                    volume_id: null,
                    subscription_id: productid,
                    bundle_id: null,
                    price: amount,
                  },
                ],
              };
              ret_val = await insetOrder(orderData);
            } else {
              orderData = {
                user_id: userdata.id, // Replace with dynamic user data if available
                cart_id: productid,
                transaction_id: tranx_id,
                sub_total: amount,
                final_amount: amount,
                currency: currency,
                payment_mode: "Wallet",
              };
              
              ret_val = await insetCartOrder(orderData);
            }
            console.log(`order : ${orderData}`);
            console.log(ret_val);
            if (ret_val.message != null) {
              setLoading(false);

              sendThankuEmail({
                email: userdata.email,
                name: userdata.username,
                orderId:
                  type === "subscription"
                    ? "Subscriptions purchased"
                    : type === "cart"
                    ? product.title || "Print Purchase"
                    : product.title || "Print Purchase",
                totalAmount: amount.toString(),
                currency: currency === "usd" ? "$" : "₪",
              });
              navigate("/thank-you/true/");
            }
            // Assuming success response contains a success flag

            // navigate('//User' );
          } else {
            setLoading(false);
            console.error(
              response.data.message || "Error transacting from wallet"
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error transacting from wallet");
        });
    } else {
      alert("Wallet Payment is not available for the Selected Currency");
    }
  };
  const sendThankuEmail = async (data) => {
    const emaildata = JSON.stringify({
      email: data.email,
      name: data.name,
      orderId: data.orderId,
      totalAmount: data.totalAmount,
      currency: data.currency,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://oizvehadar.com/mailjs/send-thank-you-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: emaildata,
    };

    try {
      const response = await axios.request(config);
      console.log(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <br />
      <br /> <br />
      <div className="flex justify-center items-center absolute left-1/2 transform -translate-x-1/2">
        {type !== "wallet-top-up" && type !== "donation" && getUserData().id && (
          <div
            className="dialog-box flex justify-center items-center cursor-pointer"
            onClick={() => {
              paywithwallet();
            }}
          >
            <svg
              width="80px"
              height="80px"
              viewBox="0 0 1024 1024"
              className="icon ml-4 mr-4"
              class="icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M224 295.68c-42.24 0-64-21.76-64-64s21.76-64 64-64h665.6s25.6 0 25.6 25.6v102.4h-691.2z"
                fill="#C89005"
              />
              <path
                d="M915.2 308.48h-691.2c-49.92 0-76.8-26.88-76.8-76.8s26.88-76.8 76.8-76.8h665.6c15.36 0 38.4 10.24 38.4 38.4v102.4c0 7.68-6.4 12.8-12.8 12.8z m-25.6-128h-665.6c-35.84 0-51.2 15.36-51.2 51.2s15.36 51.2 51.2 51.2h678.4v-89.6c0-10.24-7.68-12.8-12.8-12.8z"
                fill="#231C1C"
              />
              <path
                d="M907.52 494.08c2.56 14.08-6.4 26.88-20.48 30.72l-601.6 122.88c-14.08 2.56-26.88-6.4-30.72-20.48l-74.24-363.52c-2.56-14.08 6.4-26.88 20.48-30.72l601.6-122.88c14.08-2.56 26.88 6.4 30.72 20.48l74.24 363.52z"
                fill="#C11F29"
              />
              <path
                d="M280.32 660.48c-17.92 0-33.28-12.8-37.12-30.72l-74.24-363.52c-2.56-10.24 0-20.48 5.12-29.44 5.12-8.96 14.08-14.08 24.32-16.64l601.6-122.88c20.48-3.84 40.96 8.96 44.8 29.44l74.24 363.52c3.84 20.48-8.96 40.96-29.44 44.8l-601.6 122.88c-2.56 2.56-5.12 2.56-7.68 2.56z m527.36-537.6h-2.56l-601.6 122.88c-6.4 1.28-11.52 7.68-10.24 15.36l74.24 363.52c1.28 6.4 7.68 11.52 15.36 10.24l601.6-122.88c3.84-1.28 6.4-2.56 7.68-5.12 1.28-2.56 2.56-6.4 1.28-10.24L819.2 133.12c0-6.4-5.12-10.24-11.52-10.24z"
                fill="#231C1C"
              />
              <path
                d="M860.16 263.68c1.28 8.96-7.68 19.2-21.76 21.76l-601.6 122.88c-14.08 2.56-26.88-2.56-28.16-11.52l-8.96-42.24c-1.28-8.96 7.68-19.2 21.76-21.76l601.6-122.88c14.08-2.56 26.88 2.56 28.16 11.52l8.96 42.24z"
                fill="#513328"
              />
              <path
                d="M229.12 422.4c-16.64 0-30.72-8.96-33.28-23.04l-8.96-40.96c-3.84-16.64 10.24-33.28 32-37.12l601.6-122.88c20.48-3.84 39.68 5.12 43.52 21.76l8.96 42.24c3.84 16.64-10.24 33.28-32 37.12l-601.6 122.88h-10.24z m601.6-199.68h-5.12l-601.6 122.88c-7.68 1.28-11.52 5.12-11.52 7.68l8.96 40.96c1.28 1.28 6.4 2.56 12.8 1.28l601.6-122.88c7.68-1.28 11.52-5.12 11.52-6.4l-8.96-40.96c0-1.28-2.56-2.56-7.68-2.56zM513.1136 482.8928l112.8448-23.1808 5.1584 25.088-112.8576 23.168zM448.0768 548.2752l263.3216-54.08 5.1456 25.088-263.3216 54.08z"
                fill="#231C1C"
              />
              <path
                d="M839.68 686.08c-3.84 14.08-17.92 21.76-32 16.64L183.04 512c-12.8-3.84-20.48-17.92-16.64-32l112.64-367.36c3.84-14.08 17.92-21.76 32-16.64l625.92 190.72c12.8 3.84 20.48 17.92 16.64 32l-113.92 367.36z"
                fill="#B8CA43"
              />
              <path
                d="M815.36 718.08c-3.84 0-7.68 0-11.52-1.28L179.2 524.8c-10.24-2.56-17.92-10.24-23.04-19.2-5.12-8.96-5.12-19.2-2.56-29.44l112.64-367.36c6.4-20.48 26.88-32 47.36-25.6l625.92 190.72c20.48 6.4 30.72 28.16 25.6 47.36l-112.64 367.36c-2.56 10.24-8.96 17.92-17.92 23.04-6.4 3.84-12.8 6.4-19.2 6.4z m-512-610.56c-5.12 0-10.24 3.84-11.52 8.96L179.2 483.84c-1.28 3.84-1.28 6.4 1.28 10.24 1.28 2.56 3.84 5.12 7.68 6.4L812.8 691.2c2.56 1.28 6.4 0 8.96-1.28 2.56-1.28 5.12-3.84 6.4-7.68l112.64-367.36c2.56-6.4-1.28-14.08-7.68-15.36l-627.2-190.72c-1.28-1.28-1.28-1.28-2.56-1.28z"
                fill="#231C1C"
              />
              <path
                d="M806.4 582.4l60.16-195.84c-26.88-7.68-40.96-37.12-33.28-64L375.04 183.04c-7.68 26.88-37.12 42.24-62.72 34.56l-60.16 195.84c26.88 7.68 40.96 37.12 33.28 64L742.4 616.96c8.96-26.88 37.12-42.24 64-34.56z"
                fill="#B8CA43"
              />
              <path
                d="M751.36 632.32L270.08 485.12l3.84-12.8c2.56-10.24 2.56-20.48-2.56-29.44-5.12-8.96-12.8-15.36-21.76-17.92l-12.8-3.84 67.84-220.16 12.8 3.84c19.2 6.4 40.96-5.12 47.36-25.6l3.84-12.8 481.28 147.2-3.84 12.8c-2.56 10.24-2.56 20.48 2.56 29.44 5.12 8.96 12.8 15.36 21.76 17.92l12.8 3.84-67.84 220.16-12.8-3.84c-19.2-6.4-40.96 5.12-47.36 25.6l-3.84 12.8zM300.8 468.48l435.2 133.12c11.52-23.04 35.84-35.84 61.44-33.28l53.76-174.08c-10.24-6.4-19.2-15.36-25.6-25.6-6.4-11.52-7.68-23.04-6.4-35.84L384 199.68c-11.52 23.04-37.12 35.84-61.44 33.28L268.8 405.76c10.24 6.4 19.2 15.36 25.6 25.6 5.12 11.52 7.68 24.32 6.4 37.12z"
                fill="#231C1C"
              />
              <path
                d="M506.440538 571.150982a163.84 179.2 17.002 1 0 104.797983-342.735967 163.84 179.2 17.002 1 0-104.797983 342.735967Z"
                fill="#E1E0A6"
              />
              <path
                d="M550.4 590.08c-15.36 0-32-2.56-47.36-7.68-44.8-14.08-81.92-46.08-103.68-90.88-20.48-44.8-24.32-96-8.96-144.64 15.36-48.64 46.08-89.6 88.32-113.92 42.24-25.6 90.88-32 135.68-17.92 93.44 28.16 143.36 134.4 112.64 235.52-24.32 84.48-98.56 139.52-176.64 139.52z m16.64-355.84c-25.6 0-51.2 7.68-75.52 21.76-37.12 21.76-64 57.6-76.8 99.84s-10.24 87.04 7.68 125.44c17.92 38.4 48.64 65.28 87.04 76.8 79.36 24.32 166.4-26.88 193.28-115.2 26.88-88.32-16.64-179.2-96-203.52-11.52-2.56-25.6-5.12-39.68-5.12z"
                fill="#231C1C"
              />
              <path
                d="M494.2464 567.4112l104.6528-342.784 24.4864 7.4752-104.6528 342.784z"
                fill="#231C1C"
              />
              <path
                d="M540.16 537.6c-7.68 0-14.08-1.28-21.76-3.84-20.48-6.4-37.12-20.48-46.08-39.68-7.68-17.92-8.96-37.12-3.84-55.04l24.32 7.68c-3.84 12.8-2.56 25.6 2.56 37.12 6.4 12.8 16.64 23.04 30.72 26.88 26.88 7.68 55.04-7.68 62.72-34.56 7.68-26.88-6.4-55.04-33.28-64-39.68-12.8-62.72-55.04-49.92-96 6.4-19.2 19.2-35.84 37.12-44.8 17.92-10.24 38.4-11.52 57.6-6.4 19.2 6.4 35.84 19.2 44.8 37.12 8.96 17.92 11.52 38.4 5.12 58.88l-24.32-7.68c3.84-12.8 2.56-26.88-3.84-38.4-6.4-11.52-16.64-20.48-29.44-24.32-12.8-3.84-26.88-2.56-38.4 3.84-11.52 6.4-20.48 16.64-24.32 30.72-7.68 26.88 6.4 55.04 33.28 64 39.68 12.8 62.72 55.04 49.92 96-10.24 30.72-40.96 52.48-72.96 52.48z"
                fill="#231C1C"
              />
              <path
                d="M953.6 782.08c0 14.08-11.52 25.6-25.6 25.6h-742.4c-14.08 0-25.6-11.52-25.6-25.6v-550.4c0 38.4 25.6 64 64 64h704c14.08 0 25.6 11.52 25.6 25.6v460.8z"
                fill="#EEBE00"
              />
              <path
                d="M928 820.48h-742.4c-21.76 0-38.4-16.64-38.4-38.4v-550.4c0-7.68 5.12-12.8 12.8-12.8s12.8 5.12 12.8 12.8c0 30.72 20.48 51.2 51.2 51.2h704c21.76 0 38.4 16.64 38.4 38.4v460.8c0 21.76-17.92 38.4-38.4 38.4z m-755.2-529.92v491.52c0 7.68 5.12 12.8 12.8 12.8h742.4c7.68 0 12.8-5.12 12.8-12.8v-460.8c0-7.68-5.12-12.8-12.8-12.8h-704c-20.48 0-38.4-6.4-51.2-17.92z"
                fill="#231C1C"
              />
              <path
                d="M953.6 615.68H803.84c-30.72 0-55.04-23.04-55.04-51.2v-25.6c0-28.16 24.32-51.2 55.04-51.2h151.04v128z"
                fill="#C89005"
              />
              <path
                d="M953.6 628.48H803.84c-37.12 0-67.84-28.16-67.84-64v-25.6c0-35.84 30.72-64 67.84-64h151.04c7.68 0 12.8 5.12 12.8 12.8v128c-1.28 7.68-6.4 12.8-14.08 12.8z m-149.76-128c-23.04 0-42.24 16.64-42.24 38.4v25.6c0 21.76 19.2 38.4 42.24 38.4h138.24v-102.4H803.84z"
                fill="#231C1C"
              />
              <path d="M226.56 346.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M328.96 346.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M431.36 346.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M533.76 346.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M636.16 346.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M738.56 346.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M840.96 346.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M226.56 730.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M328.96 730.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M431.36 730.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M533.76 730.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M636.16 730.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M738.56 730.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path d="M840.96 730.88h61.44v25.6h-61.44z" fill="#231C1C" />
              <path
                d="M812.8 551.68m-25.6 0a25.6 25.6 0 1 0 51.2 0 25.6 25.6 0 1 0-51.2 0Z"
                fill="#231C1C"
              />
            </svg>
            {/* <p className="text-xl mb-2">Pay With Wallet</p> */}
            <p className="text-xl mb-2 font-bold mr-4">{t("paywithwallet")}</p>
          </div>
        )}
      </div>
      <br />
      <br /> <br />
      <div className="relative w-full">
        {clientSecret ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              product={product}
              type={type}
              amount={amount}
              currency={currency}
            />
          </Elements>
        ) : (
          <div className="flex justify-center items-center min-h-screen space-x-4">
            {amount <= 2 ? (
              <div className="text-red-500 text-lg mb-4 flex justify-center items-center cursor-pointer border border-gray-300 bg-gray-100 px-8 py-4 rounded w-full max-w-lg">
                <div>
                  <h1 className="font-bold text-xl mb-2">{t("disclaimer")}</h1>
                  <p>{t("disclaimer_payment")}</p>
                </div>
              </div>
            ) : (
              <>
                {/* Progress Circle */}
                <div className="w-12 h-12 border-4 border-t-4 border-blue-500 rounded-full animate-spin"></div>

                {/* Loading Text */}
                <p className="text-xl tilt-neon animate-pulse">
                  {t("loading")}
                </p>
              </>
            )}
          </div>
        )}
        {isLoading && <LoadingPopup />}
      </div>
    </div>
  );
};

export default Stripepayments;
