import React, { useEffect, useState } from "react";
import { FaTelegramPlane, FaEnvelope, FaWhatsapp, FaDownload } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import BASE_URL from "../constant";
import axios from "axios";

const DownloadCatalogue = () => {
  const [catalog, setCatalog] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [alllang,setAllLang]=useState(localStorage.getItem("language")=== "en" ? "All" : "הכל"||"All")
 
  const { t } = useTranslation();

  useEffect(() => {
   
    fetchCatalog();
    // fetchCategories();
  }, []);
 

 
  const fetchCatalog = async () => {
    try {
      let url = `${BASE_URL}/api_catelog.php`;
      const response = await axios.get(url);
      const bookData = response.data.catalog;


      setCatalog(bookData);
      extractCategories(bookData);
    
    } catch (error) {
      console.error("Error fetching catalog:", error);
    }
  };
  const extractCategories = (books) => {
    const allCategories = books.reduce((acc, book) => {
      if (book.categories && Array.isArray(book.categories)) {
        book.categories.forEach((category) => {
          if (!acc.includes(category)) {
            acc.push(category);
          }
        });
      }
      return acc;
    }, []);
    
    setCategories([alllang, ...allCategories]); // Add default option and update state
  };

    useEffect(() => {
      const svLang = localStorage.getItem("language");
      setAllLang(svLang === "en" ? "All" : "הכל");
      setCategories((prevCategories) => {
        const otherCategories = prevCategories.slice(1); // Exclude the first category
        return [svLang === "en" ? "All" : "הכל", ...otherCategories];
      });
    });
  const shareOnWhatsApp = (title, link) => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      title
    )}%20${encodeURIComponent(link)}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnTelegram = (title, link) => {
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
      link
    )}&text=${encodeURIComponent(title)}`;
    window.open(telegramUrl, "_blank");
  };

  const shareViaEmail = (title, link) => {
    const emailUrl = `mailto:?subject=${encodeURIComponent(
      title
    )}&body=${encodeURIComponent(link)}`;
    window.open(emailUrl, "_blank");
  };

  const handleDownload = (item) => {
    const link = document.createElement("a");
    link.href = item.file_path;
    link.download = item.title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredCatalog = selectedCategory === "All"
    ? catalog
    : catalog.filter((item) => item.categories?.includes(selectedCategory));

  return (
    <>
      <div className="py-[40px] max-w-[400px] m-auto grid ">
        {/* <form className="w-full "> */}
          <select
            id="categories"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        {/* </form> */}
      </div>

      <div className="m-auto text-center">
        <button
          type="button"
          className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2"
          onClick={() => setSelectedCategory("All")}
        >
          {t("clear")}
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-[90%] m-auto max-w-[1600px]">
        {filteredCatalog.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-4 border rounded-lg shadow"
          >
            <img
              src={item.image}
              alt={item.title}
              className="mb-4 w-full h-100 object-cover rounded"
            />

            <div className="grid grid-cols-4 gap-2 mb-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-red-900 rounded-full text-white"
                onClick={() => shareOnTelegram(item.title, item.file_path)}
              >
                <FaTelegramPlane />
              </a>
              <a
                className="p-2 bg-gray-800 rounded-full text-white"
                onClick={() => shareViaEmail(item.title, item.file_path)}
              >
                <FaEnvelope />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-green-500 rounded-full text-white"
                onClick={() => shareOnWhatsApp(item.title, item.file_path)}
              >
                <FaWhatsapp />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-[#fde5c3c4] rounded-full text-black"
                onClick={() => handleDownload(item)}
              >
                <FaDownload />
              </a>
            </div>

            <hr className="border-dotted border-gray-300 w-full mt-1 mb-2" />

            <h3 className="text-lg font-semibold">{item.title}</h3>
          </div>
        ))}
      </div>
    </>
  );
};

export default DownloadCatalogue;
