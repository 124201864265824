import React from "react";


  export function Input({ className, ...props }) {
    return (
      <input
        className={`border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
        {...props}
      />
    );
  }


  
  export function Button({ className, children, ...props }) {
    return (
      <button
        className={`bg-red-800 text-white py-2 px-4 rounded-md hover:bg-red-700 transition ${className}`}
        {...props}
      >
        {children}
      </button>
    );
  }
 

  
 


  export function Select({ className, children, ...props }) {
    return (
      <div className={`relative ${className}`}>
        <select
          className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          {...props}
        >
          {children}
        </select>
      </div>
    );
  }
  
  export function SelectTrigger({ className, children }) {
    return (
      <div className={`border border-gray-300 p-2 rounded-md cursor-pointer ${className}`}>
        {children}
      </div>
    );
  }
  
  export function SelectValue({ value, placeholder }) {
    return <span>{value || placeholder}</span>;
  }
  
  export function SelectContent({ className, children }) {
    return <div className={`absolute bg-white border rounded-md shadow-lg ${className}`}>{children}</div>;
  }
  
  export function SelectItem({ value, children, onSelect }) {
    return (
      <div
        className="p-2 hover:bg-gray-100 cursor-pointer"
        onClick={() => onSelect && onSelect(value)}
      >
        {children}
      </div>
    );
  }

  
  export function Card({ className, children }) {
    return <div className={`border rounded-lg shadow-md p-6 bg-white ${className}`}>{children}</div>;
  }
  
  export function CardHeader({ className, children }) {
    return <div className={`border-b pb-2 mb-4 text-xl font-semibold ${className}`}>{children}</div>;
  }
  
  export function CardContent({ className, children }) {
    return <div className={className}>{children}</div>;
  }
  
  export function CardTitle({ className, children }) {
    return <h2 className={`text-xl font-bold ${className}`}>{children}</h2>;
  }
  

  // export {Card,CardContent,Button}