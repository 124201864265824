import axios from "axios";
import { t } from "i18next";
import React, { useState } from "react";
import { FaEnvelope } from 'react-icons/fa';
import { toast, ToastContainer } from "react-toastify";


const Forget = () => {
  const [email, setEmail] = useState('');

 

  const handleResetPassword = async () => {
   
      console.log("reset email sent to " + email);
      const emaildata = JSON.stringify({
        email: email,
      
      });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://oizvehadar.com/mailjs/generate-reset-link',
        headers: {
          'Content-Type': 'application/json'
        },
        data: emaildata
      };
  
      try {
        const response = await axios.request(config);
        console.log(response.data);
        toast.success('reset email sent to:',  email);
      } catch (error) {
        console.error(error.message);
        toast.error('Error sending password reset email:', error.message);
      } 
      
    
  };
 

  return (
    <>
       <div className="block md:flex items-center">
      <ToastContainer />
     
     
     <div className="md:flex hidden  items-center md:flex-col">
    
         {/* Image */}
         <img
           src="../images/resetpass.svg"
           alt="login"
           className="w-full  h-full lg::w-[44%] lg:h-[832px] object-contain md:w-full"
         />
       </div>
        <div className="w-[95%] lg:w-1/2 bg-[#ffffff] rounded-2xl shadow-xl border border-gray-300 rounded-lg shadow-md p-4 m-4">
        <div
                  className="w-full h-[100px] "
                  // style={{
                  //   boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                  // }}
                >
                  <img
                    src="https://oizvehadar.com/api/uploads/images/logo.png"
                    className="rounded-t-2xl w-full h-full object-contain  "
                    alt="logo"
                  />
                </div>
          <h1 className="text-center text-[40px]">{t("forget_pass")}</h1>
          <div className="mt-4">
            {/* Email input with icon */}
            <div className="relative">
              <FaEnvelope className="absolute top-5 left-3 text-red-900" />
              <input
                type="email"
                placeholder={t("email")}
                name="email_One"
                    
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                className="pl-10 w-full h-14 px-4 mb-3 border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
              />
            </div>
           
            {/* Submit button */}
            <div className="flex justify-center items-center ">
            <button
              className="bg-red-800 text-white rounded-[14px] hover:bg-red-900 min-w-[154px] tracking-[0.44px] px-[34px] py-4"  onClick={ handleResetPassword}>
            {t("submit")}
            </button>
            </div>
          </div>
        </div>
        <div className="md:hidden block  items-center md:flex-col">
    
         {/* Image */}
         <img
           src="../images/resetpass.svg"
           alt="login"
           className="w-full  h-full lg::w-[44%] lg:h-[832px] object-contain md:w-full"
         />
       </div>
      </div>
    </>
  );
};

export default Forget;
