import React, { useState, useEffect, useContext } from "react";
import { FiUser, FiBook, FiList, FiEdit, FiLogOut } from "react-icons/fi";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { FaHistory, FaHeart } from "react-icons/fa";
import AboutUser from "./AboutUser";
import MyBooks from "./MyBooks";
import Phistory from "./Phistory";
import { useNavigate, useParams } from "react-router-dom";
import Notes from "./Notes";

import { signOut } from "../services/AuthServices";
import MySubs from "./Mysubscription";
import { useTranslation } from "react-i18next";
import MyFavorite from "./MyFavorite";
import Myorder from "./Myorders";
import MyPrintables from "./Myprintables";
import { AiFillWallet } from "react-icons/ai";
import WalletPage from "./walletPage";
import MyNotifications from "./MyNotifications";
import { BiBell } from "react-icons/bi";
import { AuthContext } from "../context/AuthContext";

const tabMapping = {
  1: "about-me",
  10: "notifications",
  9: "wallet",
  2: "my-collection",
  7: "favorites",
  6: "subscriptions",
  3: "history",
  8: "orders",
  4: "my-printables",
  5: "logout",
};

const reverseTabMapping = Object.fromEntries(
  Object.entries(tabMapping).map(([key, value]) => [value, Number(key)])
);

const User = () => {
  const { emptyCurrentUser } = useContext(AuthContext);
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  //const navigate = useNavigate();

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    navigate(`/User/${tabMapping[tabNumber]}`);
  };

  useEffect(() => {
    if (id) {
      const tabNumber = reverseTabMapping[id] || 1; // Default to 1 if no match
      setActiveTab(tabNumber);
    }
  }, [id]);
  useEffect(() => {
    // console.log(id);
    if (activeTab === 5) {
      emptyCurrentUser();
      signOuts();
    }
  }, [activeTab]);

  const signOuts = async () => {
    await signOut(navigate);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="flex w-full ">
        <div
          className="shadow w-[60px] h-[800px] md:w-[250px] md:h-[800px]"
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#7f1d1d",
            borderRadius: "25px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px"
          }}
        >
          <div>
            <div
              onClick={() => handleTabClick(1)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 1 ? "black" : "white",
                background: activeTab === 1 ? "#FDEBD1" : "transparent",
              }}
            >
              <FiUser size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("about_me")}
              </span>
            </div>
            <div
              onClick={() => handleTabClick(10)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 10 ? "black" : "white",
                background: activeTab === 10 ? "#FDEBD1" : "transparent",
              }}
            >
              <BiBell size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("notifications")}
              </span>
            </div>
            <div
              onClick={() => handleTabClick(9)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 9 ? "black" : "white",
                background: activeTab === 9 ? "#FDEBD1" : "transparent",
              }}
            >
              <AiFillWallet size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("my_wallet")}
              </span>
            </div>
            {/* <div
              onClick={() => handleTabClick(2)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 2 ? "black" : "white",
                background: activeTab === 2 ? "#FDEBD1" : "transparent",
              }}
            >
              <FiBook size={20} />
              <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("MyCollection")}
              </span>
            </div> */}
            <div
              onClick={() => handleTabClick(7)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 7 ? "black" : "white",
                background: activeTab === 7 ? "#FDEBD1" : "transparent",
              }}
            >
              <FaHeart size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("MyFavorite")}
              </span>
            </div>
            <div
              onClick={() => handleTabClick(6)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 6 ? "black" : "white",
                background: activeTab === 6 ? "#FDEBD1" : "transparent",
              }}
            >
              <FiList size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("my_subscriptions")}
              </span>
            </div>
            <div
              onClick={() => handleTabClick(3)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 3 ? "black" : "white",
                background: activeTab === 3 ? "#FDEBD1" : "transparent",
              }}
            >
              <FaHistory size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("phistory")}
              </span>
            </div>
            <div
              onClick={() => handleTabClick(8)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 8 ? "black" : "white",
                background: activeTab === 8 ? "#FDEBD1" : "transparent",
              }}
            >
              <BsFillBoxSeamFill size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("myorder")}
              </span>
            </div>
            <div
              onClick={() => handleTabClick(4)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 4 ? "black" : "white",
                background: activeTab === 4 ? "#FDEBD1" : "transparent",
              }}
            >
              <FiEdit size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("my_prinable")}
              </span>
            </div>
            <div
              onClick={() => handleTabClick(5)}
              style={{
                padding: "15px",
                cursor: "pointer",
                display: "flex",
                color: activeTab === 5 ? "black" : "white",
                background: activeTab === 5 ? "#FDEBD1" : "#450A0A",
              }}
            >
              <FiLogOut size={20} />
              <span className="md:block hidden" style={{ marginLeft: "10px", marginRight: "10px" }}>
                {t("Logout")}
              </span>
            </div>
          </div>
        </div>

        <div className="profile-right w-[70%] m-auto mt-10">
          {activeTab === 1 && <AboutUser />}
          {activeTab === 2 && <MyBooks />}
          {activeTab === 7 && <MyFavorite />}
          {activeTab === 3 && <Phistory />}
          {activeTab === 4 && <MyPrintables />}
          {activeTab === 6 && <MySubs />}
          {activeTab === 8 && <Myorder />}
          {activeTab === 9 && <WalletPage />}
          {activeTab === 10 && <MyNotifications />}
        </div>
      </div>
    </>
  );
};

export default User;
