import React, { useState, useContext, useEffect } from "react";
import { MdAccountCircle } from "react-icons/md";
import { Routes, Route, NavLink, Link, useNavigate } from "react-router-dom";
import { ImSearch, ImCross } from "react-icons/im";
import Home from "./Home";
import Login from "./Pages/Login";
import Forget from "./Pages/Forget";
import Signup from "./Pages/Signup";
import Collection from "./Pages/Collections";
import Product from "./Pages/Product";

import Contact from "./Pages/Contact";
import ReactDOM from "react-dom";
import GlobeSearch from "./Globe-search";
import Bundles from "./Pages/Bundles";
import User from "./User/User";
import DownloadCatalogue from "./Pages/DownloadCatalogue";
import Donate from "./Pages/Donate";
import BASE_URL from "./constant";
import PDFViewer from "./Pages/PdfViewer";
import Subscriptions from "./Pages/Subscriptions";

import { AuthContext } from "./context/AuthContext";
import Stripepayments from "./Pages/Stripe";
import ThankYouPage from "./Pages/thankyou";
import LanguageSelectorComponent from "./LanguageSelector";
import AjaxCart from "./Ajaxcart";
import { useTranslation } from "react-i18next";
import LanguageCurrencySelect from "./language-selector";
import Privacy from "./Pages/Privacy";
import Checkout from "./Pages/Checkout";
import PrivateRoute from "./privateRoute";
import WalletPage from "./User/walletPage";
import axios from "axios";
import Reset from "./Pages/Reset";
import { BiSupport } from "react-icons/bi";
import UploadBooksForm from "./Pages/UserInsertBook";
import { FaTimes } from "react-icons/fa";
import SearchComponent from "./searchcomponent";

const Navbar = () => {
  const { currentUser, getUserData } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState("English");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [balance, setBalance] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("language") || "he"
  );
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const fontSize =
    windowWidth < 640
      ? "text-[8px]"
      : windowWidth < 1024
      ? "text-[10px]"
      : "text-[12px]";
  const history = useNavigate();
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const navLinks = [
    { path: "/", label: "Home" },
    { path: "/Collections", label: "books_catalogue" },
    { path: "/Bundles", label: "bundles" },
    { path: "/Subscriptions", label: "subscriptions" },
    { path: "/DownloadCatalogue", label: "catalogue" },
    { path: "/Donate", label: "donate" },
    { path: "/UserInsert", label: "list_book" },
  ];
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setSaveLang(svLang);
    console.log(savedLang);
  });
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [msg, setmsg] = useState("");
  useEffect(() => {
    const userdata = getUserData();

    // Fetch wallet balance using Axios
    axios
      .get(`${BASE_URL}/api_wallet.php?user_id=${userdata.id}`, {
        params: { user_id: userdata.id }, // Send user ID as a query parameter
      })
      .then((response) => {
        setBalance(response.data.balance); // Assuming the response contains a 'balance' field
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching wallet balance");
        setLoading(false);
      });
  }, [getUserData, currentUser]);
  // Notifier for authentication state changes
  useEffect(() => {
    if (currentUser) {
      console.log("User logged in:", currentUser);
    } else {
      console.log("User logged out");
    }
  }, [currentUser]);

  // Notifier for wallet balance changes
  useEffect(() => {
    if (balance !== null) {
      console.log("Wallet balance updated:", balance);
    }
  }, [balance]);
  const { t } = useTranslation();
  const [issearchOpen, setIssearchOpen] = useState(false);

  const togglesearchPopup = () => {
    setIssearchOpen(!issearchOpen);
  };
  return (
    <>
      <div
        className="flex justify-between items-center bg-[#fde5c3c4] shadow-md text-white px-2 p-2"
        style={{ boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px" }}
      >
        {/* Left side logo */}
        <NavLink
          to="/"
          className="flex-col hidden md:flex items-center mb-2 group"
        >
          <div className="logo">
            <img
              src="https://oizvehadar.com/api/uploads/images/logo.png"
              className="h-[80px]"
              alt="logo"
            />
          </div>
        </NavLink>
        <div class="w-[20%] block md:hidden">
          <button
            className="md:hidden text-red-900"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <span className="text-2xl">☰</span>
          </button>
        </div>
        {/* Middle navigation */}
        <div className="navigation hidden md:flex flex-grow justify-center mb-4 sm:mb-0">
          {navLinks.map(({ path, label }) => (
            <NavLink
              key={path}
              to={path}
              className={({ isActive }) =>
                path === "/DownloadCatalogue"
                  ? ` mr-2 px-4 h-[40px] rounded-full transform hover:scale-110 transition-colors 
             flex items-center justify-center bg-red-900 hover:bg-red-950 ${fontSize} 
             ${isActive ? "bg-red-950 text-white" : "text-white"} 
             whitespace-nowrap flex-shrink-0 text-center min-w-max`
                  : `mr-2 w-[120px] h-[40px] rounded-full transform hover:scale-110 transition-colors 
             flex items-center justify-center bg-red-900 hover:bg-red-950 ${fontSize} 
             ${isActive ? "bg-red-950 text-white" : "text-white"}`
              }
            >
              {t(label)}
            </NavLink>
          ))}
        </div>
        {/* Middle side logo */}
        <div class="w-[60%] block md:hidden">
          <NavLink
            to="/"
            className="flex-col flex md:hidden items-center mb-2 group"
          >
            <div className="logo">
              <img
                src="https://oizvehadar.com/api/uploads/images/logo.png"
                className="h-[80px]"
                alt="logo"
              />
            </div>
          </NavLink>
        </div>
        {!currentUser && (
          <>
            {/* <NavLink to="/Pages/Contact" className="mr-4 px-4 py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">Contact us</NavLink> */}
            <NavLink
              // to="/Login"
              className="mr-2 px-4 hidden md:block text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
              onClick={() => {
                setmsg("Login");
                setIsOpen(true);
              }}
            >
              <span className=" text-[12px] text-des w-auto" onC>
                {t("login")}
              </span>
            </NavLink>
            <NavLink
              className="mr-4 px-4 hidden md:block text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
              // to="/Signup"
              onClick={() => {
                setmsg("Signup");
                setIsOpen(true);
              }}
            >
              <span className="text-[12px] text-des w-auto">{t("sign")}</span>
            </NavLink>
          </>
        )}

        {currentUser && (
          <>
            <div className="md:block hidden">
              <NavLink to="/User">
                <MdAccountCircle
                  className={`${
                    savedLang === "en" ? "ml-7" : "mr-7"
                  } h-[40px] w-[40px]`}
                  style={{ color: "000" }}
                />

                <div className="w-[100px] h-[25px] rounded-full bg-green-600 transform hover:scale-110 hover:bg-green-700 transition-colors flex items-center justify-center text-center text-[14px] font-bold">
                  <NavLink to="/User/wallet">
                    {loading ? (
                      <span className="balance-text">{t("loading")}</span>
                    ) : error ? (
                      <span className="balance-text error">{error}</span>
                    ) : (
                      <span className="balance-text">${balance}</span>
                    )}
                  </NavLink>
                </div>
              </NavLink>
            </div>
          </>
        )}
        {/* Mobile */}
        <div className="relative w-[20%] md:hidden flex items-center justify-end">
          <div
            className={`flex flex-col items-center ${
              savedLang === "en" ? "mr-2" : "ml-2"
            }`}
          >
            <NavLink
              to="/Contact"
              className="flex hidden md:flex flex-col items-center mb-2 group"
            >
              <div className="h-[30px] w-[30px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center">
                <BiSupport className="h-[24px] w-[24px] text-white group-hover:text-white" />
              </div>
            </NavLink>
            <div className="hidden md:flex">
              <LanguageCurrencySelect />
            </div>
          </div>
          <button
            onClick={togglesearchPopup}
            className="px-4 py-2 ml-2 font-semibold text-white bg-red-800 rounded-lg hover:bg-black focus:outline-none"
          >
            <ImSearch />
          </button>
          <AjaxCart />
        </div>

        {issearchOpen && (
          <div
            className="fixed z-40 inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center popup-slide-in  fade-in"
            onClick={togglesearchPopup}
          >
            <div
              className="bg-white  w-[90%] bg-opacity-50 backdrop-blur-md  p-8 relative rounded-lg shadow-lg popup-fade-in"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={togglesearchPopup}
                className="px-4 absolute right-0 top-4 py-2 text-red-900 rounded-lg hover:text-black"
              >
                <ImCross />
              </button> 
              <div className=" py-8 mt-0 w-full md:w-[100%] m-auto rounded-md">
      <p class="text-center text-black">{t('welcome_desc')}</p>
      {/* <div className="flex w-full md:my-8 my-4 "> */}
       {/* <div className="w-[100%]"> */}
        <SearchComponent setclose={setIssearchOpen}/>
        {/* <ReactSearchAutocomplete
          items={searchResults}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          autoFocus
          placeholder={t("search_book")}
          showItemsOnFocus
          styling={{
            borderRadius: "12px", //
            hoverBackgroundColor: "#eee",
            boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
          }}
          className="rounded-l-full shadow-slate-100 sreach-book"
          formatResult={formatResult}
        /> */}
        {/* </div> */}
     
      {/* </div> */}

    </div>
              {/* <GlobeSearch setclose={ setIssearchOpen} /> */}
            </div>
          </div>
        )}
        {/* desktop */}
        <div className="relative w-[20%] hidden md:flex items-center">
          <div
            className={`flex flex-col items-center ${
              savedLang === "en" ? "ml-2" : "mr-2"
            }`}
          >
            <NavLink
              to="/Contact"
              className="flex  flex-col items-center mb-2 group"
            >
              <div className="h-[30px] w-[30px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center">
                <BiSupport className="h-[24px] w-[24px] text-white group-hover:text-white" />
              </div>
              <div className="w-[80px] h-[20px]  rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center text-[10px] font-bold text-white mt-1">
                <span className="balance-text">{t("contact")}</span>
              </div>
            </NavLink>
            <div className="">
              <LanguageCurrencySelect />
            </div>
          </div>
          <button
            onClick={togglesearchPopup}
            className="px-4 py-2 ml-2 font-semibold text-white bg-red-800 rounded-lg hover:bg-black focus:outline-none"
          >
            <ImSearch />
          </button>
          <AjaxCart />
        </div>
        {isOpen &&
          ReactDOM.createPortal(
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 border shadow-lg rounded-lg w-[90%] max-w-2xl max-h-[80vh] min-h-[300px] overflow-y-auto relative">
                <div className="flex justify-between items-center ">
                  <p>{""}</p>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="text-red-500"
                  >
                    <FaTimes />
                  </button>
                </div>
                <div className="flex item-center justify-center ">
                  <img
                    src="https://oizvehadar.com/api/uploads/images/logo.png"
                    className="h-[80px]"
                    alt="logo"
                  />
                </div>

                <h3 className="text-xl font-semibold mt-2 mb-2">
                  {msg === "Login" ? t("titlelg") : t("titlesg")}
                </h3>

                <p className="text-gray-600 mb-6">{t("des")}</p>

                {/* <br/> */}
                {/* Conditionally render buttons */}
                <div className="flex flex-col gap-8 items-center">
                  {msg === "Login" ? (
                    <>
                      <button
                        className="bg-red-800 text-white  border px-4 py-2 rounded-lg w-1/2"
                        onClick={() => {
                          history(`/Login`);
                          setIsOpen(false);
                        }}
                      >
                        {t("loginUser")}
                      </button>
                      <button
                        className="bg-red-900 text-white border px-4 py-2 rounded-lg w-1/2"
                        onClick={() => {
                          window.location.href =
                            "http://wholesale.oizvehadar.com/Login";
                        }}
                      >
                        {t("loginBookstore")}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-red-800 text-white px-4 py-2 rounded-lg w-1/2"
                        onClick={() => {
                          history(`/Signup`);
                          setIsOpen(false);
                        }}
                      >
                        {t("signupUser")}
                      </button>
                      <button
                        className="bg-red-900 text-white px-4 py-2 rounded-lg w-1/2"
                        onClick={() => {
                          window.location.href =
                            "http://wholesale.oizvehadar.com/register";
                        }}
                      >
                        {t("signupBookstore")}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>,
            document.body
          )}
      </div>
      {/* Mobile Drawer */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-60 z-50 transition-all transform ${
          isDrawerOpen ? "translate-x-0" : "translate-x-full"
        }`}
        onClick={() => setIsDrawerOpen(false)}
      >
        <div
          className="w-[340px] bg-[#fde5c3c4] text-white h-full flex flex-col justify-between py-4"
          onClick={(e) => e.stopPropagation()} // Prevent closing the drawer when clicking inside
        >
          {/* Close button */}
          <button
            className="text-xl text-red-500 absolute top-4 right-4"
            onClick={() => setIsDrawerOpen(false)}
          >
            ✖
          </button>

          {/* Drawer Content */}
          <div className="flex flex-col items-center space-y-6 overflow-y-auto h-[650px]">
            {/* Logo in Drawer */}
            <img
              src="https://oizvehadar.com/api/uploads/images/logo.png"
              className="h-[80px]"
              alt="logo"
            />

            {/* Navigation Links in Drawer */}
            <div className="space-y-4 w-full">
              {navLinks.map(({ path, label }) => (
                <NavLink
                  key={path}
                  to={path}
                  className="block text-left text-white py-2 px-4 rounded-md hover:bg-red-950 border-b-4"
                  onClick={() => setIsDrawerOpen(false)} // Close drawer on link click
                >
                  {t(label)}
                </NavLink>
              ))}
            </div>

            {/* User and Cart Icons in Drawer */}
            <div className="flex items-center gap-2 space-x-4">
              {!currentUser && (
                <>
                  {/* <NavLink to="/Pages/Contact" className="mr-4 px-4 py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">Contact us</NavLink> */}
                  <NavLink
                    // to="/Login"
                    className="mr-2 px-4  text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
                    onClick={() => {
                      setmsg("Login");
                      setIsOpen(true);
                    }}
                  >
                    <span className=" text-[12px] text-des w-auto" onC>
                      {t("login")}
                    </span>
                  </NavLink>
                  <NavLink
                    className="mr-4 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
                    // to="/Signup"
                    onClick={() => {
                      setmsg("Signup");
                      setIsOpen(true);
                    }}
                  >
                    <span className="text-[12px] text-des w-auto">
                      {t("sign")}
                    </span>
                  </NavLink>
                </>
              )}

              {currentUser && (
                <>
                  <div className="">
                    <NavLink to="/User">
                      <MdAccountCircle
                        className={`${
                          savedLang === "en" ? "ml-7" : "mr-7"
                        } h-[40px] w-[40px]`}
                        style={{ color: "000" }}
                      />

                      <div className="w-[100px] h-[25px] rounded-full bg-green-600 transform hover:scale-110 hover:bg-green-700 transition-colors flex items-center justify-center text-center text-[14px] font-bold">
                        <NavLink to="/User/wallet">
                          {loading ? (
                            <span className="balance-text">{t("loading")}</span>
                          ) : error ? (
                            <span className="balance-text error">{error}</span>
                          ) : (
                            <span className="balance-text">${balance}</span>
                          )}
                        </NavLink>
                      </div>
                    </NavLink>
                  </div>
                </>
              )}
            </div>
            <div className="flex md:hidden">
              <LanguageCurrencySelect />
            </div>
            <div className="flex md:hidden">
              <NavLink
                to="/Contact"
                className="flex flex-col items-center mb-2 group"
              >
                <div className="h-[30px] w-[30px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center">
                  <BiSupport className="h-[24px] w-[24px] text-white group-hover:text-white" />
                </div>
                <div className="w-[80px] h-[20px]  rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center text-[10px] font-bold text-white mt-1">
                  <span className="balance-text">{t("contact")}</span>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/Login" Component={Login} />
        <Route path="/reset-password" Component={Reset} />
        <Route path="/Forget" Component={Forget} />
        <Route path="/Signup" Component={Signup} />
        <Route path="/Collections/:id?" Component={Collection} />
        <Route path="/Product" Component={Product} />
        {/* <Route path="/About" Component={About} /> */}
        <Route path="/Contact" Component={Contact} />

        <Route path="/Bundles" Component={Bundles} />
        <Route path="/UserInsert" Component={UploadBooksForm} />
        <Route path="/Pages/Stripe" Component={Stripepayments} />
        <Route path="/Subscriptions" Component={Subscriptions} />
        <Route path="/Pages/Product/:id?" Component={Product} />
        <Route path="/DownloadCatalogue" Component={DownloadCatalogue} />
        <Route path="/Donate" Component={Donate} />
        <Route path="/Checkout" Component={Checkout} />
        {/* Protected PDFViewer route */}
        <Route
          path="/pdfViewer/:id?/:title?"
          element={
            <PrivateRoute>
              <PDFViewer />
            </PrivateRoute>
          }
        />

        <Route path="/User/:id?" Component={User} />
        <Route
          path="/thank-you/:paymentConfirmed?/:pro_id?/:product?/:price?/:type?/"
          Component={ThankYouPage}
        />
        <Route path="/Privacy" Component={Privacy} />
        <Route path="/Checkout" Component={Checkout} />
        <Route path="/wallet" Component={WalletPage} />
      </Routes>
    </>
  );
};

export default Navbar;

// const Navbar = () => {
//   const { currentUser, getUserData } = useContext(AuthContext);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//   const [balance, setBalance] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [savedLang, setSaveLang] = useState(
//     localStorage.getItem("language") || "he"
//   );

//   useEffect(() => {
//     const handleResize = () => setWindowWidth(window.innerWidth);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     const userdata = getUserData();
//     if (userdata) {
//       axios
//         .get(`${BASE_URL}/api_wallet.php?user_id=${userdata.id}`)
//         .then((response) => {
//           setBalance(response.data.balance);
//           setLoading(false);
//         })
//         .catch(() => {
//           setError("Error fetching wallet balance");
//           setLoading(false);
//         });
//     }
//   }, [getUserData, currentUser]);

//   const { t } = useTranslation();

//   return (
//     <>
//       <div
//         className="flex justify-between items-center bg-[#fde5c3c4] shadow-md text-white px-2 p-2"
//         style={{ boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px" }}
//       >
//         {/* Left Logo */}
//         <div className="logo">
//           <img
//             src="https://oizvehadar.com/api/uploads/images/logo.png"
//             className="h-[80px]"
//             alt="logo"
//           />
//         </div>

//         {/* Middle Navigation */}
//         <div className="navigation flex-grow flex justify-center">
//           {[
//             { path: "/", label: "Home" },
//             { path: "/Collections", label: "books_catalogue" },
//             { path: "/Bundles", label: "bundles" },
//             { path: "/Subscriptions", label: "subscriptions" },
//             { path: "/DownloadCatalogue", label: "catalogue" },
//             { path: "/Donate", label: "donate" },
//             { path: "/UserInsert", label: "list_book" },
//           ].map(({ path, label }) => (
//             <NavLink
//               key={path}
//               to={path}
//               className={({ isActive }) =>
//                 `mr-4 w-[120px] h-[40px] rounded-full transform hover:scale-110 transition-colors flex items-center justify-center ${
//                   isActive ? "bg-red-950 text-white" : "bg-red-900"
//                 }`
//               }
//             >
//               {t(label)}
//             </NavLink>
//           ))}
//         </div>

//         {/* Auth Links */}
//         {!currentUser ? (
//           <>
//             <NavLink
//               to="/Login"
//               className="mr-2 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
//             >
//               {t("login")}
//             </NavLink>
//             <NavLink
//               to="/Signup"
//               className="mr-4 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
//             >
//               {t("sign")}
//             </NavLink>
//           </>
//         ) : (
//           <>
//             <NavLink to="/User" className="flex items-center">
//               <MdAccountCircle
//                 className={`${
//                   savedLang === "en" ? "ml-7" : "mr-7"
//                 } h-[40px] w-[40px]`}
//               />
//               <div className="w-[100px] h-[25px] rounded-full bg-green-600 transform hover:scale-110 hover:bg-green-700 transition-colors flex items-center justify-center text-[14px] font-bold">
//                 <NavLink to="/User/wallet">
//                   {loading ? (
//                     <span className="balance-text">{t("loading")}</span>
//                   ) : error ? (
//                     <span className="balance-text error">{error}</span>
//                   ) : (
//                     <span className="balance-text">${balance}</span>
//                   )}
//                 </NavLink>
//               </div>
//             </NavLink>
//           </>
//         )}

//         {/* Right Side Icons & Language Selector */}
//         <div className="relative flex items-center">
//           <div
//             className={`flex flex-col items-center ${
//               savedLang === "en" ? "ml-2" : "mr-2"
//             }`}
//           >
//             <NavLink
//               to="/Contact"
//               className="flex flex-col items-center mb-2 group"
//             >
//               <div className="h-[30px] w-[30px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center">
//                 <BiSupport className="h-[24px] w-[24px] text-white" />
//               </div>
//               <div className="w-[80px] h-[20px] rounded-full bg-red-900 transform group-hover:scale-110 group-hover:bg-red-700 transition-colors flex items-center justify-center text-[10px] font-bold text-white mt-1">
//                 {t("contact")}
//               </div>
//             </NavLink>

//             <LanguageCurrencySelect />
//           </div>

//           <AjaxCart />
//         </div>
//       </div>

//       {/* Routes */}
//       <Routes>
//         <Route path="/" Component={Home} />
//         <Route path="/Login" Component={Login} />
//         <Route path="/Signup" Component={Signup} />
//         <Route path="/Collections" Component={Collection} />
//         <Route path="/Product" Component={Product} />
//         <Route path="/Contact" Component={Contact} />
//         <Route path="/Bundles" Component={Bundles} />
//         <Route path="/UserInsert" Component={UploadBooksForm} />
//         <Route path="/Subscriptions" Component={Subscriptions} />
//         <Route path="/DownloadCatalogue" Component={DownloadCatalogue} />
//         <Route path="/Donate" Component={Donate} />
//         <Route path="/Checkout" Component={Checkout} />
//         <Route path="/User/:id?" Component={User} />
//         <Route path="/wallet" Component={WalletPage} />
//         <Route
//           path="/pdfViewer/:id?/:title?"
//           element={
//             <PrivateRoute>
//               <PDFViewer />
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="/thank-you/:paymentConfirmed?/:pro_id?/:product?/:price?/:type?/"
//           Component={ThankYouPage}
//         />
//         <Route path="/Privacy" Component={Privacy} />
//       </Routes>
//     </>
//   );
// };

// export default Navbar;
