import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BASE_URL from "../constant";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import htmlToPlainText from "../htmltotext";
import { FaCartPlus, FaICursor } from "react-icons/fa6";
import { HiCursorClick } from "react-icons/hi";
import { t } from "i18next";
import { PiCursorClick } from "react-icons/pi";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";

// FaCartPlus
const Filters = ({ handleFilterChange }) => {
  const [alllang, setAllLang] = useState(
    localStorage.getItem("language") === "en" ? "All" : "הכל" || "All"
  );
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setAllLang(svLang === "en" ? "All" : "הכל");
    setCategories((prevCategories) => {
      const otherCategories = prevCategories.slice(1); // Exclude the first category

      return [svLang === "en" ? "All" : "הכל", ...otherCategories];
    });
    setAuthors((prevCategories) => {
      const otherCategories = prevCategories.slice(1); // Exclude the first category

      return [svLang === "en" ? "All" : "הכל", ...otherCategories];
    });
  });
  useEffect(() => {
    // Fetch filter options (authors, categories, topics, volumes) from the API
    const fetchFilterOptions = async () => {
      try {
        let url = `${BASE_URL}/api_book.php/books`;

        const response = await axios.get(url);
        const bookData = response.data;
        if (Array.isArray(bookData)) {
          // sliderData1(bookData);
          // extractTopics(bookData);
          extractCategories(bookData);
          // extractvolume(bookData);
          extractAuther(bookData);
        } else {
          console.error("Expected an array of books");
        }
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };

    fetchFilterOptions();
  }, []);
  const extractCategories = (books) => {
    const allCategories = books.reduce((acc, book) => {
      if (book.category && !acc.includes(book.category)) {
        acc.push(book.category);
      }
      return acc;
    }, []);
    setCategories([alllang, ...allCategories]);
  };
  // const extractvolume = (books) => {
  //   const allCategories = books.reduce((acc, book) => {
  //     if (book.volumes && !acc.includes(book.volumes)) {
  //       acc.push(book.volumes);
  //     }
  //     return acc;
  //   }, []);
  //   setVolumes(['All', ...allCategories]);
  // };
  const extractAuther = (books) => {
    const uniqueAuthors = new Set();

    books.forEach((book) => {
      if (book.auther) {
        uniqueAuthors.add(book.auther);
      }
    });

    setAuthors([alllang, ...Array.from(uniqueAuthors)]);
  };

  //   const extractTopics = (books) => {
  //     const allTopics = books.reduce((acc, book) => {
  //         if (book.topics) {
  //             book.topics.forEach(topic => {
  //                 if (!acc.includes(topic)) {
  //                     acc.push(topic);
  //                 }
  //             });
  //         }
  //         return acc;
  //     }, []);
  //     console.log(allTopics);
  //     setTopics(['All', ...allTopics]);
  // };
  const handleMinPriceChange = (e) => {
    setMinPrice(e.target.value);
    handleFilterChange("min_price", e.target.value);
  };

  const handleMaxPriceChange = (e) => {
    setMaxPrice(e.target.value);
    handleFilterChange("max_price", e.target.value);
  };

  const resetFilters = () => {
    setMinPrice("");
    setMaxPrice("");
    setCategories([alllang]);
    setAuthors([alllang]);
    setTopics([]);
    setVolumes([]);
    handleFilterChange("min_price", "");
    handleFilterChange("max_price", "");
    handleFilterChange("category", alllang);
    handleFilterChange("author", alllang);
    handleFilterChange("topic", "");
    handleFilterChange("volume", "");
  };

  const { t } = useTranslation();
  return (
    <div className="bg-[#fde5c3c4] shadow-md rounded-lg p-6 flex flex-col w-full">
      <h2 className="text-xl font-bold mb-6 text-[#6D3815] text-center">
        {t("filters")}
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        {/* Author Filter */}
        <label className="flex flex-col text-sm">
          <span className="mb-1 font-medium text-[#6D3815]">
            {t("author")}:
          </span>
          <select
  className="form-select text-black bg-white p-2 rounded-lg shadow border border-red-900 focus:outline-none focus:ring-2 focus:ring-[#6D3815] transition hover:bg-gray-100"
  onChange={(e) => handleFilterChange("author", e.target.value)}
  aria-label="Author Filter"
>
  {authors.map((author) => (
    <option key={author} value={author} className="text-black">
      {htmlToPlainText(author)}
    </option>
  ))}
</select>

        </label>

        {/* Category Filter */}
        <label className="flex flex-col text-sm">
          <span className="mb-1 font-medium text-[#6D3815]">
            {t("category")}:
          </span>
          <select
            className="form-select text-black bg-white p-2 rounded-lg shadow border border-red-900 focus:outline-none focus:ring-2 focus:ring-[#6D3815] transition hover:bg-gray-100"
            onChange={(e) => handleFilterChange("category", e.target.value)}
            aria-label="Category Filter"
          >
            {categories.map((category) => (
              <option key={category} value={category} className="text-black">
                {category}
              </option>
            ))}
          </select>
        </label>

        {/* Price Range Filter */}
        <label className="flex flex-col col-span-2  md:col-span-1 text-sm">
          <span className="mb-1 font-medium text-[#6D3815]">
            {t("price_range")}:
          </span>
          <div className="flex gap-2">
            <input
              type="number"
              placeholder={t("min_price")}
               className="form-select w-full text-black bg-white p-2 rounded-lg shadow border border-red-900 focus:outline-none focus:ring-2 focus:ring-[#6D3815] transition hover:bg-gray-100"
              value={minPrice}
              onChange={handleMinPriceChange}
              aria-label="Minimum Price"
            />
            <input
              type="number"
              placeholder={t("max_price")}
              className="form-select text-black w-full bg-white p-2 rounded-lg shadow border border-red-900 focus:outline-none focus:ring-2 focus:ring-[#6D3815] transition hover:bg-gray-100"
              value={maxPrice}
              onChange={handleMaxPriceChange}
              aria-label="Maximum Price"
            />
          </div>
        </label>
      </div>

      {/* Reset Filters Button */}
      <div className="mt-6 flex justify-center">
        <button
          className="bg-[#6D3815] text-white py-2 px-6 rounded-lg shadow hover:bg-[#5b2e11] transition focus:outline-none focus:ring-2 focus:ring-[#6D3815] font-semibold"
          onClick={resetFilters}
        >
          {t("reset_filters")}
        </button>
      </div>
    </div>
  );
};
const ProductCard = ({
  pro,
  id,
  image,
  title,
  price,
  symbol,
  discount,
  rating,
  available_vol,
  availabe_quantity,
}) => {
  const {
    cart,
    addToCart,
    removeFromCart,
    currentUser,
    getUserData,
    fetchUserCart,
  } = useContext(AuthContext);
  return (
    <div className="group flex flex-col items-center w-full gap-4 p-5 sm:p-6 border border-gray-300 shadow-md rounded-lg hover:shadow-lg transition-transform duration-300 transform hover:scale-105 bg-white">
      {/* Badge for Discount */}
      {discount && (
        <span className="absolute top-3 right-3 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
          {discount}% OFF
        </span>
      )}

      {/* Product Image */}

      <div>
        {image ? (
          <img
            src={image}
            alt={title || "Product Image"}
            loading="lazy"
            className="w-full h-[210px] object-contain rounded-lg hover:opacity-90 transition-opacity duration-300"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-[210px] bg-gray-100 text-gray-500 rounded-lg">
            <svg
              fill="#000000"
              width="800px"
              height="800px"
              viewBox="0 0 32 32"
              className="mr-[10px] ml-[10px] w-[90%] h-[210px] ml-7 mt-1.5 object-contain"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <style>{`.cls-1{fill:none;}`}</style>
              </defs>
              <title>no-image</title>
              <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z" />
              <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z" />
              <rect
                id="_Transparent_Rectangle_"
                data-name="&lt;Transparent Rectangle&gt;"
                className="cls-1"
                width="32"
                height="32"
              />
            </svg>
          </div>
        )}
      </div>
      {/* </Link> */}

      {/* Product Info */}
      <div className="px-4 py-3 w-full">
        {/* Title */}
 <div
  className={`group-hover:text-gray-600 transition-colors duration-300 overflow-hidden ${
    title.length < 20 ? "text-lg" : title.length < 50 ? "text-base" : "text-sm"
  }`}
  style={{
    height: title.length > 50 ? "3em" : "2.8em",
    lineHeight: "1.5em",
  }}
  dir="rtl"
>
  <Markup content={title} />
</div>


        {/* Price & Discount */}
        <div className="flex items-center justify-between mt-1">
          <p className="text-lg font-semibold text-gray-900" dir="ltr">
          {available_vol ? (
          <><span className="text-sm text-gray-500" dir="ltr">{`${t("starting_from")} `}{symbol} </span>{price}</>
        ):(<><span className="text-sm text-gray-500" >{symbol}</span> {price}</>)}
            {/* <span className="text-sm text-gray-500" >{symbol}</span> {price} */}
            {/* {pro?.volumes?.length > 0
                  ? pro?.volumes.some((volume) => volume.must_buy === 1)
                    ? symbol
                    : symbol !== "₪"
                    ? ``
                    : ``
                  : symbol}

                {pro?.volumes?.length > 0
                  ? pro.volumes.some((volume) => volume.must_buy === 1)
                    ? symbol !== "₪"
                      ? calculateTotalPrice(pro?.volumes, "wholesale_price")
                      : calculateTotalPrice(
                        pro?.volumes,
                          "wholesale_price_is"
                        )
                    : symbol !== "₪"
                    ? `${t("starting_from")} ${Number(
                      pro?.volumes?.[0]?.wholesale_price || 0
                      ).toFixed(2)}`
                    : `${t("starting_from")} ${Number(
                      pro?.volumes?.[0]?.wholesale_price_is || 0
                      ).toFixed(2)}`
                  :symbol !== "₪"
                  ? Number(pro?.wholesale_price || 0).toFixed(2)
                  : Number(pro?.wholesale_price_is || 0).toFixed(2)} */}
          </p>
          {discount && (
            <p className="text-sm text-red-500 font-bold line-through">
              <span className="text-xs">{symbol}</span>{" "}
              {(price / (1 - discount / 100)).toFixed(2)}
            </p>
          )}
        </div>

        {/* Rating */}
        {rating && (
          <div className="flex items-center mt-2 text-yellow-500">
            {"★".repeat(rating)}
            <span className="ml-2 text-sm text-gray-600">
              {rating}/5 Ratings
            </span>
          </div>
        )}

        <br />
        {available_vol && (
          <div className="flex justify-center w-full">
            <p className="flex flex-row items-center mb-3 justify-center text-center cursor-pointer whitespace-nowrap   sm:px-5 py-2 text-sm text-red-900 font-semibold text-gray-800  transition-colors duration-300 w-full max-w-[300px]">
              {t("select_vol")}
            </p>
          </div>
        )}

        <div className="flex flex-col items-center gap-4">
          {/* View Button */}
          <div className="flex justify-center w-full">
            <Link to={`/pages/Product/${id}`} className="w-full max-w-[300px]">
              <button
                className="flex flex-row items-center justify-center text-center 
        cursor-pointer whitespace-nowrap rounded-[10px] gap-2 border border-red-800 sm:px-5 py-2 text-sm text-red-900 font-semibold hover:bg-red-800 hover:text-white transition-colors duration-300 w-full"
              >
                {t("view")}
                <PiCursorClick className="text-lg" />
              </button>
            </Link>
          </div>
          {!available_vol && (
            <div className="flex justify-center w-full">
              {availabe_quantity > 0 ? (
                <button
                  className="flex flex-row items-center justify-center text-center 
        cursor-pointer whitespace-nowrap rounded-[10px] gap-2 border border-red-800 sm:px-5 py-2 text-sm text-red-900 font-semibold text-gray-800 hover:bg-red-800 hover:text-white transition-colors duration-300 w-full max-w-[300px]"
                  onClick={async () => {
                    if (!currentUser) {
                      alert("You need to log in first");
                      return;
                    }

                    // Single product scenario without multiple items

                    if (availabe_quantity > 0) {
                      const data = {
                        product: pro,
                        quantity: 1,
                        price: price,
                      };

                      try {
                        await addToCart(data);
                        // setAlertItabOpen(true);
                        // fetchUserCart(currentUser.id);
                        // window.location.reload(); // Refresh page after adding to cart
                      } catch (error) {
                        console.error("Failed to add to cart:", error);
                        alert("An error occurred. Please try again.");
                      }
                    } else {
                      alert(
                        `${t("book")} ${htmlToPlainText(title)} ${t(
                          "not_enough"
                        )}`
                      );
                    }
                  }}
                >
                  {t("add_to_cart")}
                  <FaCartPlus className="text-lg" />
                </button>
              ) : (
                <button className="flex items-center justify-center text-center text-white font-semibold text-sm bg-red-400 border-2 border-red-400 rounded-[10px] py-2 px-5 w-full max-w-[300px]">
                  {t("sold_out")}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const ProductCardShimmer = ({ savedLang }) => {
  return (
    <div className="animate-pulse w-full flex flex-col items-center gap-4 p-5 sm:p-6 border border-gray-300 shadow-md rounded-lg bg-gray-100">
      {/* Shimmer for Image */}
      <div className="w-full h-[210px] bg-gray-300 rounded-lg"></div>

      {/* Shimmer for Title */}
      <div className="w-full h-6 bg-gray-300 rounded-lg"></div>
      {/* <div className="w-3/4 h-6 bg-gray-300 rounded-lg"></div> */}

      {/* Shimmer for Price */}
      <div
        className={`w-1/2 h-6 bg-gray-300 rounded-lg mt-2 ${
          savedLang !== "ils" ? "self-start" : "self-end"
        }`}
      ></div>

      {/* Shimmer for Rating */}
      {/* <div className="w-1/3 h-6 bg-gray-300 rounded-lg mt-2"></div> */}

      {/* Shimmer for Buttons */}
      <div className="flex flex-col gap-3 w-full">
        <div className="w-full h-10 bg-gray-300 rounded-lg"></div>
        <div className="w-full h-10 bg-gray-300 rounded-lg"></div>
      </div>
    </div>
  );
};

const ProductListShimmer = ({ count = 10, savedLang }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
      {Array.from({ length: count }).map((_, index) => (
        <ProductCardShimmer key={index} savedLang={savedLang} />
      ))}
    </div>
  );
};

// const ProductCard = ({ id, image, title, price, symbol }) => {
//   return (
//     <>
//       <div className="flex flex-col items-center w-full gap-[18px] p-[22px] sm:p-5 border-black-900_72 border border-solid shadow-xs rounded-[10px]">
//         <Link to={`/pages/Product/${id}`}>
//           <div>
//             {image ? (
//               <img src={image} alt="Image" className="mr-[10px] ml-[10px] w-[90%] h-[210px] ml-7 mt-1.5 object-contain" />
//               // <img
//               //   src={image}
//               //   alt={title}
//               //   className="h-80 w-full object-contain rounded-t-xl"
//               // />
//             ) : (
//               <svg
//                 fill="#000000"
//                 width="800px"
//                 height="800px"
//                 viewBox="0 0 32 32"
//                 className="mr-[10px] ml-[10px] w-[90%] h-[210px] ml-7 mt-1.5 object-contain"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <defs>
//                   <style>{`.cls-1{fill:none;}`}</style>
//                 </defs>
//                 <title>no-image</title>
//                 <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z" />
//                 <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z" />
//                 <rect
//                   id="_Transparent_Rectangle_"
//                   data-name="&lt;Transparent Rectangle&gt;"
//                   className="cls-1"
//                   width="32"
//                   height="32"
//                 />
//               </svg>
//             )}
//           </div>

//           <div className="px-4 py-3 w-full">

//             <div className="line-clamp-3 leading-6 overflow-hidden">
//   <Markup content={title} />
// </div>

//             {/* </p> */}

//             <div className="flex items-center" style={{ direction: "ltr" }}>

//               <p className="text-lg font-semibold text-black cursor-auto my-3">
//                 <span className="text-[14px] text-gray-900_01">{symbol}</span> {price}
//               </p>

//             </div>
//           </div>
//         </Link>
//         <br/> <br/>
//       </div>
//     </>
//   );
// };

const ProductList = ({ products, savedLang }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
      {products.map((product) => (
        <ProductCard
          pro={product}
          key={product.id}
          id={product.id}
          image={product.images[0]}
          title={product.title}
          price={savedLang !== "ils" ? product.price : product.price_is}
          symbol={savedLang !== "ils" ? "$" : "₪"}
          available_vol={product.volumes_available}
          availabe_quantity={
            savedLang !== "ils"
              ? parseInt(product.warehouse_quantity, 10)
              : parseInt(product.warehouse_quantity_is, 10)
          }
        />
      ))}
    </div>
  );
};

// const Collection = () => {
//   const [products, setProducts] = useState([]);
//   const [filters, setFilters] = useState({});
//   const [searchInput, setSearchInput] = useState("");
//   useEffect(() => {
//     // Fetch filter options (authors, categories, topics, volumes) from the API
//     const fetchFilterOptions = async () => {
//       try {
//         let url = `${BASE_URL}/api_book.php/books`;

//         const response = await axios.get(url);
//         const bookData = response.data;
//         if (Array.isArray(bookData)) {
//           // sliderData1(bookData);
//           setProducts(response.data);
//         } else {
//           console.error("Expected an array of books");
//         }
//       } catch (error) {
//         console.error("Error fetching books:", error);
//       }
//     };

//     fetchFilterOptions();
//   }, []);
//   const fetchFilteredProducts = async (filters) => {
//     try {
//       // Construct API URL with filter parameters
//       let url = `${BASE_URL}/api_book.php/books/`;
//       if (filters) {
//         const queryParams = Object.keys(filters)
//           .map((key) => `${key}=${filters[key]}`)
//           .join("&");
//         url += `?${queryParams}`;
//       }

//       const response = await axios.get(url);
//       setProducts(response.data);
//     } catch (error) {
//       console.error("Error fetching filtered products:", error);
//     }
//   };

//   const handleFilterChange = (filterType, filterValue) => {
//     // Update filters object with the new filter value
//     const newFilters = { ...filters, [filterType]: filterValue };
//     if (filterValue === "All") {
//       delete newFilters[filterType];
//     }
//     if (filterValue === "0") {
//       delete newFilters[filterType];
//     }
//     setFilters(newFilters);
//     // Fetch filtered products with updated filters
//     fetchFilteredProducts(newFilters);
//   };
//   const handleSearchInputChange = (e) => {
//     setSearchInput(e.target.value);
//     handleFilterChange("name", e.target.value);
//   };
//   const { t } = useTranslation();
//   const [savedLang, setSaveLang] = useState(
//     localStorage.getItem("currency") || "usd"
//   );
//   useEffect(() => {
//     const svLang = localStorage.getItem("currency");
//     setSaveLang(svLang);
//   });

//   return (
//     <div>
//       <div
//   className="text-center"

//   style={{
//     background: "linear-gradient(180.5deg, black 82%, white 20%)",
//     height: "523px", // Fixed height
//     color: "white",  // Ensure text is visible on the black portion
//   }}
// >
//         {/* Search input */}
//         {/* <input
//           type="text"
//           placeholder={t("search_book")}
//           className="border border-[#CC9600] absolute bg-[#000000b8] text-white	top-[34%] w-[38%] left-[32%] rounded-md px-4 py-4 focus:outline-none"
//           value={searchInput}
//           //onChange={handleSearchInputChange}
//         /> */}
//         <div className="relative w-[38%] left-[32%] top-[34%]">
//   <input
//     type="text"
//     placeholder={t("search_book")}
//     className="border border-[#CC9600] bg-[#000000b8] text-white w-full rounded-md px-4 py-4 focus:outline-none"
//     value={searchInput}
//     onChange={handleSearchInputChange}
//   />
//   <button
//     className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-[#CC9600] text-black font-semibold px-4 py-2 rounded-md hover:bg-[#b38100] transition duration-300"
//   //  onClick={handleSearchInputChange}
//   >
//     {t("search")}
//   </button>
// </div>

//         {/* Your banner content goes here */}
//         <div className="flex justify-center w-full">
//         <img
//           src="../images/Rectangle 5.gif"
//           className="h-[450px] w-[1100px]"
//           alt="Banner"
//         />
//         </div>
//       </div>

//       {/* <div className=" mr-4 rtl:ml-4  collection-filter"> */}
//       <Filters handleFilterChange={handleFilterChange} />
//       {/* </div> */}
//       <br />
//       <div>
//         <div
//           className="collection-prod"
//           style={{ height: "900px", overflowY: "auto" }}
//         >
//           <ProductList products={products} savedLang={savedLang} />
//         </div>
//       </div>
//     </div>
//   );
// };
const Collection = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filters, setFilters] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    // Fetch all products from the API
    const fetchFilterOptions = async () => {
      setLoading(true); // Start loading
      try {
        const url = `${BASE_URL}/api_book.php/books`;
        const response = await axios.get(url);
        const bookData = response.data;
        if (Array.isArray(bookData)) {
          setProducts(bookData);
          setFilteredProducts(bookData); // Initialize with all products
        } else {
          console.error("Expected an array of books");
        }
      } catch (error) {
        console.error("Error fetching books:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchFilterOptions();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = () => {
    if (colRef.current) {
      colRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
    setLoading(true); // Start loading
    setTimeout(() => {
      const searchTerm = searchInput.trim().toLowerCase(); // Trim and normalize input
      const filtered = products.filter((product) => {
        const title = product.title ? htmlToPlainText(product.title).toLowerCase() : ""; // Handle missing title
        const asin = product.asin ? product.asin.toLowerCase() : ""; // Handle missing ASIN
        return title.includes(searchTerm) || asin.includes(searchTerm); // Partial match on title or ASIN
      });
      setFilteredProducts(filtered);
      setLoading(false); // Stop loading
    }, 500); // Simulate a delay for smoother UX
  };
  

  const handleFilterChange = (filterType, filterValue) => {
    const newFilters = { ...filters, [filterType]: filterValue };
    if (filterValue === "All" || filterValue === "0") {
      delete newFilters[filterType];
    }
    setFilters(newFilters);
    fetchFilteredProducts(newFilters);
  };

  const fetchFilteredProducts = async (filters) => {
    setLoading(true); // Start loading
    try {
      let url = `${BASE_URL}/api_book.php/books/`;
      if (filters) {
        const queryParams = Object.keys(filters)
          .map((key) => `${key}=${filters[key]}`)
          .join("&");
        url += `?${queryParams}`;
      }
      const response = await axios.get(url);
      setProducts(response.data);
      setFilteredProducts(response.data); // Update filtered products
    } catch (error) {
      console.error("Error fetching filtered products:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const { t } = useTranslation();
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("currency") || "usd"
  );
  const [savedLangs, setSaveLangs] = useState(
    localStorage.getItem("language") || "he"
  );

  useEffect(() => {
    const svLang = localStorage.getItem("currency");
    setSaveLang(svLang);
    const svsLang = localStorage.getItem("language");
    setSaveLangs(svsLang);
  }, []);
  const colRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust items per page as needed
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  
  return (
    <div ref={colRef}>
      <div
        className="text-center"
        style={{
          background: "linear-gradient(180.6deg, black 74%, white 26%)",
          height: "523px",
          color: "white",
        }}
      >
       
       <div className="relative w-full h-[523px] flex justify-center items-center">
  {/* Background Image */}
  <div className="absolute inset-0 flex justify-center items-center">
    <img
      src="../images/Rectangle 5.gif"
      className="w-[1050px] h-[394px]   z-5"
      alt="Banner"
    />
  </div>

  {/* Search Box */}
  <div
    className="relative z-10 top-[-80px] md:top-0 md:w-[38%] w-[80%] bg-[#000000b8] border border-[#CC9600] rounded-md flex items-center px-4"
   
  >
    <input
      type="text"
      // dir={savedLangs === "en" ? "ltr" : "rtl"}
      placeholder={t("search_title")}
      className="bg-transparent text-white w-full py-4 focus:outline-none placeholder:text-[#CC9600]"
      value={searchInput}
      onChange={handleSearchInputChange}
    />
    <button
      className={`ml-2 bg-[#CC9600] text-black font-semibold px-4 py-2 rounded-md hover:bg-[#b38100] transition duration-300`}
      dir={savedLangs === "en" ? "ltr" : "rtl"}
      onClick={handleSearch}
    >
      {t("search")}
    </button>
  </div>
</div>


      </div>

      <Filters handleFilterChange={handleFilterChange} />
      <br />
      <div>
        <div
          className="collection-prod"
          // style={{ height: "500px", overflowY: "auto" }}
        >
          {loading ? (
            <ProductListShimmer savedLang={savedLang} />
          ) : (
            // <div className="loader-container">
            //   <div className="loader"></div>
            //   <p className="ml-2">{t("loading")}</p>
            // </div>
            <ProductList products={paginatedProducts} savedLang={savedLang} />
          )}
        </div>
        {totalPages > 1 && (
        <div className="flex justify-center items-center mt-4 space-x-2">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded-md ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-red-700 text-white"
            }`}
          >
            {"<"}
          </button>
          <span className="px-4 py-1 bg-gray-100 rounded-md">{`${t(
            "page"
          )} ${currentPage} ${t("of")} ${totalPages}`}</span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className={`px-3 py-1 rounded-md ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-red-700 text-white"
            }`}
          >
            {">"}
          </button>
        </div>
      )}
      </div>
    </div>
  );
};

export default Collection;
