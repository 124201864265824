import React, { useState, useEffect, useRef, useContext } from "react";

import ReactDOM from "react-dom";
import {
  FaFolder,
  FaFileAlt,
  FaList,
  FaTh,
  FaPlus,
  FaEdit,
  FaTrash,
  FaTimes,
} from "react-icons/fa";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { FaFolder, FaFileAlt, FaList, FaTh } from "react-icons/fa";

// import React, { useState, useRef, useEffect } from 'react';
// import { Viewer, Worker } from "@react-pdf-viewer/core";
import Tesseract from "tesseract.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate } from "react-router-dom";

import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { IoMdArrowDropup } from "react-icons/io";

import html2canvas from "html2canvas";
import { IoMdCut } from "react-icons/io";
import { MdCreateNewFolder, MdDriveFileMove, MdPrint } from "react-icons/md";

import { RiPageSeparator } from "react-icons/ri";
import { FaCirclePlus } from "react-icons/fa6";
import { SiImessage } from "react-icons/si";
import { FaHeart, FaKey } from "react-icons/fa";
import { FaMarker } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { FaRegHeart } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";

import { FaBook } from "react-icons/fa";
import { BsCardHeading } from "react-icons/bs";
import BASE_URL from "../constant";
import { TbLockAccess, TbTrash } from "react-icons/tb";
import { AuthContext } from "../context/AuthContext";

import { saveAs } from "file-saver";
import pdf_URL from "../constant_pdf";
import htmlToPlainText from "../htmltotext";
import axios from "axios";
import { savePDFToIndexedDB, getPDFFromIndexedDB } from "./pdfcacher";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  PageOrganizer,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import NODE_BASE_URL from "../node_constant";

import convertToHebrewNumerals from "../hebrewnumbers";
import { t } from "i18next";

const Section2 = ({
  firsttab,
  addTabs,
  multiSearchResult,
  setmultibook,
  alerttab,
}) => {
  const { cart, addToCart, removeFromCart, currentUser, getUserData } =
    useContext(AuthContext);
  const viewerRefs = useRef({});
  const viewerRefs2 = useRef({});
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([]);
  const [recenttab, setRecentTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ocrText, setOcrText] = useState("");
  const [isSnipping, setIsSnipping] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [snip, setSnip] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const pdfViewerRef = useRef();
  const [documentUrl, setDocumentUrl] = useState("");
  const [loadedDocs, setLoadedDocs] = useState({}); // Store loaded documents for each tab
  const [tabStates, setTabStates] = useState({});
  const history = useNavigate();
  const [currentbook, setcurrentbook] = useState({});
  const [pdfData, setPdfData] = useState(null);

  const closeTab = (id) => {
    const updatedTabs = tabs.filter((tab) => tab.id !== id);
    setOptions([]);
    setIssOpen(false);
    setTabs(updatedTabs);
    // console.log("closetab");
    // console.log("closetab tab left:" + updatedTabs.length);
    // console.log(updatedTabs);
    if (activeTab === id && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[updatedTabs.length - 1].id);
      handleTabClick(updatedTabs.length - 1);
    } else if (updatedTabs.length === 0) {
      setActiveTab(null); // No tabs open
    }
  };
  const handleDocumentLoad = (tabId, base64Data) => {
    // console.log(base64Data);
    setLoadedDocs((prevDocs) => ({
      ...prevDocs,
      [tabId]: base64Data,
    }));
    // const viewerInstance = viewerRefs.current[tabId];
    // if (viewerInstance) {
    //   viewerInstance.documentPath = base64Data; // Load the PDF into the viewer
    //   viewerInstance.load(base64Data,null);
    //       }
  };

  const loadDocumentForTab = async (tab) => {
    // console.log(tab);
    if (!loadedDocs[tab.id]) {
      // console.log(tab.id);
      //  const pdfData = await fetchAndCachePDF(tab.pdf_location); // Fetch and cache the PDF
      handleDocumentLoad(
        tab.id,
        tab.pdf_location.replace("uploads/searchable/", "")
      ); // Store loaded document
    }
  };

  // Save current viewer state (like page, zoom) before switching tabs
  const saveViewerState = (tabId) => {
    const viewerInstance = viewerRefs.current[tabId];
    if (viewerInstance) {
      const currentState = {
        currentPage: viewerInstance.currentPageNumber,
        zoom: viewerInstance.zoomPercentage,
      };
      setTabStates((prevStates) => ({
        ...prevStates,
        [tabId]: currentState,
      }));
    }
  };

  // Restore viewer state (like page, zoom) when switching tabs
  const addTab = (newTab) => {
    // Save current viewer state before switching tabs
    if (activeTab) {
      saveViewerState(activeTab);
    }

    // Restore viewer state for the selected tab if available

    setOptions([]);
    setIssOpen(false);
    // Check if the tab already exists
    const existingTab = tabs.find((tab) => tab.id === newTab.id);

    if (existingTab) {
      if (activeTab !== existingTab.id) {
        setActiveTab(existingTab.id);
        restoreViewerState(existingTab.id);
      }
      setCurrentKeyword({
        keyword: "",
        matchCase: false,
        wholeWords: false,
      });
      window.history.pushState(
        null,
        "",
        `/pdfViewer/${existingTab.id}/${htmlToPlainText(existingTab.title)}`
      );
      setOptions([]);
      setIssOpen(false);
    } else {
      // If the tab doesn't exist, add it to the list of tabs
      setIssOpen(false);
      setCurrentKeyword({
        keyword: "",
        matchCase: false,
        wholeWords: false,
      });
      setOptions([]);
      const newTabs = [...tabs, newTab];
      setTabs(newTabs);
      setActiveTab(newTab.id);

      if (newTab && newTab.id && newTab.title) {
        // Update PDF data and the URL
        console.log(newTab.pdf_location);
        setPdfData(newTab.pdf_location.replace("uploads/searchable/", ""));
        window.history.pushState(
          null,
          "",
          `/pdfViewer/${newTab.id}/${htmlToPlainText(newTab.title)}`
        );
      }
      loadDocumentForTab(newTab);
      setcurrentbook(newTab);
      fetchHeadings(newTab);
    }
  };

  const handleTabClick = async (index) => {
    const selectedTab = tabs[index];
    //setCurrentBook(selectedTab);
    setmultibook([]);
    setOptions([]);
    setIssOpen(false);
    fetchHeadings(selectedTab);

    if (selectedTab.book_id != null) {
      // Ensure selectedTab.id is a string
      const idStr = String(selectedTab.id);

      // Check if selectedTab.id starts with "vol-" before adding it
      const idWithPrefix = idStr.startsWith("vol-") ? idStr : `vol-${idStr}`;

      window.history.pushState(
        null,
        "",
        `/pdfViewer/${idWithPrefix}/${htmlToPlainText(selectedTab.title)}`
      );
    } else {
      window.history.pushState(
        null,
        "",
        `/pdfViewer/${selectedTab.id}/${htmlToPlainText(selectedTab.title)}`
      );
    }

    // fetchBooklimit(selectedTab.id);

    // Fetch and cache PDF data before loading
    await loadDocumentForTab(selectedTab);

    // Save current viewer state before switching tabs
    if (activeTab) {
      saveViewerState(activeTab);
    }

    // Restore viewer state for the selected tab
    if (tabStates[selectedTab.id]) {
      restoreViewerState(selectedTab.id);
    }
    setActiveTab(selectedTab.id);
  };

  const restoreViewerState = (tabId) => {
    // console.log(tabStates);
    const viewerInstance = viewerRefs.current[tabId];
    if (viewerInstance && tabStates[tabId]) {
      const state = tabStates[tabId];
      viewerInstance.navigation.goToPage(state.currentPage);
      viewerInstance.magnification.zoomTo(state.zoom);
    }
  };

  // const restoreViewerState = (tabId) => {
  //   const viewerInstance = viewerRefs.current[tabId];
  //   if (viewerInstance && tabStates[tabId]) {
  //     viewerInstance.navigation.goToPage(tabStates[tabId].currentPage);
  //     viewerInstance.magnification.zoomTo(tabStates[tabId].zoom);
  //   }
  // };

  // const handleTabClick = async (index) => {

  //   const selectedTab = tabs[index];
  //   //setCurrentBook(selectedTab);
  //   setActiveTab(selectedTab.id);
  //   fetchHeadings(selectedTab);

  //   // fetchBooklimit(selectedTab.id);

  //   // Fetch and cache PDF data before loading
  //   await loadDocumentForTab(selectedTab);

  //   // Save current viewer state before switching tabs
  //   if (activeTab) {
  //     saveViewerState(activeTab);
  //   }

  //   // Restore viewer state for the selected tab
  //   if (tabStates[selectedTab.id]) {
  //     restoreViewerState(selectedTab.id);
  //   }
  // };

  // Fetch and cache PDF
  const fetchAndCachePDF = async (url) => {
    const finalUrl =
      pdf_URL === "true"
        ? `${BASE_URL}/serve_pdf.php?file=${url.replace(
            "uploads/searchable/",
            ""
          )}`
        : `${BASE_URL}/${url}`;

    // console.log("check cache", finalUrl);
    const cachedPdf = await getPDFFromIndexedDB(finalUrl);

    if (cachedPdf) {
      // console.log("PDF found in cache:", cachedPdf);
      return cachedPdf; // Return cached PDF if found
    }

    // If not cached, fetch from server
    const response = await fetch(finalUrl);
    const blob = await response.blob();
    const reader = new FileReader();
    // const viewerInstance = viewerRefs.current[activeTab];
    // if (viewerInstance) {
    //   viewerInstance.documentPath = url; // Load the PDF into the viewer
    //   viewerInstance.loadDocument();
    //       }
    return new Promise((resolve) => {
      reader.onload = async () => {
        const pdfBase64 = reader.result;
        await savePDFToIndexedDB(finalUrl, pdfBase64);
        // console.log("PDF fetched and cached:", pdfBase64);
        resolve(pdfBase64); // Resolve with the base64 PDF data
      };

      reader.readAsDataURL(blob); // Read the blob as a data URL
    });
  };

  // const handleDocumentLoad = (tabId, base64Data) => {
  //   setLoadedDocs((prevDocs) => ({
  //     ...prevDocs,
  //     [tabId]: base64Data,
  //   }));
  // };

  // const loadDocumentForTab = (tab) => {
  //   console.log(tab);
  //   if (!loadedDocs[tab.id]) {
  //           console.log(tab.id);
  //           handleDocumentLoad(tab.id, tab.pdf_location); // Store loaded document

  //   }
  // };

  // // Save current viewer state (like page, zoom) before switching tabs
  // const saveViewerState = (tabId) => {
  //   const viewerInstance = viewerRefs.current[tabId];
  //   if (viewerInstance) {
  //     const currentState = {
  //       currentPage: viewerInstance.currentPageNumber,
  //       zoom: viewerInstance.zoomPercentage,
  //     };
  //     setTabStates((prevStates) => ({
  //       ...prevStates,
  //       [tabId]: currentState,
  //     }));
  //   }
  // };

  // // Restore viewer state (like page, zoom) when switching tabs
  // const restoreViewerState = (tabId) => {
  //   const viewerInstance = viewerRefs.current[tabId];
  //   if (viewerInstance && tabStates[tabId]) {
  //     viewerInstance.navigation.goToPage(tabStates[tabId].currentPage);
  //     viewerInstance.magnification.zoomTo(tabStates[tabId].zoom);
  //   }
  // };

  // const handleTabClick = (index) => {
  //   setcurrentbook(tabs[index]);
  //   setActiveTab(tabs[index].id);
  //   fetchHeadings(tabs[index]);
  //   setPdfData(tabs[index].pdf_location.replace("uploads/searchable/", ""));
  //   if (activeTab) {
  //     saveViewerState(activeTab);
  //   }
  //   setActiveTab(tabs[index].id);
  //   if (tabStates[tabs[index].id]) {
  //     restoreViewerState(tabs[index].id);
  //   } else {
  //     loadDocumentForTab(tabs[index]); // Load document if not already loaded
  //   }
  // };

  // const fetchAndCachePDF = async (url) => {

  //   const finalUrl =
  //     pdf_URL === "true"
  //       ? `${BASE_URL}/serve_pdf.php?file=${url.replace("uploads/searchable/", "")}`
  //       : `${BASE_URL}/${url}`;

  //   console.log("check cache", finalUrl);
  //   const cachedPdf = await getPDFFromIndexedDB(finalUrl);
  //   if (cachedPdf) {
  //     console.log(cachedPdf);
  //     // setPdfData(cachedPdf);
  //     // loadPdf(cachedPdf);
  //     return;
  //   }

  //   const response = await fetch(finalUrl);
  //   const blob = await response.blob();
  //   const reader = new FileReader();

  //   reader.onload = async () => {
  //     const pdfBase64 = reader.result;
  //     await savePDFToIndexedDB(finalUrl, pdfBase64);
  //     console.log(pdfBase64);
  //     // setPdfData(pdfBase64);
  //     // loadPdf(pdfBase64);
  //   };

  //   reader.readAsDataURL(blob);
  // };

  // const loadPdf = async (url) => {
  //   console.log(url);
  //   const loadingTask
  //           = `${BASE_URL}/serve_pdf.php?file=${url.replace("uploads/searchable/", "")}`
  //           ;
  //   const pdf = await loadingTask.promise;
  //   console.log(pdf.numPages)
  //   // setNumPages(pdf.numPages??1);
  // };

  const highlightText = (keyword, pageNumber) => {
    // const viewerInstance = viewerRefs.current[activeTab];
    var viewer = viewerRefs.current[activeTab];

    // Assuming we are highlighting text on page 1
    // var pageNumber = 1;
    var keyword = keyword; // The text you want to highlight

    // Find the text bounds on the specified page

    viewer.textSearchModule.findText(keyword, (result) => {
      // console.log("Text not found for highlighting." + result);
      if (result && result.length > 0) {
        result.forEach((item) => {
          viewer.annotationSettings.isLock = true;
          // Assuming item.bounds contains the bounding box
          viewer.annotation.addAnnotation("Highlight", {
            bounds: item.bounds, // Use the bounds provided by the searchText result
            pageNumber: pageNumber,
          });
        });
      } else {
        // console.log("Text not found for highlighting.");
      }
    });
  };

  const handleAjaxRequestFailed = (args) => {
    // Handle AJAX request failure silently
    console.error("AJAX request failed: ", args);

    // const viewerInstance = viewerRefs.current[activeTab];

    // // Unload the current document from the viewer
    // if (viewerInstance) {
    //   viewerInstance.unload();
    //   var mytab = tabs.find((tab) => tab.id === activeTab);
    //   // viewerInstance.unload();
    //   viewerInstance.documentPath = `${mytab.pdf_location.replace(
    //     "uploads/searchable/",
    //     ""
    //   )}`;
    //   viewerInstance.loadDocument();

    //   // Optionally, retry loading the document or show a custom notification
    //   // loadDocumentForTab(tabs.find(tab => tab.id === activeTab));
    // }

    // Prevent showing the default error message box
    // args.cancel = true;
  };

  useEffect(() => {
    // console.log(addTabs);

    // Check if addTabs is not an empty object
    if (Object.keys(addTabs).length > 0) {
      addTab(addTabs); // Call addTab if addTabs is not empty
    }
  }, [addTabs]);
  useEffect(() => {
    // console.log(multiSearchResult);
  }, [multiSearchResult]);
  useEffect(() => {
    // console.log(alerttab);
  }, [alerttab]);

  useEffect(() => {
    // console.log(firsttab);

    if (firsttab.length > 0 && firsttab[0].id && firsttab[0].title) {
      setTabs(firsttab);
      // console.log("check", firsttab[0].pdf_location);
      // loadPdf(firsttab[0].pdf_location);
      setPdfData(firsttab[0].pdf_location.replace("uploads/searchable/", ""));
      // console.log(pdfData);
      // fetchBooklimit(firsttab[0].id);
      setActiveTab(firsttab[0].id);
      loadDocumentForTab(firsttab[0]);
      fetchHeadings(firsttab[0]);
    }
  }, [firsttab]);

  // const addTab = (newtab) => {
  //   setOptions([]);
  //   setIssOpen(false);
  //   // fetchBooklimit(selectedTab.id);
  //   const existingTabIndex = tabs.find((tab) => tab.id === newtab.id);

  //   if (existingTabIndex) {
  //     console.log(existingTabIndex);
  //     if (activeTab !== existingTabIndex.id) {
  //       // unloadDocument();
  //       // loadPdf(tabs[existingTabIndex].pdf_location);
  //       // Tab already exists, make it active
  //       // setPdfData(
  //       //   tabs[existingTabIndex].pdf_location.replace("uploads/searchable/", "")
  //       // );
  //       setActiveTab(existingTabIndex.id);

  //       // fetchBooklimit(existingTabIndex.id);
  //     }
  //     setCurrentKeyword({
  //       keyword: "",
  //       matchCase: false,
  //       wholeWords: false,
  //     });
  //     setOptions([]);
  //     setIssOpen(false);
  //   } else {
  //     setIssOpen(false);
  //     setCurrentKeyword({
  //       keyword: "",
  //       matchCase: false,
  //       wholeWords: false,
  //     });
  //     setOptions([]);
  //     const newTabs = [...tabs, newtab];
  //     setTabs(newTabs);
  //     setActiveTab(newtab.id);
  //     // fetchBooklimit(newtab.id);
  //     // if(newtab!==undefined){
  //     if (newtab && newtab.id && newtab.title) {
  //       console.log("check", newtab.pdf_location);

  //       setPdfData(newtab.pdf_location.replace("uploads/searchable/", ""));
  //       window.history.pushState(
  //         null,
  //         "",
  //         `/pdfViewer/${newtab.id}/${htmlToPlainText(newtab.title)}`
  //       );
  //     }
  //   }
  //   loadDocumentForTab(newtab);
  //   // setPdfData(newtab.)
  //   setcurrentbook(newtab);
  //   console.log(newtab);
  //   console.log(newtab.pdf_location);
  //   fetchHeadings(newtab);
  // };

  const [isOpen, setIsOpen] = useState(false);
  const [isaddOpen, setIsaddOpen] = useState(false);
  const [isbookOpen, setIsbookOpen] = useState(false);
  const [isheadOpen, setIsheadOpen] = useState(false);
  const [istabOpen, setItabOpen] = useState(false);
  const [isAlerttabOpen, setAlertItabOpen] = useState(false);
  const [isnewOpen, setInewOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [matchCase, setMatchCase] = useState(false);
  const count = () => {
    // var viewer = document.getElementById('container').ej2_instances[0];
    const viewerInstance = viewerRefs.current[activeTab];
    // console.log("Total page count = " + viewerInstance.pageCount);
    setNumPages(viewerInstance.pageCount);
  };
  const jumpToPage = (num) => {
    // var viewer = document.getElementById('container').ej2_instances[0];
    const viewerInstance = viewerRefs.current[activeTab];
    viewerInstance.navigation.goToPage(num + 1);
    // console.log("Total page count = " + viewerInstance.pageCount);
    // console.log("Going to page  " + num);
  };
  const handleSearch = () => {
    // var viewer = document.getElementById('container').ej2_instances[0];
    const viewerInstance = viewerRefs.current[activeTab];
    viewerInstance.textSearchModule.searchText(searchText, matchCase); // Perform search
    // console.log(
    //   "Search initiated for text:",
    //   searchText,
    //   "with match case:",
    //   matchCase
    // );
  };
  const extractText = (imageUrl) => {
    Tesseract.recognize(imageUrl, "heb", {
      logger: (m) => console.log(m),
    }).then(({ data: { text } }) => {
      setOcrText(text);
      openModal();

      resetSelection(); // Reset selection state
    });
  };
  const handleMouseDown = (e) => {
    if (!isSnipping) return;
    const rect = pdfViewerRef.current.getBoundingClientRect();
    setStartPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setEndPoint(null);
  };

  const handleMouseMove = (e) => {
    if (!isSnipping || !startPoint) return;
    const rect = pdfViewerRef.current.getBoundingClientRect();
    setEndPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleMouseUp = () => {
    if (!isSnipping || !startPoint || !endPoint) return;
    captureSnip();
    setIsSnipping(false); // Stop snipping after selection
  };

  const captureSnip = () => {
    const { x: startX, y: startY } = startPoint;
    const { x: endX, y: endY } = endPoint;
    const width = Math.abs(endX - startX);
    const height = Math.abs(endY - startY);

    const pdfViewerElement = pdfViewerRef.current;

    // Hide selection box before capturing
    const selectionBox = document.getElementById("selection-box");
    if (selectionBox) selectionBox.style.display = "none";

    html2canvas(pdfViewerElement, {
      x: Math.min(startX, endX),
      y: Math.min(startY, endY),
      width,
      height,
      scrollX: 0,
      scrollY: 0,
    })
      .then((canvas) => {
        const snipDataUrl = canvas.toDataURL();
        setSnip(snipDataUrl);
        extractText(snipDataUrl);
      })
      .finally(() => {
        // Show selection box after capturing
        if (selectionBox) selectionBox.style.display = "block";
      });
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(ocrText).then(() => {
      alert("Text copied to clipboard");
    });
  };
  const copylink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      alert("Link copied to clipboard");
    });
  };

  const saveImage = () => {
    if (snip) {
      saveAs(snip, "snipped_image.png");
    }
  };

  const printImage = () => {
    if (snip) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<img src="${snip}" style="width: 100%;" onload="window.print();window.close()" />`
      );
      printWindow.document.close();
    }
  };

  const resetSelection = () => {
    setStartPoint(null);
    setEndPoint(null);
    setIsSnipping(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const openaddModal = () => {
    setIsaddOpen(true);
  };

  const closeaddModal = () => {
    setIsaddOpen(false);
  };
  const openbookModal = () => {
    setIsbookOpen(true);
  };

  const closebookModal = () => {
    setIsbookOpen(false);
  };
  const openheadModal = () => {
    setIsheadOpen(true);
  };

  const closeheadModal = () => {
    setIsheadOpen(false);
  };
  const openTabModal = () => {
    setItabOpen(true);
  };

  const closeTabModal = () => {
    setItabOpen(false);
  };

  const opennewModal = () => {
    setInewOpen(true);
  };

  const closenewModal = () => {
    setInewOpen(false);
  };
  const [activeAccordion1, setActiveAccordion1] = useState(true);
  const [activeAccordion2, setActiveAccordion2] = useState(false);
  const [activeAccordion3, setActiveAccordion3] = useState(false);
  const [activeAccordion4, setActiveAccordion4] = useState(false);

  const toggleAccordion = (accordionId) => {
    // Close all accordions
    setActiveAccordion1(false);
    setActiveAccordion2(false);
    setActiveAccordion3(false);
    setActiveAccordion4(false);

    // Toggle the selected accordion
    switch (accordionId) {
      case "accordion-open-body-1":
        setActiveAccordion1(true);
        break;
      case "accordion-open-body-2":
        setActiveAccordion2(true);
        break;
      case "accordion-open-body-3":
        setActiveAccordion3(true);
        break;
      case "accordion-open-body-4":
        setActiveAccordion4(true);

        break;
      default:
        break;
    }
  };

  const [isFavorite, setIsFavorite] = useState({});
  const checkIfFavorite = async () => {
    const userData = getUserData();

    try {
      const res = await axios.get(
        `${BASE_URL}/api_book.php/favoritebooks?user_id=${userData.id}`
      );
      const favoriteBooks = res.data.reduce((acc, book) => {
        acc[book.book_id] = true;
        return acc;
      }, {});

      setIsFavorite(favoriteBooks);
    } catch (err) {
      console.error(err);
    }
  };
  const handleToggleFavorite = async (bookId) => {
    const userData = getUserData();

    if (isFavorite[bookId]) {
      // Remove from favorites logic
      try {
        const response = await axios.delete(
          `${BASE_URL}/api_book.php/favoritebooks/${bookId}`
        );

        if (response.status === 200) {
          alert("Book removed from favorites!");
          setIsFavorite((prevState) => ({ ...prevState, [bookId]: false }));
        } else {
          alert("Failed to remove book from favorites");
        }
      } catch (error) {
        alert("Failed to remove book from favorites. Please try again later.");
        console.error("Error removing book from favorites:", error);
      }
    } else {
      // Add to favorites logic
      try {
        const formData = new FormData();
        formData.append("user_id", userData.id);
        formData.append("book_id", bookId);

        const config = {
          method: "post",
          url: `${BASE_URL}/api_book.php/favoritebooks`,
          headers: { "Content-Type": "multipart/form-data" },
          data: formData,
        };

        const response = await axios(config);

        alert("Book added to favorites!");
        setIsFavorite((prevState) => ({ ...prevState, [bookId]: true }));
      } catch (error) {
        alert("Failed to add book to favorites. Please try again later.");
        console.error("Error adding book to favorites:", error);
      }
    }
  };
  // const checkIfFavorite = () => {
  //   console.log(currentUser.id);
  //   const userData = getUserData();

  //   axios
  //     .get(`${BASE_URL}/api_book.php/favoritebooks?user_id=${userData.id}&book_id=${id}`)
  //     .then((res) => {
  //       console.log(res.data);

  //       setIsFavorite(res.data.length > 0);
  //       setFavorite(res.data[0].id);
  //        console.log(res.data[0].id); // Update isFavorite state based on API response
  //     })
  //     .catch((err) => console.log(err));
  // };
  const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level (in percentage)
  const handleZoomIn = () => {
    if (zoomLevel < 200) {
      // Limit maximum zoom to 200%
      setZoomLevel(zoomLevel + 10);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 50) {
      // Limit minimum zoom to 50%
      setZoomLevel(zoomLevel - 10);
    }
  };
  const [isBlurred, setIsBlurred] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [limitPageMap, setLimitPageMap] = useState({});
  const [isBlurredMap, setIsBlurredMap] = useState({});
  const [showDialogMap, setShowDialogMap] = useState({});

  // Function to fetch the book's reading limit for the given tab
  const fetchBookLimit = async (bookId, tabId) => {
    try {
      const userData = getUserData(); // Ensure you have user data available
      if (!userData || !userData.id) {
        throw new Error("User data is missing");
      }
      console.log(`limit check userid ${userData.id}`);
      console.log(`limit check ${bookId}`);
      // Fetch the book limit from the API
      const response = await axios.get(
        `${BASE_URL}/api_mycollection.php?user_id=${userData.id}&b_id=${bookId}`
      );

      if (response.data && response.data.read_limit !== null) {
        // Update the limit for the specific tab
        console.log(`limit check ${response.data.read_limit}`);
        setLimitPageMap((prev) => ({
          ...prev,
          [tabId]: response.data.read_limit,
        }));
      } else {
        // If no read limit is provided, set to null for this tab
        setLimitPageMap((prev) => ({
          ...prev,
          [tabId]: null,
        }));
        console.warn("Read limit not found or null for the book.");
      }
    } catch (error) {
      setLimitPageMap((prev) => ({
        ...prev,
        [tabId]: null,
      }));
      console.error("Error fetching book limit:", error);
    }
  };

  // Function to handle page change for a specific tab
  const handlePageChange = (args) => {
    const currentPage = args.currentPageNumber;
    const limitPage = limitPageMap[activeTab];
    //console.log({page:currentPage,limitPage:limitPage});
    // Check if current page exceeds the free limit for this tab
    if (limitPage !== null && currentPage >= limitPage) {
      setIsBlurredMap((prev) => ({
        ...prev,
        [activeTab]: true,
      }));
      setShowDialogMap((prev) => ({
        ...prev,
        [activeTab]: true,
      }));
    } else {
      setIsBlurredMap((prev) => ({
        ...prev,
        [activeTab]: false,
      }));
      setShowDialogMap((prev) => ({
        ...prev,
        [activeTab]: false,
      }));
    }
  };

  // Fetch the book limit when a new tab is active
  useEffect(() => {
    if (activeTab) {
      const activeBook = tabs.find((tab) => tab.id === activeTab);
      const userData = getUserData(); // Ensure you have user data available
      // if (!userData || !userData.id) {
      //     throw new Error("User data is missing");
      // }
      if (activeBook) {
        if (!userData || !userData.id) {
          fetchBookLimit(activeBook.id, activeTab);
        }
        // else{
        if (userData.id != 12) {
          fetchBookLimit(activeBook.id, activeTab);
        }
        // }
      }
    }
  }, [activeTab]);

  const closeDialog = (tabId) => {
    setShowDialogMap((prev) => ({ ...prev, [tabId]: false }));
  };

  // const highlightPluginInstance = highlightPlugin({});
  const [selectedTab, setSelectedTab] = useState(0); // State to track active tab index
  const currentPageInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlights, setHighlights] = useState([]);
  const [currentKeyword, setCurrentKeyword] = useState({
    keyword: "",
    matchCase: false,
    wholeWords: false,
  });
  const [bookmarks, setBookmarks] = useState([]);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  //const searchPluginInstance = searchPlugin();
  const pageRenderInitiate = (args) => {
    // This method is called when the page rendering starts
    // console.log("Rendering of pages started");
    // console.log(args);
  };
  const pageRenderComplete = (args) => {
    // This method is called when the page rendering completes
    // console.log("Rendering of pages completed");
    // console.log(args);
  };
  const handletext = (args) => {
    // console.log("search:", args);
  };

  const reverseKeyword = (keyword) => {
    // console.log(keyword);
    if (typeof keyword.keyword === "string") {
      return {
        keyword: keyword.keyword.split("").reverse().join(""),
        matchCase: keyword.matchCase,
        wholeWords: keyword.wholeWords,
      };
    }
    // If keyword is not a string, return it unchanged or handle it as needed
    console.error("Keyword is not a string:", keyword);
    return keyword;
  };

  const handleAjaxRequestSuccess = (args) => {
    // console.log("AJAX request succeeded:", args);

    // Handle additional success logic here
    const viewerInstance = viewerRefs.current[activeTab];

    // For example, check if it's loading a specific page or if the document has fully loaded
    if (args.action === "RenderPdfPages" && args.status === 200) {
      // console.log("Page successfully rendered.");
    }

    // You can also check if additional data has been passed
    if (args.data) {
      // console.log("Received additional data:", args.data);
      if (args.data.pageCount) {
        setNumPages(args.data.pageCount);
      }
    }
  };

  // const handleSearch = () => {
  //   search({
  //     keyword: searchTerm,
  //     matchCase: currentKeyword.matchCase,
  //     wholeWords: currentKeyword.wholeWords,
  //   });
  // };

  // useEffect(() => {
  //   fetchHeadings(activeTab);
  // }, [activeTab]);

  const fetchHeadings = async (tab) => {
    setBookmarks([]);
    if (pdfData) {
      // console.log(tab.id);
      // console.log("location", `../../../api/${tab.pdf_location}`);
      try {
        // const pdfDocument = await pdfjs.getDocument(
        //   pdfData
        // ).promise;
        // const numPages = pdfDocument.numPages;
        // setNumPages(pdfDocument.numPages);
        let url = `${BASE_URL}/api_books_indexes.php?book_id=${tab.id}`;
        const response = await axios.get(url);
        const volumeData = response.data;
        console.log(response.data);
        // if (Array.isArray(volumeData)) {
        setBookmarks(volumeData);
        // } else {
        //   console.error("Expected an array of volumes");
        // }

        // const headings = [];

        // const regex = /[\u0590-\u05FF]+/g; // Regex for Hebrew characters

        // for (let i = 1; i <= numPages; i++) {
        //   const page = await pdfDocument.getPage(i);
        //   const textContent = await page.getTextContent();

        //   const firstHeadingItem = textContent.items.find((item) => {
        //     const matches = item.str.match(regex);
        //     return matches && matches.length > 0; // Adjust this condition based on your PDF's headings structure
        //   });

        //   if (firstHeadingItem) {
        //     headings.push({
        //       title: firstHeadingItem.str.trim(),
        //       pageIndex: i - 1, // pageIndex is zero-based for react-pdf-viewer
        //       index: firstHeadingItem.transform[5], // Adjust this based on where your heading index is stored
        //     });
        //   }
        // }

        // setBookmarks(headings);
      } catch (error) {
        console.error("Error fetching headings:", error);
        // setBookmarks([]);
      }
    }
  };

  // componentDidMount() {
  //   viewer = document.getElementById('container').ej2_instances[0];
  // }
  const copyImageToClipboard = () => {
    if (snip) {
      fetch(snip)
        .then((response) => response.blob())
        .then((blob) => {
          const item = new ClipboardItem({ "image/png": blob });
          navigator.clipboard.write([item]);
        })
        .then(() => {
          alert("Image copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy image: ", err);
        });
    }
  };
  const [issOpen, setIssOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const mapSearchResults = (data, mapdata) => {
    if (data.length === 0) {
      // console.log("No data received");
      return [];
    }

    const firstResult = data[0];
    // console.log("Received data:", firstResult);

    // Map the pages array to a simpler format
    const results = firstResult.pages.map((item) => ({
      page: item.page,
      occurrences: item.occurrences,
      text: mapdata.word,
      filename: mapdata.filename, // Number of occurrences on the page
    }));

    // console.log("Mapped results:", results);
    return results;
  };
  const fetchResults = async (text, file) => {
    setLoading(true); // Set loading to true while fetching data
    setOptions([]); // Clear previous options immediately

    try {
      const response = await axios.post(
        `${NODE_BASE_URL}/searchbytags`,
        {
          tag: file,
          word: text,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set Content-Type to application/json
          },
        }
      );

      if (response.data) {
        // console.log(response.data);
        const mappedResults = mapSearchResults(response.data, {
          filename: response.data[0].pdf_location.replace(
            "uploads/searchable/",
            ""
          ),
          word: text,
        });
        // console.log(mappedResults);
        setOptions(mappedResults);
      } else {
        setOptions([]); // No results to show
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
      setOptions([]); // Clear options on error
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  const toggleDropdown = (text, file) => {
    setOptions([]);
    setIssOpen((prev) => !prev);
    // console.log(text, file);
    if (!issOpen) {
      // setCurrentKeyword({
      //   keyword: "",
      //   matchCase: false,
      //   wholeWords: false,
      // });
      setOptions([]);
      fetchResults(text, file); // Fetch data when opening the dropdown
    }
  };
  const renderPDFViewer = (pdfUrl, tab) => (
    // <PdfViewerComponent
    // ref={(ref) => (viewerRefs.current[tab.id] = ref)}
    // id={`pdf-viewer-${tab.id}`}
    //   documentPath={pdfUrl}
    //   serviceUrl="https://oizvehadar.com/pdfsync1/pdfviewer"
    //   style={{ height: '640px' }}
    // >
    //   <Inject
    //     services={[
    //       Toolbar,
    //       Magnification,
    //       Navigation,
    //       LinkAnnotation,
    //       BookmarkView,
    //       ThumbnailView,
    //       Print,
    //       TextSelection,
    //       TextSearch,
    //       Annotation,
    //       FormFields,
    //       FormDesigner,
    //       PageOrganizer,
    //     ]}
    //   />
    // </PdfViewerComponent>
    <PdfViewerComponent
      id={`pdf-viewer-${tab.id}`}
      ref={(ref) => (viewerRefs.current[tab.id] = ref)}
      documentPath={loadedDocs[tab.id] || ""}
     // serviceUrl="https://oizvehadar.com/testpdfs/pdfviewer/"
       serviceUrl="https://oizvehadar.com/pdfsync1/pdfviewer"
      ajaxRequestFailed={handleAjaxRequestFailed}
      enableVirtualization={true}
      height="80vh"
      pageRenderInitiate={pageRenderInitiate}
      pageRenderComplete={pageRenderComplete}
      showNotificationDialog={false}
      pageChange={(args) => handlePageChange(args, tab.id)}
      enableNavigation={true}
      enableLocalStorage={true}
      // retryTimeout={20}
      // retryCount={100}
      // ajaxRequestSuccess={(args) => handlePageChange(args, tab.id)}
      enableBookmark={true}
      enableTextSelection={false}
      // enableAnnotation={false}
      enableAnnotationToolbar={false}
      annotationSettings={{
        author: "", // or set appropriate author
        isLock: false, // ensure annotations aren't locked
        allowPopup: false, // disable popups by default
      }}
      //  enableAnnotation={false}
      //enableTextSearch={true}
      // enableAnnotation={true}
      documentLoad={() => {
        restoreViewerState(tab.id);
        // fetchBooklimit(tab.id);
        count();
      }}
      toolbarSettings={{
        showTooltip: true,
        toolbarItems: ["PageNavigationTool", "MagnificationTool"],
      }}
    >
      {/* Inject the required services */}
      <Inject
        services={[
          Toolbar,
          Magnification,
          Navigation,
          Annotation,
          LinkAnnotation,
          BookmarkView,
          ThumbnailView,
          Print,
          TextSelection,
          TextSearch,
          FormFields,

          //   PageOrganizer,
        ]}
      />
    </PdfViewerComponent>
  );
  return (
    <div className="bg-gray-100">
      <div className="bg-[#F7F7F7] shadow-md">
        <div className="flex items-center  justify-between px-4 py-0 pt-4">
          <div className="flex space-x-2  w-full" dir="rtl">
            <div className="overflow-x-auto	w-auto flex">
              {tabs.map((title, index) => (
                <div
                  key={index}
                  className={`cursor-pointer min-w-[90px] border-t border-l border-r border-gray-300 w-full flex px-3  rounded-t-lg ${
                    activeTab === tabs[index].id ? "bg-white  " : "bg-[#F7F7F7]"
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  <span className="text-[10px] m-auto truncate  w-[95%]">
                    {htmlToPlainText(title.title)}
                  </span>
                  <button
                    onClick={() => closeTab(tabs[index].id)}
                    className="ml-2  w-[5%] text-sm text-gray-500 hover:text-gray-800 focus:outline-none"
                  >
                    <IoClose className="w-4 h-4 inline hover:text-red-500" />
                  </button>
                </div>
              ))}
            </div>
            <div className="w-auto">
              <button
                onClick={openTabModal}
                className="px-3 mr-2  bg-[#6D3815] text-white rounded-t-lg hover:bg-red-600 focus:outline-none"
              >
                <span className="text-sm">+</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        direction={"rtl"}
        selected={activeTab}
        onSelect={() => {
          handleTabClick();
        }}
      >
        {tabs.map((tab, index) => (
          <TabPanel key={index} className="">
            <div className="flex">
              {tabs.map(
                (tab) =>
                  activeTab === tab.id && (
                    <div key={tab.id} className="w-[80%] ">
                      <div className="flex items-center relative justify-center h-[82vh]">
                        {/*second pdfviewer */}

                        <div
                          ref={pdfViewerRef}
                          style={{
                            position: "",
                            width: "100vw",
                            height: "82vh",
                            filter: isBlurredMap[tab.id]
                              ? "blur(10px)"
                              : "none",
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                        >
                          <div className="flex items-center space-x-2 py-1 px-2">
                            {" "}
                            <div className="w-[200px] search-left-dv">
                              <div className="pdf-vertical-bar ">
                                <div className="flex flex-row">
                                  {/* start buton */}
                                  {/* <div class="group relative flex w-full justify-center">
                      <button className="mb-2 p-2 hover:bg-gray-300 rounded-md">
                        <RiPageSeparator />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("All_Pages")}
                      </span>
                    </div> */}
                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={() => {
                                        setIsSnipping(true);
                                        // openModal
                                      }}
                                      className="mb-2 	 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <IoMdCut />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Text_or_Image")}
                                    </span>
                                  </div>
                                  {isOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center z-[199]">
                                      {/* Background overlay */}
                                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                      {/* Modal dialog */}
                                      <div className="relative bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {t("Marker_Options")}
                                          </h2>
                                          <button
                                            onClick={closeModal}
                                            className="text-gray-700 hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div className="w-full">
                                          <div className="flex w-[90%] m-auto">
                                            <div className="w-[45%] m-auto img-maekr">
                                              <p className="mb-2 mt-2">
                                                {t(":image")}
                                              </p>
                                              <img
                                                src={snip}
                                                alt="snip"
                                                style={{ maxWidth: "100%" }}
                                              />
                                              {/* <input className="border h-[100px] w-[250px] border-black ax" name="image"  /> */}
                                            </div>
                                            <div className="w-[45%] m-auto img-maekr">
                                              <p className="mb-2 mt-2 flex items-center">
                                                {t(":text(OCR)")}
                                                <button
                                                  className="py-0 px-4 rounded-full text-white ml-2 mr-2 bg-[#6D3815] cursor-pointer"
                                                  onClick={handleZoomIn}
                                                >
                                                  +
                                                </button>
                                                <button
                                                  className="py-0 px-4 rounded-full text-white ml-2 mr-2 bg-[#6D3815] cursor-pointer"
                                                  onClick={handleZoomOut}
                                                >
                                                  -
                                                </button>
                                              </p>
                                              <p
                                                style={{
                                                  fontSize: `${zoomLevel}%`,
                                                }}
                                                className="border-black border p-4 pt-0"
                                              >
                                                {ocrText}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        {/* Footer */}
                                        <div className="mt-4 bg-[#E9E9E9] p-4 flex justify-center">
                                          <button
                                            className="px-4 py-1  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                            onClick={copyToClipboard}
                                          >
                                            {t("Copy_Text")}
                                          </button>
                                          <button
                                            className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                            onClick={saveImage}
                                          >
                                            {t("Save_image")}
                                          </button>
                                          <button
                                            className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                            onClick={printImage}
                                          >
                                            {t("print")}
                                          </button>
                                          {/* <button
          className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
        >
       Create a marker 
        </button> */}
                                          <button
                                            className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                            onClick={copyImageToClipboard}
                                          >
                                            {t("copy_img")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    {/* <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                        <MdPrint />
                      </button> */}
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("print")}
                                    </span>
                                  </div>
                                  {/* End button */}
                                  {/* start buton */}
                                  {/* <div class="group relative flex w-full justify-center">
                      <button
                        onClick={openaddModal}
                        className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                      >
                        <GrChapterAdd />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("User_add-ons")}
                      </span>
                    </div> */}
                                  {/* modal content */}
                                  {isaddOpen && (
                                    <div className="fixed inset-0 flex items-center h-[600px]  justify-left left-[47px] top-[138px] z-[199]">
                                      {/* Background overlay */}
                                      {/* <div className="absolute inset-0 bg-gray-500 opacity-75"></div> */}

                                      {/* Modal dialog */}
                                      <div className="relative bg-white w-[20%] h-[600px] left-0 rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {t("Marker_Options")}
                                          </h2>
                                          <button
                                            onClick={closeaddModal}
                                            className=" hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div
                                          id="accordion-open"
                                          data-accordion="open"
                                          dir="rtl"
                                        >
                                          {/* Accordion 1 */}
                                          <div>
                                            <div className="flex border-b-0 border-[#dedede] border   hover:bg-[#f7f7f7] gap-1">
                                              <button className=" text-[#3b200f]  mr-2 rounded">
                                                <SiImessage />
                                              </button>
                                              <button
                                                type="button"
                                                className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]   focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
                                                  activeAccordion1
                                                    ? "bg-[#f7f7f7]"
                                                    : ""
                                                }`}
                                                data-accordion-target="#accordion-open-body-1"
                                                aria-expanded={activeAccordion1}
                                                onClick={() =>
                                                  toggleAccordion(
                                                    "accordion-open-body-1"
                                                  )
                                                }
                                              >
                                                <span className="flex items-center justify-between w-full">
                                                  <span className="flex items-center">
                                                    {t("Note")}
                                                  </span>
                                                  <IoMdArrowDropup
                                                    className={`w-5 h-5 me-2 shrink-0 transform ${
                                                      activeAccordion1
                                                        ? "rotate-0"
                                                        : "rotate-180"
                                                    }`}
                                                  />
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              id="accordion-open-body-1"
                                              className={`p-2 bg-white h-[200px] ${
                                                activeAccordion1
                                                  ? "block"
                                                  : "hidden"
                                              }`}
                                              aria-labelledby="accordion-open-heading-1"
                                            >
                                              <div className="text-gray-500">
                                                <div className="relative">
                                                  <input
                                                    type="search"
                                                    id="Searchbooks"
                                                    placeholder={t(
                                                      "search_in_note"
                                                    )}
                                                    dir="rtl"
                                                    className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
                                                  />

                                                  <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                                                    <button
                                                      type="button"
                                                      className="text-black  hover:text-gray-700"
                                                    >
                                                      <span className="sr-only">
                                                        Search
                                                      </span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="h-3 w-3"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                        />
                                                      </svg>
                                                    </button>
                                                  </span>
                                                </div>
                                                <div
                                                  className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                  dir="ltr"
                                                >
                                                  <ul
                                                    className="flex flex-col h-[160px] pr-2 space-y-1"
                                                    dir="rtl"
                                                  >
                                                    <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {t("Note")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                    <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {" "}
                                                        {t("Note")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* Accordion 2 */}
                                          <div>
                                            <div className="flex overflow-auto border-b-0 border-[#dedede] border  hover:bg-[#f7f7f7] gap-1">
                                              <button className=" text-[#3b200f]  mr-2 rounded">
                                                <FaKey />
                                              </button>
                                              <button
                                                type="button"
                                                className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]   hover:bg-[#f7f7f7] gap-3 ${
                                                  activeAccordion2
                                                    ? "bg-[#f7f7f7]"
                                                    : ""
                                                }`}
                                                data-accordion-target="#accordion-open-body-2"
                                                aria-expanded={activeAccordion2}
                                                onClick={() =>
                                                  toggleAccordion(
                                                    "accordion-open-body-2"
                                                  )
                                                }
                                              >
                                                <span className="flex items-center justify-between w-full">
                                                  <span className="flex items-center">
                                                    {t("Indexes")}
                                                  </span>
                                                  <IoMdArrowDropup
                                                    className={`w-5 h-5 me-2 shrink-0 transform ${
                                                      activeAccordion2
                                                        ? "rotate-0"
                                                        : "rotate-180"
                                                    }`}
                                                  />
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              id="accordion-open-body-2"
                                              className={`p-2 bg-white h-[200px] ${
                                                activeAccordion2
                                                  ? "block"
                                                  : "hidden"
                                              }`}
                                              aria-labelledby="accordion-open-heading-2"
                                            >
                                              <div className="text-gray-500">
                                                <div className="relative">
                                                  <input
                                                    type="search"
                                                    id="Searchbooks"
                                                    placeholder="keys"
                                                    dir="rtl"
                                                    className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
                                                  />

                                                  <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                                                    <button
                                                      type="button"
                                                      className="text-black  hover:text-gray-700"
                                                    >
                                                      <span className="sr-only">
                                                        Search
                                                      </span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="h-3 w-3"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                        />
                                                      </svg>
                                                    </button>
                                                  </span>
                                                </div>
                                                <div
                                                  className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                  dir="ltr"
                                                >
                                                  <ul
                                                    className="flex flex-col h-[160px] pr-2 space-y-1"
                                                    dir="rtl"
                                                  >
                                                    <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">Key 1</a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                    <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">Key 2</a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* Accordion 3 */}
                                          <div>
                                            <div className="flex border-b-0 border-[#dedede] border hover:bg-[#f7f7f7] gap-1">
                                              <button className=" text-[#3b200f]  mr-2 rounded">
                                                <FaMarker />
                                              </button>
                                              <button
                                                type="button"
                                                className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
                                                  activeAccordion3
                                                    ? "bg-[#f7f7f7]"
                                                    : ""
                                                }`}
                                                data-accordion-target="#accordion-open-body-3"
                                                aria-expanded={activeAccordion3}
                                                onClick={() =>
                                                  toggleAccordion(
                                                    "accordion-open-body-3"
                                                  )
                                                }
                                              >
                                                <span className="flex items-center justify-between w-full">
                                                  <span className="flex items-center">
                                                    {t("Marker")}
                                                  </span>
                                                  <IoMdArrowDropup
                                                    className={`w-5 h-5 me-2 shrink-0 transform ${
                                                      activeAccordion3
                                                        ? "rotate-0"
                                                        : "rotate-180"
                                                    }`}
                                                  />
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              id="accordion-open-body-3"
                                              className={`p-2 bg-white h-[200px] ${
                                                activeAccordion3
                                                  ? "block"
                                                  : "hidden"
                                              }`}
                                              aria-labelledby="accordion-open-heading-3"
                                            >
                                              <div className="text-gray-500">
                                                <div
                                                  className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                  dir="ltr"
                                                >
                                                  <ul
                                                    className="flex flex-col h-[160px] pr-2 space-y-1"
                                                    dir="rtl"
                                                  >
                                                    <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {t("Marker")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                    <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {" "}
                                                        {t("Marker")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* Accordion 4 */}
                                          <div>
                                            <div className="flex border-b-0 border-[#dedede] border  hover:bg-[#f7f7f7] gap-1">
                                              <button className=" text-[#3b200f]  mr-2 rounded">
                                                <FaLink />
                                              </button>
                                              <button
                                                type="button"
                                                className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
                                                  activeAccordion4
                                                    ? "bg-[#f7f7f7]"
                                                    : ""
                                                }`}
                                                data-accordion-target="#accordion-open-body-4"
                                                aria-expanded={activeAccordion4}
                                                onClick={() =>
                                                  toggleAccordion(
                                                    "accordion-open-body-4"
                                                  )
                                                }
                                              >
                                                <span className="flex items-center justify-between w-full">
                                                  <span className="flex items-center">
                                                    {t("Internal_Links")}
                                                  </span>
                                                  <IoMdArrowDropup
                                                    className={`w-5 h-5 me-2 shrink-0 transform ${
                                                      activeAccordion4
                                                        ? "rotate-0"
                                                        : "rotate-180"
                                                    }`}
                                                  />
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              id="accordion-open-body-4"
                                              className={`p-2 bg-white h-[200px] ${
                                                activeAccordion4
                                                  ? "block"
                                                  : "hidden"
                                              }`}
                                              aria-labelledby="accordion-open-heading-4"
                                            >
                                              <div className="text-gray-500">
                                                <div
                                                  className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                  dir="ltr"
                                                >
                                                  <ul
                                                    className="flex flex-col h-[160px] pr-2 space-y-1"
                                                    dir="rtl"
                                                  >
                                                    <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">Link</a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                    <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">Link</a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* Footer */}
                                      </div>
                                    </div>
                                  )}
                                  {/* modal content end */}

                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                      onClick={copylink}
                                    >
                                      <FaLink />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Create_a_link")}
                                    </span>
                                  </div>
                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                      {isFavorite[activeTab] ? (
                                        <FaHeart
                                          className="text-red-500 hover:text-red-800 cursor-pointer"
                                          onClick={() =>
                                            handleToggleFavorite(activeTab)
                                          } // Remove from favorites on click
                                        />
                                      ) : (
                                        <FaRegHeart
                                          className="text-grey-200 hover:text-gery-600 cursor-pointer"
                                          onClick={() =>
                                            handleToggleFavorite(activeTab)
                                          } // Add to favorites on click
                                        />
                                      )}
                                      {/* <FaRegHeart /> */}
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("add_fav")}
                                    </span>
                                  </div>
                                  {/* End button */}
                                  {/* start buton */}
                                  {/* <div class="group relative flex w-full justify-center">
                      <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                        <IoLocationOutline />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("Hyperlinks")}
                      </span>
                    </div> */}
                                  {/* End button */}
                                  {/* start buton */}
                                  {/* <div class="group relative flex w-full justify-center">
                      <button
                        onClick={opennewModal}
                        className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                      >
                        <VscEmptyWindow />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("Open_book_in_a_new_tab")}
                      </span>
                    </div> */}
                                  {/* End button */}
                                  {/* Start Tab Modal */}
                                  {isnewOpen && (
                                    <div className="fixed  inset-0 flex items-center justify-center z-[199]">
                                      {/* Background overlay */}
                                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                      {/* Modal dialog */}
                                      <div className="relative w-[80%] overflow-auto h-[80%] bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-4 pt-1 pb-2 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {" "}
                                            {t(" book_name")}
                                          </h2>
                                          <button
                                            onClick={closenewModal}
                                            className="text-gray-700 hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div className="flex">
                                          <div className="w-[5%]">
                                            <div className="pdf-vertical-bar p-4">
                                              <div className="flex flex-col">
                                                {/* start buton */}
                                                <div class="group relative flex w-full justify-center">
                                                  <button className="mb-2 p-2 hover:bg-gray-300 rounded-md">
                                                    <RiPageSeparator />
                                                  </button>
                                                  <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                                    {t("All_Pages")}
                                                  </span>
                                                </div>
                                                {/* End button */}
                                                {/* start buton */}
                                                {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><MdOutlineFitScreen />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Full Screen</span>
    </div>
    {/* End button */}
                                                {/* start buton */}
                                                {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><AiOutlineZoomOut />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Zoom Out</span>
    </div> */}
                                                {/* End button */}

                                                {/* start buton */}
                                                {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><AiOutlineZoomIn />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Zoom In</span>
    </div> */}
                                                {/* End button */}
                                                {/* start buton */}
                                                <div class="group relative flex w-full justify-center">
                                                  <button
                                                    onClick={() => {
                                                      setIsSnipping(true);
                                                      // openModal
                                                    }}
                                                    className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                                  >
                                                    <IoMdCut />
                                                  </button>
                                                  <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                                    {t("Text_or_Image")}
                                                  </span>
                                                </div>
                                                {isOpen && (
                                                  <div className="fixed inset-0 flex items-center justify-center z-[199]">
                                                    {/* Background overlay */}
                                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                                    {/* Modal dialog */}
                                                    <div className="relative bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                                                      {/* Header */}
                                                      <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                                        <h2 className="text-lg font-semibold">
                                                          {t("Marker_Options")}
                                                        </h2>
                                                        <button
                                                          onClick={closeModal}
                                                          className="text-gray-700 hover:text-gray-900"
                                                        >
                                                          <svg
                                                            className="h-6 w-6 fill-current"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                          >
                                                            <path
                                                              fillRule="evenodd"
                                                              d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                              clipRule="evenodd"
                                                            />
                                                          </svg>
                                                        </button>
                                                      </div>

                                                      {/* Content */}
                                                      <div className="w-full">
                                                        <div className="flex w-[90%] m-auto">
                                                          <div className="w-[45%] m-auto img-maekr">
                                                            <p className="mb-2 mt-2">
                                                              {t(":image")}
                                                            </p>
                                                            <img
                                                              src={snip}
                                                              alt="snip"
                                                              style={{
                                                                maxWidth:
                                                                  "100%",
                                                              }}
                                                            />
                                                            {/* <input className="border h-[100px] w-[250px] border-black ax" name="image"  /> */}
                                                          </div>
                                                          <div className="w-[45%] m-auto img-maekr">
                                                            <p className="mb-2 mt-2 flex items-center">
                                                              {t(":text(OCR)")}
                                                              <button
                                                                className="py-0 px-4 rounded-full text-white ml-2 mr-2 bg-[#6D3815] cursor-pointer"
                                                                onClick={
                                                                  handleZoomIn
                                                                }
                                                              >
                                                                +
                                                              </button>
                                                              <button
                                                                className="py-0 px-4 rounded-full text-white ml-2 mr-2 bg-[#6D3815] cursor-pointer"
                                                                onClick={
                                                                  handleZoomOut
                                                                }
                                                              >
                                                                -
                                                              </button>
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: `${zoomLevel}%`,
                                                              }}
                                                              className="border-black border p-4 pt-0"
                                                            >
                                                              {ocrText}
                                                            </p>
                                                            <p>{ocrText}</p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* Footer */}
                                                      <div className="mt-4 bg-[#E9E9E9] p-4 flex justify-center">
                                                        <button
                                                          className="px-4 py-1  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                                          onClick={
                                                            copyToClipboard
                                                          }
                                                        >
                                                          {t("Copy_Text")}
                                                        </button>
                                                        <button
                                                          className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                                          onClick={saveImage}
                                                        >
                                                          {t("Save_image")}
                                                        </button>
                                                        <button
                                                          className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                                          onClick={printImage}
                                                        >
                                                          {t("print")}
                                                        </button>
                                                        {/* <button
          className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
        >
       Create a marker 
        </button> */}
                                                        <button
                                                          className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                                          onClick={
                                                            copyImageToClipboard
                                                          }
                                                        >
                                                          {t("copy_img")}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                                {/* End button */}
                                                {/* start buton */}
                                                <div class="group relative flex w-full justify-center">
                                                  <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                                    <MdPrint />
                                                  </button>
                                                  <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                                    {t("print")}
                                                  </span>
                                                </div>
                                                {/* End button */}
                                                {/* start buton */}
                                                {/* <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={openaddModal}
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <GrChapterAdd />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("User_add-ons")}
                                    </span>
                                  </div> */}
                                                {/* modal content */}
                                                {isaddOpen && (
                                                  <div className="fixed inset-0 flex items-center h-[600px]  justify-left left-[47px] top-[138px] z-[199]">
                                                    {/* Background overlay */}
                                                    {/* <div className="absolute inset-0 bg-gray-500 opacity-75"></div> */}

                                                    {/* Modal dialog */}
                                                    <div className="relative bg-white w-[20%] h-[600px] left-0 rounded-lg shadow-lg  pt-0">
                                                      {/* Header */}
                                                      <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                                        <h2 className="text-lg font-semibold">
                                                          {t("Marker_Options")}
                                                        </h2>
                                                        <button
                                                          onClick={
                                                            closeaddModal
                                                          }
                                                          className=" hover:text-gray-900"
                                                        >
                                                          <svg
                                                            className="h-6 w-6 fill-current"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                          >
                                                            <path
                                                              fillRule="evenodd"
                                                              d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                              clipRule="evenodd"
                                                            />
                                                          </svg>
                                                        </button>
                                                      </div>

                                                      {/* Content */}
                                                      <div
                                                        id="accordion-open"
                                                        data-accordion="open"
                                                        dir="rtl"
                                                      >
                                                        {/* Accordion 1 */}
                                                        <div>
                                                          <div className="flex border-b-0 border-[#dedede] border   hover:bg-[#f7f7f7] gap-1">
                                                            <button className=" text-[#3b200f]  mr-2 rounded">
                                                              <SiImessage />
                                                            </button>
                                                            <button
                                                              type="button"
                                                              className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]   focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
                                                                activeAccordion1
                                                                  ? "bg-[#f7f7f7]"
                                                                  : ""
                                                              }`}
                                                              data-accordion-target="#accordion-open-body-1"
                                                              aria-expanded={
                                                                activeAccordion1
                                                              }
                                                              onClick={() =>
                                                                toggleAccordion(
                                                                  "accordion-open-body-1"
                                                                )
                                                              }
                                                            >
                                                              <span className="flex items-center justify-between w-full">
                                                                <span className="flex items-center">
                                                                  {t("Note")}
                                                                </span>
                                                                <IoMdArrowDropup
                                                                  className={`w-5 h-5 me-2 shrink-0 transform ${
                                                                    activeAccordion1
                                                                      ? "rotate-0"
                                                                      : "rotate-180"
                                                                  }`}
                                                                />
                                                              </span>
                                                            </button>
                                                          </div>
                                                          <div
                                                            id="accordion-open-body-1"
                                                            className={`p-2 bg-white h-[200px] ${
                                                              activeAccordion1
                                                                ? "block"
                                                                : "hidden"
                                                            }`}
                                                            aria-labelledby="accordion-open-heading-1"
                                                          >
                                                            <div className="text-gray-500">
                                                              <div className="relative">
                                                                <input
                                                                  type="search"
                                                                  id="Searchbooks"
                                                                  placeholder="Search in notes"
                                                                  dir="rtl"
                                                                  className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
                                                                />

                                                                <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                                                                  <button
                                                                    type="button"
                                                                    className="text-black  hover:text-gray-700"
                                                                  >
                                                                    <span className="sr-only">
                                                                      Search
                                                                    </span>
                                                                    <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      fill="none"
                                                                      viewBox="0 0 24 24"
                                                                      strokeWidth="1.5"
                                                                      stroke="currentColor"
                                                                      className="h-3 w-3"
                                                                    >
                                                                      <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                      />
                                                                    </svg>
                                                                  </button>
                                                                </span>
                                                              </div>
                                                              <div
                                                                className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                                dir="ltr"
                                                              >
                                                                <ul
                                                                  className="flex flex-col h-[160px] pr-2 space-y-1"
                                                                  dir="rtl"
                                                                >
                                                                  <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                                    <a className="">
                                                                      {t(
                                                                        "Note"
                                                                      )}
                                                                    </a>
                                                                    <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                                      <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                                        <AiOutlineDelete className="h-4 w-4" />
                                                                      </button>
                                                                      <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                                        <AiOutlineEdit className="h-4 w-4" />
                                                                      </button>
                                                                    </div>
                                                                  </li>
                                                                  <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                                    <a className="">
                                                                      {t(
                                                                        "Note"
                                                                      )}
                                                                    </a>
                                                                    <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                                      <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                                        <AiOutlineDelete className="h-4 w-4" />
                                                                      </button>
                                                                      <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                                        <AiOutlineEdit className="h-4 w-4" />
                                                                      </button>
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        {/* Accordion 2 */}
                                                        <div>
                                                          <div className="flex overflow-auto border-b-0 border-[#dedede] border  hover:bg-[#f7f7f7] gap-1">
                                                            <button className=" text-[#3b200f]  mr-2 rounded">
                                                              <FaKey />
                                                            </button>
                                                            <button
                                                              type="button"
                                                              className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]   hover:bg-[#f7f7f7] gap-3 ${
                                                                activeAccordion2
                                                                  ? "bg-[#f7f7f7]"
                                                                  : ""
                                                              }`}
                                                              data-accordion-target="#accordion-open-body-2"
                                                              aria-expanded={
                                                                activeAccordion2
                                                              }
                                                              onClick={() =>
                                                                toggleAccordion(
                                                                  "accordion-open-body-2"
                                                                )
                                                              }
                                                            >
                                                              <span className="flex items-center justify-between w-full">
                                                                <span className="flex items-center">
                                                                  {t("Indexes")}
                                                                </span>
                                                                <IoMdArrowDropup
                                                                  className={`w-5 h-5 me-2 shrink-0 transform ${
                                                                    activeAccordion2
                                                                      ? "rotate-0"
                                                                      : "rotate-180"
                                                                  }`}
                                                                />
                                                              </span>
                                                            </button>
                                                          </div>
                                                          <div
                                                            id="accordion-open-body-2"
                                                            className={`p-2 bg-white h-[200px] ${
                                                              activeAccordion2
                                                                ? "block"
                                                                : "hidden"
                                                            }`}
                                                            aria-labelledby="accordion-open-heading-2"
                                                          >
                                                            <div className="text-gray-500">
                                                              <div className="relative">
                                                                <input
                                                                  type="search"
                                                                  id="Searchbooks"
                                                                  placeholder="keys"
                                                                  dir="rtl"
                                                                  className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
                                                                />

                                                                <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                                                                  <button
                                                                    type="button"
                                                                    className="text-black  hover:text-gray-700"
                                                                  >
                                                                    <span className="sr-only">
                                                                      Search
                                                                    </span>
                                                                    <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      fill="none"
                                                                      viewBox="0 0 24 24"
                                                                      strokeWidth="1.5"
                                                                      stroke="currentColor"
                                                                      className="h-3 w-3"
                                                                    >
                                                                      <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                                      />
                                                                    </svg>
                                                                  </button>
                                                                </span>
                                                              </div>
                                                              <div
                                                                className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                                dir="ltr"
                                                              >
                                                                <ul
                                                                  className="flex flex-col h-[160px] pr-2 space-y-1"
                                                                  dir="rtl"
                                                                >
                                                                  <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                                    <a className="">
                                                                      Key 1
                                                                    </a>
                                                                    <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                                      <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                                        <AiOutlineDelete className="h-4 w-4" />
                                                                      </button>
                                                                      <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                                        <AiOutlineEdit className="h-4 w-4" />
                                                                      </button>
                                                                    </div>
                                                                  </li>
                                                                  <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                                    <a className="">
                                                                      Key 2
                                                                    </a>
                                                                    <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                                      <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                                        <AiOutlineDelete className="h-4 w-4" />
                                                                      </button>
                                                                      <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                                        <AiOutlineEdit className="h-4 w-4" />
                                                                      </button>
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        {/* Accordion 3 */}
                                                        <div>
                                                          <div className="flex border-b-0 border-[#dedede] border hover:bg-[#f7f7f7] gap-1">
                                                            <button className=" text-[#3b200f]  mr-2 rounded">
                                                              <FaMarker />
                                                            </button>
                                                            <button
                                                              type="button"
                                                              className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
                                                                activeAccordion3
                                                                  ? "bg-[#f7f7f7]"
                                                                  : ""
                                                              }`}
                                                              data-accordion-target="#accordion-open-body-3"
                                                              aria-expanded={
                                                                activeAccordion3
                                                              }
                                                              onClick={() =>
                                                                toggleAccordion(
                                                                  "accordion-open-body-3"
                                                                )
                                                              }
                                                            >
                                                              <span className="flex items-center justify-between w-full">
                                                                <span className="flex items-center">
                                                                  {t("Marker")}
                                                                </span>
                                                                <IoMdArrowDropup
                                                                  className={`w-5 h-5 me-2 shrink-0 transform ${
                                                                    activeAccordion3
                                                                      ? "rotate-0"
                                                                      : "rotate-180"
                                                                  }`}
                                                                />
                                                              </span>
                                                            </button>
                                                          </div>
                                                          <div
                                                            id="accordion-open-body-3"
                                                            className={`p-2 bg-white h-[200px] ${
                                                              activeAccordion3
                                                                ? "block"
                                                                : "hidden"
                                                            }`}
                                                            aria-labelledby="accordion-open-heading-3"
                                                          >
                                                            <div className="text-gray-500">
                                                              <div
                                                                className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                                dir="ltr"
                                                              >
                                                                <ul
                                                                  className="flex flex-col h-[160px] pr-2 space-y-1"
                                                                  dir="rtl"
                                                                >
                                                                  <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                                    <a className="">
                                                                      {t(
                                                                        "Marker"
                                                                      )}
                                                                    </a>
                                                                    <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                                      <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                                        <AiOutlineDelete className="h-4 w-4" />
                                                                      </button>
                                                                      <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                                        <AiOutlineEdit className="h-4 w-4" />
                                                                      </button>
                                                                    </div>
                                                                  </li>
                                                                  <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                                    <a className="">
                                                                      {t(
                                                                        "Marker"
                                                                      )}
                                                                    </a>
                                                                    <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                                      <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                                        <AiOutlineDelete className="h-4 w-4" />
                                                                      </button>
                                                                      <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                                        <AiOutlineEdit className="h-4 w-4" />
                                                                      </button>
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* Accordion 4 */}
                                                        <div>
                                                          <div className="flex border-b-0 border-[#dedede] border  hover:bg-[#f7f7f7] gap-1">
                                                            <button className=" text-[#3b200f]  mr-2 rounded">
                                                              <FaLink />
                                                            </button>
                                                            <button
                                                              type="button"
                                                              className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
                                                                activeAccordion4
                                                                  ? "bg-[#f7f7f7]"
                                                                  : ""
                                                              }`}
                                                              data-accordion-target="#accordion-open-body-4"
                                                              aria-expanded={
                                                                activeAccordion4
                                                              }
                                                              onClick={() =>
                                                                toggleAccordion(
                                                                  "accordion-open-body-4"
                                                                )
                                                              }
                                                            >
                                                              <span className="flex items-center justify-between w-full">
                                                                <span className="flex items-center">
                                                                  {t(
                                                                    "Internal_Links"
                                                                  )}
                                                                </span>
                                                                <IoMdArrowDropup
                                                                  className={`w-5 h-5 me-2 shrink-0 transform ${
                                                                    activeAccordion4
                                                                      ? "rotate-0"
                                                                      : "rotate-180"
                                                                  }`}
                                                                />
                                                              </span>
                                                            </button>
                                                          </div>
                                                          <div
                                                            id="accordion-open-body-4"
                                                            className={`p-2 bg-white h-[200px] ${
                                                              activeAccordion4
                                                                ? "block"
                                                                : "hidden"
                                                            }`}
                                                            aria-labelledby="accordion-open-heading-4"
                                                          >
                                                            <div className="text-gray-500">
                                                              <div
                                                                className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                                dir="ltr"
                                                              >
                                                                <ul
                                                                  className="flex flex-col h-[160px] pr-2 space-y-1"
                                                                  dir="rtl"
                                                                >
                                                                  <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                                    <a className="">
                                                                      {t(
                                                                        "Link"
                                                                      )}
                                                                    </a>
                                                                    <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                                      <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                                        <AiOutlineDelete className="h-4 w-4" />
                                                                      </button>
                                                                      <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                                        <AiOutlineEdit className="h-4 w-4" />
                                                                      </button>
                                                                    </div>
                                                                  </li>
                                                                  <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                                    <a className="">
                                                                      {t(
                                                                        "Link"
                                                                      )}
                                                                    </a>
                                                                    <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                                      <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                                        <AiOutlineDelete className="h-4 w-4" />
                                                                      </button>
                                                                      <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                                        <AiOutlineEdit className="h-4 w-4" />
                                                                      </button>
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* Footer */}
                                                    </div>
                                                  </div>
                                                )}
                                                {/* modal content end */}

                                                {/* End button */}
                                                {/* start buton */}
                                                <div class="group relative flex w-full justify-center">
                                                  <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                                    <FaLink />
                                                  </button>
                                                  <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                                    {t("Create_link_page")}
                                                  </span>
                                                </div>
                                                {/* End button */}
                                                {/* start buton */}
                                                <div class="group relative flex w-full justify-center">
                                                  <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                                    <FaRegHeart />
                                                  </button>
                                                  <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                                    {t("add_fav")}
                                                  </span>
                                                </div>
                                                {/* End button */}
                                                {/* start buton */}
                                                <div class="group relative flex w-full justify-center">
                                                  <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                                    <IoLocationOutline />
                                                  </button>
                                                  <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                                    {t("Hyperlinks")}
                                                  </span>
                                                </div>
                                                {/* End button */}

                                                {/* start buton */}
                                                <div class="group relative flex w-full justify-center">
                                                  <button
                                                    onClick={() => {
                                                      openbookModal();
                                                    }}
                                                    className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                                  >
                                                    <FaBook />
                                                  </button>
                                                  <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                                    {t("Book_Detail")}
                                                  </span>
                                                </div>
                                                {isbookOpen && (
                                                  <div className="fixed inset-0 flex items-center justify-center z-[199]">
                                                    {/* Background overlay */}
                                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                                    {/* Modal dialog */}
                                                    <div className="relative bg-white w-[50%]  rounded-lg shadow-lg  pt-0">
                                                      {/* Header */}
                                                      <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                                        <h2 className="text-lg font-semibold">
                                                          {t("Book_Detail")}
                                                        </h2>
                                                        <button
                                                          onClick={
                                                            closebookModal
                                                          }
                                                          className=" hover:text-gray-900"
                                                        >
                                                          <svg
                                                            className="h-6 w-6 fill-current"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                          >
                                                            <path
                                                              fillRule="evenodd"
                                                              d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                              clipRule="evenodd"
                                                            />
                                                          </svg>
                                                        </button>
                                                      </div>

                                                      {/* Content */}
                                                      <div className="flex w-full p-4 h-[300px]">
                                                        <div className="w-[30%]">
                                                          <img src="..//images/book.png" />
                                                        </div>
                                                        <div className="w-[70%]">
                                                          <div className="max-w-md mx-auto  bg-white rounded-lg shadow-md overflow-hidden">
                                                            {/* Tab List */}
                                                            <Tabs>
                                                              <TabList className="flex p-4 space-x-2 bg-gray-100">
                                                                <Tab
                                                                  className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                                    selectedTab ===
                                                                    0
                                                                      ? "border-b-2 border-red-500"
                                                                      : ""
                                                                  }`}
                                                                  onClick={() =>
                                                                    setSelectedTab(
                                                                      0
                                                                    )
                                                                  }
                                                                >
                                                                  {t(
                                                                    "Otzar_HaChochma"
                                                                  )}
                                                                </Tab>
                                                                <Tab
                                                                  className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                                    selectedTab ===
                                                                    1
                                                                      ? "border-b-2 border-red-500"
                                                                      : ""
                                                                  }`}
                                                                  onClick={() =>
                                                                    setSelectedTab(
                                                                      1
                                                                    )
                                                                  }
                                                                >
                                                                  {t(
                                                                    "Bibliographic_Data"
                                                                  )}
                                                                </Tab>
                                                                <Tab
                                                                  className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                                    selectedTab ===
                                                                    2
                                                                      ? "border-b-2 border-red-500"
                                                                      : ""
                                                                  }`}
                                                                  onClick={() =>
                                                                    setSelectedTab(
                                                                      2
                                                                    )
                                                                  }
                                                                >
                                                                  {t(
                                                                    "National_Library_of_Israel"
                                                                  )}
                                                                </Tab>
                                                              </TabList>

                                                              {/* Tab Panels */}
                                                              <TabPanel
                                                                className={`p-4 ${
                                                                  selectedTab ===
                                                                  0
                                                                    ? ""
                                                                    : "hidden"
                                                                } overflow-auto h-full`}
                                                              >
                                                                <div>
                                                                  <ul>
                                                                    <li className="p-4 border-b border-gray-600">
                                                                      {t(
                                                                        "book_name"
                                                                      )}
                                                                      :{" "}
                                                                      {htmlToPlainText(
                                                                        currentbook.title
                                                                      )}
                                                                    </li>
                                                                    <li className="p-4 border-b border-gray-600">
                                                                      {t(
                                                                        "arthor_name"
                                                                      )}
                                                                      :{" "}
                                                                      {htmlToPlainText(
                                                                        currentbook.auther
                                                                      )}
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </TabPanel>
                                                              <TabPanel
                                                                className={`p-4 ${
                                                                  selectedTab ===
                                                                  1
                                                                    ? ""
                                                                    : "hidden"
                                                                } overflow-auto h-full`}
                                                              >
                                                                <ul>
                                                                  <li className="p-4 border-b border-gray-600">
                                                                    {t(
                                                                      "book_name"
                                                                    )}
                                                                    :{" "}
                                                                    {htmlToPlainText(
                                                                      currentbook.title
                                                                    )}
                                                                  </li>
                                                                  <li className="p-4 border-b border-gray-600">
                                                                    {t(
                                                                      "arthor_name"
                                                                    )}
                                                                    :{" "}
                                                                    {htmlToPlainText(
                                                                      currentbook.auther
                                                                    )}
                                                                  </li>
                                                                </ul>
                                                              </TabPanel>
                                                              <TabPanel
                                                                className={`p-4 ${
                                                                  selectedTab ===
                                                                  2
                                                                    ? ""
                                                                    : "hidden"
                                                                } overflow-auto h-full`}
                                                              >
                                                                <ul>
                                                                  <li className="p-4 border-b border-gray-600">
                                                                    {t(
                                                                      "book_name"
                                                                    )}
                                                                    :{" "}
                                                                    {htmlToPlainText(
                                                                      currentbook.title
                                                                    )}
                                                                  </li>
                                                                  <li className="p-4 border-b border-gray-600">
                                                                    {t(
                                                                      "arthor_name"
                                                                    )}
                                                                    :{" "}
                                                                    {htmlToPlainText(
                                                                      currentbook.auther
                                                                    )}
                                                                  </li>
                                                                </ul>
                                                              </TabPanel>
                                                            </Tabs>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* content */}
                                                    </div>
                                                  </div>
                                                )}

                                                {/* End button */}
                                                {/* start buton */}
                                                {/* <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={openheadModal}
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <BsCardHeading />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Show_Page_Headings")}
                                    </span>
                                  </div> */}

                                                {/* End button */}
                                              </div>
                                            </div>
                                          </div>

                                          {tabs.map(
                                            (tab) =>
                                              activeTab === tab.id && (
                                                <div className="w-[75%]">
                                                  {/*second pdfviewer */}
                                                  <div
                                                    key={tab.id}
                                                    className="flex items-center justify-center h-[82vh]"
                                                  >
                                                    <div
                                                      ref={pdfViewerRef}
                                                      style={{
                                                        position: "relative",
                                                        width: "100vw",
                                                        height: "82vh",
                                                      }}
                                                      onMouseDown={
                                                        handleMouseDown
                                                      }
                                                      onMouseMove={
                                                        handleMouseMove
                                                      }
                                                      onMouseUp={handleMouseUp}
                                                    >
                                                      {/* <PdfViewerComponent
                                          id={`pdf-viewer-${tab.id}`}
                                          ref={(ref) =>
                                            (viewerRefs2.current[tab.id] = ref)
                                          }
                                           documentPath={
                                            loadedDocs[tab.id] || ""
                                          }
                                          serviceUrl="https://oizvehadar.com/pdfsync1/pdfviewer"
                                          retryTimeout={10}
                                          retryCount={5}
                                          initialRenderPages={50}
                                          textSearchComplete={() => handletext}
                                         
                                          ajaxRequestSuccess={
                                            handleAjaxRequestSuccess
                                          }
                                          ajaxRequestFailed={
                                            handleAjaxRequestFailed
                                          }
                                          showNotificationDialog={false}
                                          height="80vh"
                                          enableRtl="true"
                                          enableBookmark={true}
                                          enableTextSearch={true}
                                          toolbarSettings={{
                                            showTooltip: true,
                                            toolbarItems: [
                                              "PageNavigationTool",
                                              "MagnificationTool",
                                             
                                              "SearchOption",
                                            ],
                                           
                                          }}
                                        >
                                          <Inject
                                            services={[
                                              Toolbar,
                                              Magnification,
                                              Navigation,
                                              Annotation,
                                              LinkAnnotation,
                                              BookmarkView,
                                              ThumbnailView,
                                              Print,
                                              TextSelection,
                                              TextSearch,
                                              FormFields,
                                            ]}
                                          />
                                        </PdfViewerComponent> */}
                                                      {startPoint &&
                                                        endPoint && (
                                                          <div
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              left: Math.min(
                                                                startPoint.x,
                                                                endPoint.x
                                                              ),
                                                              top: Math.min(
                                                                startPoint.y,
                                                                endPoint.y
                                                              ),
                                                              width: Math.abs(
                                                                endPoint.x -
                                                                  startPoint.x
                                                              ),
                                                              height: Math.abs(
                                                                endPoint.y -
                                                                  startPoint.y
                                                              ),
                                                              border:
                                                                "2px dashed black",
                                                              // backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                                            }}
                                                          />
                                                        )}
                                                    </div>
                                                    {/* </Worker> */}
                                                  </div>
                                                </div>
                                              )
                                          )}

                                          {/* <div className="w-[20%]">
                              <Accordion
                                tabs={tabs}
                                bookmarks={bookmarks}
                                jumpToPage={jumpToPage}
                                numPages={numPages}
                                multiSearchResult={multiSearchResult}
                                addTab={addTab}
                                activeTab={activeTab}
                                viewerRefs={viewerRefs2}
                                customsearch={customsearch}
                                // search={search}
                                // setTargetPages={setTargetPages}
                              />
                            </div> */}
                                        </div>
                                        {/* Footer */}
                                      </div>
                                    </div>
                                  )}
                                  {/* End Tab Modal */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={
                                        () => {
                                          alert("Coming Soon");
                                        }

                                        ///openbookModal
                                      }
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <FaBook />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Book_Detail")}
                                    </span>
                                  </div>
                                  {isbookOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center z-[199]">
                                      {/* Background overlay */}
                                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                      {/* Modal dialog */}
                                      <div className="relative bg-white w-[50%]  rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {t("Book_Detail")}
                                          </h2>
                                          <button
                                            onClick={closebookModal}
                                            className=" hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div className="flex w-full p-4 h-[300px]">
                                          <div className="w-[30%]">
                                            {currentbook &&
                                            currentbook.images != null &&
                                            currentbook.images.length > 0 ? (
                                              <img
                                                src={currentbook.images[0]}
                                                alt="Book Cover"
                                              />
                                            ) : (
                                              <img
                                                src="../images/book.png"
                                                alt="Default Book Cover"
                                              />
                                            )}
                                          </div>
                                          <div className="w-[70%]">
                                            <div className="max-w-md mx-auto  bg-white rounded-lg shadow-md overflow-hidden">
                                              {/* Tab List */}
                                              <Tabs>
                                                <TabList className="flex p-4 space-x-2 bg-gray-100">
                                                  <Tab
                                                    className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                      selectedTab === 0
                                                        ? "border-b-2 border-red-500"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      setSelectedTab(0)
                                                    }
                                                  >
                                                    {t("Otzar_HaChochma")}
                                                  </Tab>
                                                  <Tab
                                                    className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                      selectedTab === 1
                                                        ? "border-b-2 border-red-500"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      setSelectedTab(1)
                                                    }
                                                  >
                                                    {t("Bibliographic_Data")}
                                                  </Tab>
                                                  <Tab
                                                    className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                      selectedTab === 2
                                                        ? "border-b-2 border-red-500"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      setSelectedTab(2)
                                                    }
                                                  >
                                                    {t(
                                                      "National_Library_of_Israel"
                                                    )}
                                                  </Tab>
                                                </TabList>

                                                {/* Tab Panels */}
                                                <TabPanel
                                                  className={`p-4 ${
                                                    selectedTab === 0
                                                      ? ""
                                                      : "hidden"
                                                  } overflow-auto h-full`}
                                                >
                                                  <div>
                                                    <ul>
                                                      <li className="p-4 border-b border-gray-600">
                                                        {t("book_name")}:{" "}
                                                        {htmlToPlainText(
                                                          currentbook.title
                                                        ) ?? " "}
                                                      </li>
                                                      <li className="p-4 border-b border-gray-600">
                                                        {t("arthor_name")}:{" "}
                                                        {htmlToPlainText(
                                                          currentbook.auther
                                                        ) ?? " "}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </TabPanel>
                                                <TabPanel
                                                  className={`p-4 ${
                                                    selectedTab === 1
                                                      ? ""
                                                      : "hidden"
                                                  } overflow-auto h-full`}
                                                >
                                                  <ul>
                                                    <li className="p-4 border-b border-gray-600">
                                                      {t("book_name")}:{" "}
                                                      {htmlToPlainText(
                                                        currentbook.title
                                                      ) ?? " "}
                                                    </li>
                                                    <li className="p-4 border-b border-gray-600">
                                                      {t("arthor_name")}:{" "}
                                                      {htmlToPlainText(
                                                        currentbook.auther
                                                      ) ?? " "}
                                                    </li>
                                                  </ul>
                                                </TabPanel>
                                                <TabPanel
                                                  className={`p-4 ${
                                                    selectedTab === 2
                                                      ? ""
                                                      : "hidden"
                                                  } overflow-auto h-full`}
                                                >
                                                  <ul>
                                                    <li className="p-4 border-b border-gray-600">
                                                      {t("book_name")}:{" "}
                                                      {htmlToPlainText(
                                                        currentbook.title
                                                      ) ?? " "}
                                                    </li>
                                                    <li className="p-4 border-b border-gray-600">
                                                      {t("arthor_name")}:{" "}
                                                      {htmlToPlainText(
                                                        currentbook.auther
                                                      ) ?? " "}
                                                    </li>
                                                  </ul>
                                                </TabPanel>
                                              </Tabs>
                                            </div>
                                          </div>
                                        </div>
                                        {/* content */}
                                      </div>
                                    </div>
                                  )}

                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={
                                        () => {
                                          alert("Coming Soon");
                                        }
                                        // openheadModal
                                      }
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <BsCardHeading />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Show_Page_Headings")}
                                    </span>
                                  </div>
                                  {isheadOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center z-[199]">
                                      {/* Background overlay */}
                                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                      {/* Modal dialog */}
                                      <div className="relative bg-white w-[50%]  rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {t("Page_headings")}
                                          </h2>
                                          <button
                                            onClick={closeheadModal}
                                            className=" hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div className="flex w-full p-4 h-[300px]">
                                          <ul
                                            dir="rtl"
                                            className="overflow-auto h-[280px] m-auto"
                                          >
                                            {bookmarks.map(
                                              (bookmark, index) => (
                                                <li
                                                  key={index}
                                                  className=" hover:bg-gray-300"
                                                >
                                                  <button
                                                    onClick={() => {
                                                      jumpToPage(
                                                        bookmark.pageNum - 1
                                                        // bookmark.pageIndex
                                                      );
                                                      setIsheadOpen(false);
                                                    }}
                                                  >
                                                    {htmlToPlainText(
                                                      bookmark.index
                                                    )}
                                                  </button>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                        {/* content */}
                                      </div>
                                    </div>
                                  )}
                                  {/* End button */}
                                </div>
                              </div>
                            </div>
                            {/* Flex container */}
                            <div className="relative w-full">
                              <input
                                dir="rtl"
                                className="flex-grow penn w-full rounded-full focus-visible:outline-none py-1 px-2 shadow-sm sm:text-sm"
                                placeholder={t("search_in_book")}
                                type="text"
                                value={currentKeyword.keyword}
                                onChange={(e) => {
                                  setCurrentKeyword({
                                    keyword: e.target.value,
                                    matchCase: currentKeyword.matchCase,
                                    wholeWords: currentKeyword.wholeWords,
                                  });
                                }}
                              />
                            </div>
                            <div className="px-[12px] py-1 rounded-full   text-white bg-[#6D3815]">
                              <button
                                onClick={() => {
                                  // console.log({
                                  //   word: currentKeyword.keyword,
                                  //   url: tab.pdf_location,
                                  // });
                                  if (currentKeyword.keyword !== "") {
                                    toggleDropdown(
                                      currentKeyword.keyword,
                                      tab.id
                                    );
                                  } else {
                                    // console.log("empty search");
                                    setIssOpen(false);
                                    alert(
                                      "Type any word in Hebrew to Search on this Book"
                                    );
                                  }
                                }}
                                className="text-[12px]"
                              >
                                {t("search")}
                              </button>
                            </div>
                            {/* <div className="group relative flex justify-center">
                              <button onClick={previousTextSearch}>
                                <PreviousIcon />
                              </button>
                              <span className="absolute top-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                {t("Previous")}
                              </span>
                            </div>
                            <div className="group relative flex justify-center">
                              <button onClick={nextTextSearch}>
                                <NextIcon />
                              </button>
                              <span className="absolute top-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                {t("Next")}
                              </span>
                            </div> */}
                          </div>

                          {renderPDFViewer(tab.pdf_location, tab)}

                          {startPoint && endPoint && (
                            <div
                              style={{
                                position: "absolute",
                                left: Math.min(startPoint.x, endPoint.x),
                                top: Math.min(startPoint.y, endPoint.y),
                                width: Math.abs(endPoint.x - startPoint.x),
                                height: Math.abs(endPoint.y - startPoint.y),
                                border: "2px dashed black",
                                // backgroundColor: 'rgba(0, 0, 0, 0.3)',
                              }}
                            />
                          )}
                        </div>
                        {showDialogMap[tab.id] && (
                          <div className="dialog-overlay">
                            <div className="dialog-box">
                              <div className="relative w-auto overflow-auto h-auto bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                                {/* Content */}
                                <div dir="rtl" className="modal-books">
                                  <div class="rounded-lg bg-white p-4 text-center shadow-xl">
                                    <TbLockAccess
                                      style={{
                                        height: "80px",
                                        width: "80px",
                                        color: "#dc2626 ",
                                      }}
                                      className="m-auto"
                                    />
                                    <h1>{t("limit_reach")}</h1>
                                    <p class="text-gray-600 text-sm">
                                      {t("Oops")} {t("limited-reached-massage")}
                                    </p>
                                    <p class="text-gray-600 text-sm">
                                      {t("susbceibe_to_conti")}
                                    </p>
                                    <Link to="/Subscriptions">
                                      <button
                                        onClick={() => closeDialog(tab.id)}
                                        type="button"
                                        className="text-white m-4 bg-gradient-to-r from-red-800 to-red-900 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                      >
                                        {t("subscribe")}
                                      </button>
                                    </Link>
                                    <button
                                      onClick={() => closeDialog(tab.id)}
                                      type="button"
                                      class="text-white m-4 bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                    >
                                      {t("Close")}
                                    </button>
                                  </div>
                                </div>
                                {/* Footer */}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}

              <div className="w-[20%] max-h-[680px] min-w-[100px] overflow-auto">
                <Accordion
                  bookmarks={bookmarks}
                  jumpToPage={jumpToPage}
                  numPages={numPages}
                  multiSearchResult={multiSearchResult}
                  addTab={addTab}
                  // customsearch={customsearch}
                  activeTab={activeTab}
                  viewerRefs={viewerRefs}
                  tabs={tabs}
                  activesearch={issOpen}
                  pageoccurances={options}
                  loading={loading}

                  // search={search}
                  // setTargetPages={setTargetPages}
                />
              </div>
            </div>
          </TabPanel>
        ))}
      </Tabs>

      {/* Start Tab Modal */}
      {istabOpen && (
        <div className="fixed  inset-0 flex items-center justify-center z-[199]">
          {/* Background overlay */}
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

          {/* Modal dialog */}
          <div className="relative w-[80%] overflow-auto h-[80%] bg-white w-1/2 rounded-lg shadow-lg  pt-0">
            {/* Header */}
            <div className="flex bg-[#E9E9E9] justify-between p-4 pt-1 pb-2 items-center ">
              <h2 className="text-lg font-semibold">{t("Open_Book")}</h2>
              <button
                onClick={closeTabModal}
                className="text-gray-700 hover:text-gray-900"
              >
                <svg
                  className="h-6 w-6 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {/* Content */}
            <div dir="rtl" className="modal-books p-8">
              <h1 className="text-[21px]"> {t("Open_book_in_a_new_tab")}</h1>
              <p className="text-[17px]">{t("search_for_book_list")}</p>
              <div className="tab_recent_book">
                <h3 className="text-red-600 mt-2"> {t("Recent_books")}</h3>
                <div class="flex flex-col  w-[800px] bg-white m-auto p-auto">
                  <div class="flex w-[800px] overflow-x-auto pb-10 ">
                    <div class="flex flex-nowrap ">
                      {/* <div> */}
                      {recenttab.length === 0 ? (
                        <p>{t("No_recent_books_found")}</p>
                      ) : (
                        recenttab.map((book, index) => (
                          <div
                            key={index}
                            className="p-4 ml-2 inline-block bg-white rounded shadow w-[200px] h-full"
                            onClick={() => {
                              addTab(book);
                              setItabOpen(false);
                            }}
                          >
                            <div className="relative aspect-square overflow-hidden rounded-xl">
                              <img
                                src={
                                  book.images[0] || "https://placehold.co/200"
                                }
                                className="h-[200px] w-[200px] object-cover transition hover:scale-110"
                                alt={
                                  htmlToPlainText(book.title) || "Placeholder"
                                }
                              />
                            </div>
                            <div className="flex items-center gap-1 font-semibold">
                              {htmlToPlainText(book.title) || "Book Name"}
                            </div>
                            <div className="font-light text-neutral-500">
                              {htmlToPlainText(book.auther) || "Author Name"}
                            </div>
                          </div>
                        ))
                      )}
                      {/* </div> */}
                      {/* book data */}
                      {/* <div className="p-4 ml-2 inline-block bg-white rounded shadow w-[200px] h-full">
      <div className="relative aspect-square overflow-hidden rounded-xl">
        <img src="https://placehold.co/200" className="h-[200px] w-[200px] object-cover transition hover:scale-110" alt="Placeholder" />
        
      </div>
      <div className="flex items-center gap-1 font-semibold">Book Name</div>
      <div className="font-light text-neutral-500">Author Name</div>
    </div> */}
                      {/* book data */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
          </div>
        </div>
      )}
      {/* End Tab Modal */}
    </div>
  );
};

export default Section2;
const Accordion = ({
  bookmarks,
  jumpToPage,
  numPages,
  multiSearchResult,
  addTab,
  activeTab,
  viewerRefs,
  tabs,
  activesearch,
  pageoccurances,
  loading,
}) => {
  const { addPageToDb, getUserData } = useContext(AuthContext);
  const [activeAccordion1, setActiveAccordion1] = useState(true);
  const [activeAccordion2, setActiveAccordion2] = useState(false);
  const [activeAccordion3, setActiveAccordion3] = useState(false);
  const [activeAccordion4, setActiveAccordion4] = useState(true);
  const [activeAccordion5, setActiveAccordion5] = useState(true);
  const [activeAccordion6, setActiveAccordion6] = useState(true);
  const [isFolder, setisFolder] = useState(false);
  const toggleAccordion = (accordionId) => {
    switch (accordionId) {
      case "accordion-open-body-1":
        setActiveAccordion1(!activeAccordion1);
        break;
      case "accordion-open-body-2":
        setActiveAccordion2(!activeAccordion2);
        break;
      case "accordion-open-body-3":
        setActiveAccordion3(!activeAccordion3);
        break;
      case "accordion-open-body-4":
        setActiveAccordion4(!activeAccordion4);
        break;
      case "accordion-open-body-5":
        setActiveAccordion5(!activeAccordion5);
        break;
      case "accordion-open-body-6":
        setActiveAccordion6(!activeAccordion6);
        break;
      default:
        break;
    }
  };
  const [folders, setFolders] = useState([]);
  const [pageNumbersMap, setPageNumbersMap] = useState([]);
  const addCurrentPageNumber = () => {
    const activeBook = tabs.find((tab) => tab.id === activeTab);
    const viewerRef = viewerRefs.current[activeTab];

    // Ensure the active book and viewer reference are available
    if (!activeBook || !viewerRef) {
      console.warn("Active book or viewer reference is not available.");
      return;
    }

    // Get the current page number from the viewerRef
    const currentPageNumber = viewerRef.currentPageNumber;
    if (
      currentPageNumber === undefined ||
      currentPageNumber === null ||
      currentPageNumber === 0
    ) {
      // console.warn("Current page number is not valid.");
      return;
    }
    if (activeBook.book_id != null) {
      // Ensure selectedTab.id is a string
      const idStr = String(activeBook.id);

      // Check if selectedTab.id starts with "vol-" before adding it
      const idWithPrefix = idStr.startsWith("vol-")
        ? `${activeBook.book_id}-${idStr}`
        : `${activeBook.book_id}-vol-${idStr}`;

      const bookDetails = {
        id: idWithPrefix,
        title: activeBook.title,
        pageNumber: currentPageNumber,
      };

      // Update the state to save the book details
      setPageNumbersMap((prevSavedBooks) => [...prevSavedBooks, bookDetails]);
    } else {
      const bookDetails = {
        id: activeBook.id,
        title: activeBook.title,
        pageNumber: currentPageNumber,
      };

      // Update the state to save the book details
      setPageNumbersMap((prevSavedBooks) => [...prevSavedBooks, bookDetails]);
    }
    handleClosePopup();
    // Create a new book detail object

    // console.log("Book details saved:", bookDetails);
  };
  const addpage = async () => {
    if (pageNumbersMap && pageNumbersMap.length > 0) {
      const userData = getUserData();
      let pageList = [];

      // Iterate over pageNumbersMap and prepare page data
      pageNumbersMap.forEach((content) => {
        // console.log({ book_id: content.id, page_no: content.pageNumber });
        pageList.push({
          user_id: userData.id,
          book_id: content.id,
          page_no: content.pageNumber,
        });
      });

      // console.log(pageList);

      try {
        // Call the function to add pages to the database
        await addPageToDb(pageList);

        // If successful, reset the pageNumbersMap
        setPageNumbersMap([]); // Assuming setPageNumbersMap is a state setter function
        // console.log('Pages successfully added to the database');
      } catch (error) {
        // console.error('Error adding pages to the database:', error);
        alert("Failed to add pages to the cart");
      }
    }
    if (folders && folders.length > 0) {
      const userData = getUserData();
      let pageList = [];

      // Iterate over pageNumbersMap and prepare page data
      for (let i = 0; i < folders.length; i++) {
        folders[i].pages.forEach((content) => {
          // console.log({ book_id: content.id, page_no: content.pageNumber });
          pageList.push({
            folder: folders[i].name,
            user_id: userData.id,
            book_id: content.id,
            page_no: content.pageNumber,
          });
        });
      }

      // console.log(pageList);

      try {
        // Call the function to add pages to the database
        await addPageToDb(pageList);

        // If successful, reset the pageNumbersMap
        setFolders([]); // Assuming setPageNumbersMap is a state setter function
        // console.log('Pages successfully added to the database');
      } catch (error) {
        // console.error('Error adding pages to the database:', error);
        alert("Failed to add pages to the cart");
      }
    }
    if (
      folders &&
      folders.length == 0 &&
      pageNumbersMap &&
      pageNumbersMap.length == 0
    ) {
      // Show alert when pageNumbersMap is empty
      alert("No pages to add to the cart");
    }
  };
  const removePage = (bookId, pageNo) => {
    setPageNumbersMap((prevPages) =>
      prevPages.filter(
        (page) => !(page.id === bookId && page.pageNumber === pageNo)
      )
    );
  };

  var [pages, setPages] = useState([]);
  useEffect(() => {
    if (numPages > 0) {
      const pageNumbers = Array.from(Array(numPages).keys()).map((i) => i + 1);
      setPages(pageNumbers); // Set the pages state with the array of page numbers
    } else {
      setPages([]);
    }
  }, [numPages]);
  useEffect(() => {
    // console.log(pageoccurances);
    if (pageoccurances.length > 0) {
      setpageoptions(pageoccurances);
      // Set the pages state with the array of page numbers
    } else {
      setpageoptions([]);
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const [view, setView] = useState("grid");
  const closeModal = () => {
    setIsOpen(false);
  };
  const fetchPageResults = async (text, file, pageIndex) => {
    // console.log({
    //   filename: file,
    //   textToFind: text,
    //   pageIndex: pageIndex,
    // });

    try {
      const reversedKeyword = text.split("").reverse().join("");
      // console.log(reversedKeyword);
      const response = await axios.post(
        `https://oizvehadar.com/pdfsync1/pdfviewer/FindTextPage`,
       //`https://oizvehadar.com/testpdfs/pdfviewer/FindTextPage`,
        {
          filename: file,
          textToFind: text,
          pageIndex: pageIndex,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set Content-Type to application/json
          },
        }
      );
      // console.log({ msg: "Received data", data: response.data });
      if (response.status == 200) {
        // console.log({ msg: "Received data", data: response.data });
        const resultData = response.data.result[0];
        const viewerInstance = viewerRefs.current[activeTab];
        if (!viewerInstance) return;
        // if (!viewerInstance) return;
        viewerInstance.deleteAnnotations();
        // Define the bounds for the highlight annotation
        jumpToPage(resultData.PageIndex - 1);
        // console.log(resultData.Bounds);
        // Add the highlight annotation
        resultData.Bounds.forEach((item) => {
          viewerInstance.annotationSettings.isLock = true;

          // Assuming item.bounds contains the bounding box
          viewerInstance.annotation.addAnnotation("Highlight", {
            bounds: [
              {
                x: Math.abs(item.X) - Math.abs(item.Width),
                y: Math.abs(item.Y) + Math.abs(item.Height),
                width: Math.abs(item.Width),
                height: Math.abs(item.Height),
              },
            ],
            pageNumber: resultData.PageIndex, // The page number where the annotation should be added
            color: "yellow", // Yellow color for highlight
          });
        });
      } else {
        alert("No Match Found");
      } // Stop loading after successful data fetch
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  const { t } = useTranslation();
  const [pageoptions, setpageoptions] = useState([]);
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("language") || "en"
  );
  // const [symbol, setSymbol] = useState("en");
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setSaveLang(svLang);
  });
  const [isPopen, setIsPOpen] = useState(false);
  const [fromPage, setFromPage] = useState("");
  const [toPage, setToPage] = useState("");

  const handleOpenPopup = () => setIsPOpen(true);
  const handleClosePopup = () => setIsPOpen(false);

  // Set From & To to current page
  const handleChooseCurrentPage = () => {
    // setFromPage(currentPage);
    // setToPage(currentPage);
  };

  const generatePageList = (from, to) => {
    // Convert inputs to integers
    from = parseInt(from, 10);
    to = parseInt(to, 10);

    // Ensure valid numbers
    if (isNaN(from) || isNaN(to)) return [];
    if (from > to) [from, to] = [to, from]; // Swap if needed

    return Array.from({ length: to - from + 1 }, (_, i) => from + i);
  };

  const sentselected = () => {
    const activeBook = tabs.find((tab) => tab.id === activeTab);
    const viewerRef = viewerRefs.current[activeTab];

    // Ensure the active book and viewer reference are available
    if (!activeBook || !viewerRef) {
      console.warn("Active book or viewer reference is not available.");
      return;
    }

    // Get the current page number from the viewerRef
    const currentPageNumber = viewerRef.currentPageNumber;
    if (
      currentPageNumber === undefined ||
      currentPageNumber === null ||
      currentPageNumber === 0
    ) {
      // console.warn("Current page number is not valid.");
      return;
    }
    if (activeBook.book_id != null) {
      // Ensure selectedTab.id is a string
      const idStr = String(activeBook.id);

      // Check if selectedTab.id starts with "vol-" before adding it
      const idWithPrefix = idStr.startsWith("vol-")
        ? `${activeBook.book_id}-${idStr}`
        : `${activeBook.book_id}-vol-${idStr}`;
      // console.log(fromPage, toPage);
      const pageno = generatePageList(fromPage, toPage);
      // console.log(pageno);

      const bookDetailsList = pageno.map((page) => ({
        id: idWithPrefix,
        title: activeBook.title,
        pageNumber: page, // Use each page number from the list
      }));
      setIsPOpen(false);
      // Update state to save all pages at once
      setPageNumbersMap((prevSavedBooks) => [
        ...prevSavedBooks,
        ...bookDetailsList,
      ]);
    } else {
      // const bookDetails = {
      //   id: activeBook.id,
      //   title: activeBook.title,
      //   pageNumber: currentPageNumber,
      // };

      // console.log(fromPage, toPage);
      const pageno = generatePageList(fromPage, toPage);
      // console.log(pageno);

      const bookDetailsList = pageno.map((page) => ({
        id: activeBook.id,
        title: activeBook.title,
        pageNumber: page, // Use each page number from the list
      }));
      setIsPOpen(false);
      // Update state to save all pages at once
      setPageNumbersMap((prevSavedBooks) => [
        ...prevSavedBooks,
        ...bookDetailsList,
      ]);
    }
    // Create a new book detail object

    // console.log("Book details saved:", bookDetails);
  };
  return (
    <div id="accordion-open" data-accordion="open" dir="rtl">
      {/* Accordian 4*/}

      {multiSearchResult && Object.keys(multiSearchResult).length > 0 && (
        <div className="block">
          <button
            type="button"
            className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
              activeAccordion4 ? "bg-[#f7f7f7]" : ""
            }`}
            data-accordion-target="#accordion-open-body-4"
            aria-expanded={activeAccordion4}
            onClick={() => toggleAccordion("accordion-open-body-4")}
          >
            <span className="flex items-center justify-between w-full">
              <span className="flex items-center">{t("Search_result")}</span>
              <IoMdArrowDropup
                className={`w-5 h-5 me-2 shrink-0 transform ${
                  activeAccordion4 ? "rotate-0" : "rotate-180"
                }`}
              />
            </span>
          </button>
          <div
            id="accordion-open-body-1"
            className={`p-2 bg-white h-[200px] ${
              activeAccordion4 ? "block" : "hidden"
            }`}
            aria-labelledby="accordion-open-heading-4"
          >
            <div className="text-gray-500">
              <div
                className="max-w-xs tab-scrl mx-auto max-h-[180px] overflow-y-auto"
                dir="ltr"
              >
                <ul className="grid grid-cols-3 p-2 gap-4">
                  {multiSearchResult.pages.map((val, index) => (
                    <li key={index} className="">
                      <button
                        onClick={() => {
                          // console.log(multiSearchResult);
                          const alreadyOpen = tabs.find(
                            (tab) => multiSearchResult.id === tab.id
                          );

                          let timeout = alreadyOpen ? 0 : 1;

                          setTimeout(() => {
                            const viewerInstance =
                              viewerRefs.current[activeTab];
                            if (viewerInstance) {
                              // Create a promise to wait for the document to load
                              const waitForDocumentLoad = new Promise(
                                (resolve, reject) => {
                                  viewerInstance.documentLoad = () => {
                                    resolve(); // Resolve when document is loaded
                                  };
                                }
                              );

                              // Wait for the document to be loaded, then go to the specific page
                              waitForDocumentLoad
                                .then(() => {
                                  // console.log(
                                  //   "Document loaded, navigating to page:",
                                  //   val.page
                                  // );
                                  if (val.page) {
                                    fetchPageResults(
                                      multiSearchResult.text,
                                      multiSearchResult.pdf_location.replace(
                                        "uploads/searchable/",
                                        ""
                                      ),
                                      val.page
                                    );
                                  }
                                })
                                .catch((error) => {
                                  console.error(
                                    "Error while waiting for document to load:",
                                    error
                                  );
                                });
                              fetchPageResults(
                                multiSearchResult.text,
                                multiSearchResult.pdf_location.replace(
                                  "uploads/searchable/",
                                  ""
                                ),
                                val.page
                              );
                            }
                          }, timeout);
                        }}
                      >
                        <li className="">
                          {" "}
                          <span className="bg-gray-200 hover:bg-[#6D3815] text-[12px] text-black hover:text-white rounded-full shadow-md w-8 h-8 flex items-center justify-center">
                            {savedLang !== "he"
                              ? val.page
                              : convertToHebrewNumerals(val.page)}{" "}
                          </span>
                        </li>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Accordion 6 */}
      {activesearch && (
        <div className="block">
          <button
            type="button"
            className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede] focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
              activeAccordion6 ? "bg-[#f7f7f7]" : ""
            }`}
            data-accordion-target="#accordion-open-body-6"
            aria-expanded={activeAccordion6}
            onClick={() => toggleAccordion("accordion-open-body-6")}
          >
            <span className="flex items-center justify-between w-full">
              <span className="flex items-center">{t("Search_result")}</span>
              <IoMdArrowDropup
                className={`w-5 h-5 me-2 shrink-0 transform ${
                  activeAccordion6 ? "rotate-0" : "rotate-180"
                }`}
              />
            </span>
          </button>
          <div
            id="accordion-open-body-6"
            className={`p-2 bg-white h-[200px] ${
              activeAccordion6 ? "block" : "hidden"
            }`}
            aria-labelledby="accordion-open-heading-6"
          >
            <div className="text-gray-500">
              <div className="relative">
                {loading ? (
                  // Show loading indicator when data is being fetched
                  <svg
                    className="animate-spin h-10 w-10 mr-2 text-center m-auto text-[#6d3815]"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v2a6 6 0 100 12v2a8 8 0 01-8-8z"
                    />
                  </svg>
                ) : (
                  <div className="w-[95%] max-h-[180px] overflow-auto m-auto">
                    <ul>
                      {pageoptions.length > 0 ? (
                        pageoptions.map((option, index) => (
                          <li
                            key={index}
                            className="flex justify-between items-center p-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              // console.log(
                              //   `Page ${option.page}: ${option.occurrences} occurrences`
                              // );
                              const viewerInstance =
                                viewerRefs.current[activeTab];

                              fetchPageResults(
                                option.text,
                                option.filename,
                                option.page
                              );
                            }}
                          >
                            <span>
                              {t("page")} :
                              {savedLang !== "he"
                                ? option.page
                                : convertToHebrewNumerals(option.page)}
                            </span>
                            <span className="bg-[#6d3815] text-white rounded-full w-6 h-6 flex items-center justify-center">
                              {savedLang !== "he"
                                ? option.occurrences
                                : convertToHebrewNumerals(option.occurrences)}
                            </span>
                          </li>
                        ))
                      ) : (
                        <>
                          <li>{t("Noresultsfound")}</li>
                        </>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Accordion 1 */}
      <div>
        <button
          type="button"
          className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
            activeAccordion1 ? "bg-[#f7f7f7]" : ""
          }`}
          data-accordion-target="#accordion-open-body-1"
          aria-expanded={activeAccordion1}
          onClick={() => toggleAccordion("accordion-open-body-1")}
        >
          <span className="flex items-center justify-between w-full">
            <span className="flex items-center">{t("Pages")}</span>
            <IoMdArrowDropup
              className={`w-5 h-5 me-2 shrink-0 transform ${
                activeAccordion1 ? "rotate-0" : "rotate-180"
              }`}
            />
          </span>
        </button>
        <div
          id="accordion-open-body-1"
          className={`p-2 bg-white h-[200px] ${
            activeAccordion1 ? "block" : "hidden"
          }`}
          aria-labelledby="accordion-open-heading-1"
        >
          <div className="text-gray-500">
            <div className="relative">
              <input
                type="search"
                id="Searchbooks"
                placeholder={t("Search_in_pages")}
                dir="rtl"
                className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
              />

              <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                <button
                  type="button"
                  className="text-black  hover:text-gray-700"
                >
                  <span className="sr-only">Search</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-3 w-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </span>
            </div>
            <div className="max-w-xs mx-auto overflow-y-auto mt-2" dir="ltr">
              <ul
                className="flex flex-col h-[160px] pt-2 pr-2 space-y-1"
                dir="rtl"
              >
                {pages.map((page) => (
                  <li key={page} className=" hover:bg-gray-300">
                    <a
                      href="#"
                      className="block px-3  text-black text-[16px] w-full"
                      onClick={() => jumpToPage(page - 1)}
                    >
                      {savedLang !== "he"
                        ? page
                        : convertToHebrewNumerals(page)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Accordion 5 */}
      <div>
        <div className="flex border-b-0 border-[#dedede] border  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-1">
          <button
            className="mb-2 p-2 hover:bg-gray-300  rounded-md"
            onClick={handleOpenPopup}
            // onClick={addCurrentPageNumber}
          >
            <MdPrint />
          </button>
          {isPopen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-[1000] p-4"
              dir="ltr"
              onKeyDown={(e) => e.key === "Escape" && handleClosePopup()} // Close on Esc
              tabIndex={-1} // Allow focus for keyboard navigation
            >
              <div className="bg-white p-6 rounded-lg shadow-xl w-96">
                {/* Modal Title */}
                {/* <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
        {t("select_page_range")}
      </h2> */}

                {/* Input Fields */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("from")}:
                    </label>
                    <input
                      type="number"
                      value={fromPage}
                      onChange={(e) => setFromPage(e.target.value)}
                      className="w-full p-2 mt-1 border rounded-lg focus:ring-2 focus:ring-red-500 focus:outline-none"
                      min="1"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {t("to")}:
                    </label>
                    <input
                      type="number"
                      value={toPage}
                      onChange={(e) => setToPage(e.target.value)}
                      className="w-full p-2 mt-1 border rounded-lg focus:ring-2 focus:ring-red-500 focus:outline-none"
                      min="1"
                    />
                  </div>
                </div>

                {/* Buttons */}
                <div className="flex justify-between mt-6">
                  <button
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition"
                    onClick={handleClosePopup}
                  >
                    {t("close")}
                  </button>
                  <button
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition"
                    onClick={addCurrentPageNumber}
                  >
                    {t("select_current")}
                  </button>
                  <button
                    className="px-4 py-2 bg-red-800 text-white rounded-lg hover:bg-red-900 transition"
                    onClick={sentselected}
                  >
                    {t("add")}
                  </button>
                </div>
              </div>
            </div>
          )}

          <button
            type="button"
            className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
              activeAccordion5 ? "bg-[#f7f7f7]" : ""
            }`}
            data-accordion-target="#accordion-open-body-5"
            aria-expanded={activeAccordion5}
            // onClick={() => toggleAccordion("accordion-open-body-5")}
          >
            <span className="flex items-center justify-between w-full">
              <span className="flex items-center">{t("printables")}</span>
              <button
                onClick={() => setisFolder(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg"
              >
                <FaFolder />
              </button>
            </span>
          </button>
        </div>
        <div
          id="accordion-open-body-5"
          className={`p-2 bg-white h-[200px] ${
            activeAccordion5 ? "block" : "hidden"
          }`}
          aria-labelledby="accordion-open-heading-5"
        >
          <div className="text-gray-500">
            <div className="max-w-full mx-auto overflow-y-auto mt-2">
              <DndProvider backend={HTML5Backend}>
                {isFolder && (
                  <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-[1000] p-4"
                    dir="ltr"
                    onKeyDown={(e) => e.key === "Escape" && handleClosePopup()} // Close on Esc
                    tabIndex={-1} // Allow focus for keyboard navigation
                  >
                    {/* className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
                    dir="rtl"
                  > */}
                    <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-3xl relative max-h-[80vh] overflow-y-auto">
                      <div className="flex justify-between items-center">
                        <button
                          onClick={() => setisFolder(false)}
                          className="text-red-500 text-xl"
                        >
                          <FaTimes />
                        </button>
                      </div>

                      {/* File Manager Component with Nested Popups Support */}
                      <FileManagers
                        pageNumbersMap={pageNumbersMap}
                        savedLang={savedLang}
                        folders={folders}
                        setFolders={setFolders}
                        deletepage={removePage}
                        views={view}
                        setviews={setView}
                        addpage={addpage}
                        createpage={setPageNumbersMap}
                      />
                    </div>
                  </div>
                )}
              </DndProvider>

              <ul
                className="flex flex-col h-[140px] pt-2 pr-2 space-y-1"
                dir={savedLang !== "he" ? "ltr" : "rtl"}
              >
                <div className="divide-y">
                  {folders.map((folder, index) => (
                    <li className="flex items-center gap-4 p-2 border-b">
                      <FaFolder
                        size={20}
                        className="text-yellow-600 flex-shrink-0"
                      />
                      <span className="text-sm font-bold flex-1">
                        {folder.name}
                      </span>
                    </li>
                  ))}

                  {pageNumbersMap.length > 0 ? (
                    pageNumbersMap.map((book) => (
                      <li className="flex items-center gap-4 p-2 border-b">
                        <FaFileAlt
                          size={20}
                          className="text-gray-600 flex-shrink-0"
                        />
                        <div className="flex flex-col justify-between h-full">
                          <span className="text-xs flex-1">
                            {htmlToPlainText(book.title)} &nbsp; : &nbsp;{" "}
                            {t("page")} &nbsp;
                            {savedLang !== "he"
                              ? book.pageNumber
                              : convertToHebrewNumerals(book.pageNumber)}
                          </span>
                          <TbTrash
                            size={15}
                            className="text-red-600 self-end"
                            onClick={() => {
                              removePage(book.id, book.pageNumber);
                            }}
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <p className="text-center text-red-800 mt-5">
                      {t("print_page")}
                    </p>
                  )}
                </div>
                {/* {pageNumbersMap.length > 0 ? (
                  pageNumbersMap.map((book, index) => (
                    <li key={index}>
                      {htmlToPlainText(book.title)} : {t("page")}:{" "}
                      {savedLang !== "he"
                        ? book.pageNumber
                        : convertToHebrewNumerals(book.pageNumber)}
                    </li>
                  ))
                ) : (
                  <p className="text-center text-red-800 mt-5">
                    {t("print_page")}
                  </p>
                )} */}
              </ul>
            </div>
            <button
              class="bg-[#6D3815] hover:bg-black w-full text-white py-1 px-4 border-b-4 border-black hover:border-black rounded"
              onClick={addpage}
            >
              {t("add_to_cart")}
            </button>
          </div>
        </div>
      </div>
      {/* Accordion 2 */}
      <div>
        <button
          type="button"
          className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
            activeAccordion2 ? "bg-[#f7f7f7]" : ""
          }`}
          data-accordion-target="#accordion-open-body-2"
          aria-expanded={activeAccordion2}
          onClick={() => toggleAccordion("accordion-open-body-2")}
        >
          <span className="flex items-center justify-between w-full">
            <span className="flex items-center">{t("Indexes")}</span>
            <IoMdArrowDropup
              className={`w-5 h-5 me-2 shrink-0 transform ${
                activeAccordion2 ? "rotate-0" : "rotate-180"
              }`}
            />
          </span>
        </button>

        <div
          id="accordion-open-body-2"
          className={`p-2 bg-white h-[200px] ${
            activeAccordion2 ? "block" : "hidden"
          }`}
          aria-labelledby="accordion-open-heading-2"
        >
          <div className="text-gray-500">
            <div className="relative">
              <input
                type="search"
                id="Searchbooks"
                placeholder={t("Search_in_indexes")}
                dir="rtl"
                className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
              />

              <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                <button
                  type="button"
                  className="text-black  hover:text-gray-700"
                >
                  <span className="sr-only">Search</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-3 w-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </span>
            </div>
            <div className="max-w-xs mx-auto overflow-y-auto mt-2" dir="ltr">
              <ul
                className="flex flex-col h-[160px] pt-2 pr-2 space-y-1"
                dir="rtl"
              >
                {bookmarks.map((bookmark, index) => (
                  <li key={index} className=" text-[10px] hover:bg-gray-300">
                    <button onClick={() => jumpToPage(bookmark.pageNum)}>
                      {bookmark.index}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Accordion 3 */}
      <div>
        <div className="flex border-b-0 border-[#dedede] border  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-1">
          <button onClick={openModal} className=" text-[#3b200f]  mr-2 rounded">
            <FaCirclePlus className="" />
          </button>
          <button
            type="button"
            className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
              activeAccordion3 ? "bg-[#f7f7f7]" : ""
            }`}
            data-accordion-target="#accordion-open-body-3"
            aria-expanded={activeAccordion3}
            onClick={() => toggleAccordion("accordion-open-body-3")}
          >
            <span className="flex items-center justify-between w-full">
              <span className="flex items-center">{t("My_Indexes")}</span>
              <IoMdArrowDropup
                className={`w-5 h-5 me-2 shrink-0 transform ${
                  activeAccordion3 ? "rotate-0" : "rotate-180"
                }`}
              />
            </span>
          </button>
        </div>
        <div
          id="accordion-open-body-3"
          className={`p-2 bg-white h-[200px] ${
            activeAccordion3 ? "block" : "hidden"
          }`}
          aria-labelledby="accordion-open-heading-3"
        >
          <div className="text-gray-500">
            <div className="max-w-xs mx-auto overflow-y-auto mt-2" dir="ltr">
              <ul className="flex flex-col h-[160px]  pr-2 space-y-1" dir="rtl">
                <li className=" hover:bg-gray-300">
                  <a>{t("My_Indexes")}</a>
                </li>
              </ul>
            </div>
            {/* Modal */}
            {isOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-[199]">
                {/* Background overlay */}
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                {/* Modal dialog */}
                <div className="relative bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                  {/* Header */}
                  <div className="flex bg-[#E9E9E9] justify-between p-4 pt-1 pb-2 items-center ">
                    <h2 className="text-lg font-semibold">
                      {t("Add_a_new_personal_index_in_the_page")}
                    </h2>
                    <button
                      onClick={closeModal}
                      className="text-gray-700 hover:text-gray-900"
                    >
                      <svg
                        className="h-6 w-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Content */}
                  <div className=" p-10 w-[50%] m-auto">
                    <input
                      type="text"
                      name="add"
                      placeholder={t("Index_Name")}
                      className="border-black w-full border-b-2 m-auto"
                    />
                  </div>

                  {/* Footer */}
                  <div className="mt-4 bg-[#E9E9E9] p-2 flex justify-center">
                    <button className="px-4 py-2 rounded-full  text-white hover:bg-red-800 bg-[#6D3815] rounded-full focus:outline-none">
                      {t("create")}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FileManagers = ({
  pageNumbersMap,
  savedLang,
  folders,
  setFolders,
  deletepage,
  views,
  setviews,
  addpage,
  createpage,
}) => {
  const [pages, setPages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [isMovePopupOpen, setIsMovePopupOpen] = useState(false);
  useEffect(() => {
    // console.log(pageNumbersMap);
    setPages(pageNumbersMap);
  }, [pageNumbersMap]);
  useEffect(() => {
    // console.log(folders);
  }, [folders]);

  const createFolder = () => {
    const folderName = prompt("Enter folder name:");
    if (folderName) {
      setFolders([
        ...folders,
        { id: Date.now(), name: folderName, pages: [], date: Date.now() },
      ]);
    }
  };

  const renameFolder = (index) => {
    const newName = prompt("Enter new folder name:", folders[index].name);
    if (newName) {
      const updatedFolders = [...folders];
      updatedFolders[index].name = newName;
      setFolders(updatedFolders);
    }
  };

  const deleteFolder = (index) => {
    if (window.confirm("Are you sure you want to delete this folder?")) {
      setFolders(folders.filter((_, i) => i !== index));
    }
  };
  const handleMove = async () => {
    // console.log(selectedFolder, selectedFiles);

    if (!selectedFolder || selectedFiles.length === 0) {
      // console.warn("No folder selected or no files to move.");
      return;
    }

    setFolders((prevFolders) =>
      prevFolders.map((f) =>
        f.name === selectedFolder
          ? {
              ...f,
              pages: [
                ...(f.pages || []),
                ...selectedFiles.filter(
                  (file) =>
                    !(f.pages || []).some(
                      (p) =>
                        `${p.id}-p-${p.pageNumber}` ===
                        `${file.id}-p-${file.pageNumber}`
                    ) // Avoid duplicates
                ),
              ],
            }
          : f
      )
    );

    setPages((prevPages) => {
      const newPages = prevPages.filter(
        (page) => !selectedFiles.some((file) => file.id === page.id) // Remove moved files
      );

      // Ensure deletepage is called correctly
      selectedFiles.forEach((file) => {
        deletepage(file.id, file.pageNumber);
      });

      return newPages;
    });

    setIsMovePopupOpen(false); // Close popup after moving
    setSelectedFiles([]); // Clear selection
  };

  const toggleSelectFile = (fileName) => {
    // console.log(selectedFiles);
    // console.log(`selected: ${fileName}`);
    setSelectedFiles((prev) =>
      prev.includes(fileName)
        ? prev.filter((file) => file !== fileName)
        : [...prev, fileName]
    );
  };

  const toggleSelectAll = () => {
    if (selectedFiles.length === pages.length) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(pages);
    }
  };
  return (
    <div className="p-4" dir={savedLang !== "he" ? "ltr" : "rtl"}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">{t("explorer")}</h2>
        <div className="flex gap-4">
          {selectedFiles.length > 0 && (
            <button
              className="flex justify-center w-full p-3 border border-gray-400 rounded-md items-center gap-2 h-12"
              onClick={() => setIsMovePopupOpen(true)}
            >
              <MdDriveFileMove />
              {t("moveTo")}
            </button>
          )}

          <label className="flex justify-center w-full p-3 border border-gray-400 rounded-md items-center gap-2 h-12 cursor-pointer">
            {t("select_all")}
            <input
              className="ml-2 accent-red-700 cursor-pointer"
              type="checkbox"
              onChange={toggleSelectAll}
              checked={selectedFiles.length === pages.length}
            />
          </label>
          <button
            onClick={createFolder}
            className="flex justify-center w-full p-3 border border-gray-400 rounded-md flex items-center gap-2 h-12"
          >
            <MdCreateNewFolder />
            {t("new_folder")}
          </button>
          <button
            className="border border-gray-400 rounded-md p-3 flex justify-center items-center w-12 h-12"
            onClick={() => setviews("list")}
          >
            <FaList />
          </button>
          <button
            className="border border-gray-400 rounded-md p-3 flex justify-center items-center w-12 h-12"
            onClick={() => setviews("grid")}
          >
            <FaTh />
          </button>
        </div>
      </div>
      <div className="max-h-[400px] overflow-y-auto border rounded-lg p-2 bg-gray-100">
        {views === "list" ? (
          <div className="border rounded-lg overflow-hidden">
            {/* Table Header */}
            <div className="grid grid-cols-2 bg-gray-100 p-3 font-bold border-b">
              <div>{t("name")}</div>
              <div className="flex justify-end space-x-2">{t("action")}</div>
            </div>

            {/* List Items */}
            <div className="divide-y">
              {folders.length === 0 && pages.length === 0 ? (
                <p className="text-center text-gray-500 py-4">
                  {t("no_file_or_folder")}
                </p>
              ) : (
                <>
                  {folders.map((folder, index) => (
                    <DroppableFolder
                      key={folder.id}
                      folder={folder}
                      setFolders={setFolders}
                      index={index}
                      deletepage={deletepage}
                      renameFolder={renameFolder}
                      deleteFolder={deleteFolder}
                      page={pageNumbersMap}
                      setPages={setPages}
                      islist={views}
                      createpage={createpage}
                    />
                  ))}

                  {pages.length > 0 &&
                    pages.map((book) => (
                      <DraggableFile
                        key={book.id}
                        file={book}
                        setPages={setPages}
                        savedLang={savedLang}
                        islist={views}
                        deletepage={deletepage}
                        toggleSelectFile={toggleSelectFile}
                        selectedFiles={selectedFiles}
                      />
                    ))}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-4 gap-4">
            {folders.length === 0 && pages.length === 0 ? (
              <div className="col-span-4 text-center text-gray-500 py-4">
                {t("no_file_or_folder")}
              </div>
            ) : (
              <>
                {folders.map((folder, index) => (
                  <DroppableFolder
                    key={folder.id}
                    folder={folder}
                    setFolders={setFolders}
                    index={index}
                    islist={views}
                    deletepage={deletepage}
                    page={pageNumbersMap}
                    renameFolder={renameFolder}
                    deleteFolder={deleteFolder}
                    setPages={setPages}
                    createpage={createpage}
                  />
                ))}

                {pages.map((book) => (
                  <DraggableFile
                    key={book.id}
                    file={book}
                    setPages={setPages}
                    savedLang={savedLang}
                    islist={views}
                    deletepage={deletepage}
                    toggleSelectFile={toggleSelectFile}
                    selectedFiles={selectedFiles}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </div>

      <br></br>
      <br></br>
      {isMovePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-lg w-80">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{t("select_folder")}</h3>
              <button
                className="text-xl"
                onClick={() => setIsMovePopupOpen(false)}
              >
                ✖
              </button>
            </div>

            <select
              className="w-full border p-2 rounded-md mb-4"
              value={selectedFolder}
              onChange={(e) => setSelectedFolder(e.target.value)}
            >
              <option value="">{t("choose_folder")}</option>
              {folders.map((folder, index) => (
                <option key={index} value={folder.name}>
                  {folder.name}
                </option>
              ))}
            </select>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-md w-full"
              onClick={handleMove}
              disabled={!selectedFolder}
            >
              {t("send")}
            </button>
          </div>
        </div>
      )}
      <button
        class="bg-[#6D3815] hover:bg-black w-full text-white py-1 px-4 border-b-4 border-black hover:border-black rounded"
        onClick={addpage}
      >
        {t("add_to_cart")}
      </button>
    </div>

    // </DndProvider>
  );
};

const DraggableFile = ({
  file,
  savedLang,
  islist,
  deletepage,
  isdelete = true,
  toggleSelectFile,
  selectedFiles,
  nocheck = false,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "file",
    item: file,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const isChecked = file && selectedFiles?.includes(file);
  return islist !== "list" ? (
    <div
      ref={drag}
      className={`relative p-4 border rounded-lg bg-white flex flex-col items-center cursor-pointer ${
        isDragging ? "opacity-50" : ""
      }`}
    >
      <FaFileAlt size={40} className="text-gray-600" />
      <p className="text-xs mt-2 text-center">
        {htmlToPlainText(file.title)} : {t("page")}:{" "}
        {savedLang !== "he"
          ? file.pageNumber
          : convertToHebrewNumerals(file.pageNumber)}
      </p>

      {isdelete && (
        <button
          className="absolute bottom-2 right-2 text-xs text-red-500"
          onClick={(e) => {
            e.stopPropagation();
            // console.log(file);
            deletepage(file.id, file.pageNumber);
          }}
        >
          <FaTrash />
        </button>
      )}
      {nocheck == false && (
        <input
          className="ml-2 accent-red-700 cursor-pointer"
          type="checkbox"
          checked={isChecked}
          onChange={() => toggleSelectFile(file)}
        />
      )}
    </div>
  ) : (
    <div ref={drag} className="flex items-center border-b p-2">
      <FaFileAlt size={20} className="text-gray-600 mr-2" />
      <span className="text-sm">
        {htmlToPlainText(file.title)} : {t("page")}:{" "}
        {savedLang !== "he"
          ? file.pageNumber
          : convertToHebrewNumerals(file.pageNumber)}
      </span>
      {isdelete && (
        <div className="ml-auto flex items-center space-x-2">
          <button
            className="text-xs text-red-500"
            onClick={(e) => {
              e.stopPropagation();
              // console.log(file);
              deletepage(file.id, file.pageNumber);
            }}
          >
            <FaTrash />
          </button>
          {nocheck == false && (
            <input
              className="ml-2 accent-red-700 cursor-pointer"
              type="checkbox"
              checked={isChecked}
              onChange={() => toggleSelectFile(file)}
            />
          )}
        </div>
      )}
    </div>
  );
};

const DroppableFolder = ({
  folder,
  setFolders,
  index,
  renameFolder,
  deleteFolder,
  deletepage,
  setPages,
  createpage,
  islist,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [{ isOver }, drop] = useDrop({
    accept: "file",
    drop: (item) => {
      setFolders((prevFolders) => {
        return prevFolders.map((f, i) =>
          i === index
            ? { ...f, pages: [...(f.pages || []), item] } // Preserve existing pages
            : f
        );
      });

      setPages((prevPages) => {
        const newPages = [...prevPages];
        const index = newPages.findIndex((page) => page.id === item.id);
        if (index !== -1) {
          deletepage(newPages[index].id, newPages[index].pageNumber);
          newPages.splice(index, 1);
        }
        return newPages;
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const removeFileFromFolder = (fileId) => {
    setFolders((prevFolders) => {
      const updatedFolders = prevFolders.map((f, i) =>
        i === index
          ? { ...f, pages: f.pages.filter((p) => p.id !== fileId) }
          : f
      );
      return updatedFolders;
    });

    setPages((prevPages) => {
      const removedFile = folder.pages.find((p) => p.id === fileId);
      createpage([...prevPages, removedFile]);
      return [...prevPages, removedFile];
    });
  };

  return (
    <div className="relative">
      {islist !== "list" ? (
        <div
          ref={drop}
          className={`p-4 border rounded-lg flex flex-col items-center cursor-pointer ${
            isOver ? "opacity-50" : ""
          }`}
          onClick={() => setIsOpen(true)}
        >
          <FaFolder size={50} className="text-yellow-600" />
          <p className="text-xs mt-2 text-center">{folder.name}</p>
          <button
            className="absolute top-1 right-1 text-xs"
            onClick={(e) => {
              e.stopPropagation();
              renameFolder(index);
            }}
          >
            <FaEdit />
          </button>
          <button
            className="absolute bottom-1 right-1 text-xs"
            onClick={(e) => {
              e.stopPropagation();
              deleteFolder(index);
            }}
          >
            <FaTrash />
          </button>
        </div>
      ) : (
        <div
          ref={drop}
          className={`p-2 border-b flex items-center cursor-pointer ${
            isOver ? "opacity-50" : ""
          }`}
          onClick={() => setIsOpen(true)}
        >
          <FaFolder size={20} className="text-yellow-600 mr-2" />
          <span className="text-sm">{folder.name}</span>

          {/* Push buttons to the right */}
          <div className="ml-auto flex items-center space-x-2">
            <button
              className="text-xs"
              onClick={(e) => {
                e.stopPropagation();
                renameFolder(index);
              }}
            >
              <FaEdit />
            </button>
            <button
              className="text-xs text-red-500"
              onClick={(e) => {
                e.stopPropagation();
                deleteFolder(index);
              }}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      )}

      {isOpen &&
        ReactDOM.createPortal(
          <div className="absolute top-40 left-1/2 transform -translate-x-1/2 bg-white p-4 border shadow-lg z-50 max-w-lg w-full max-h-[80vh] overflow-y-auto rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">{folder.name}</h3>
              <button onClick={() => setIsOpen(false)} className="text-red-500">
                <FaTimes />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {folder.pages.length > 0 ? (
                folder.pages.map((file) => (
                  <div
                    key={file.id}
                    className="p-4 border rounded-lg bg-gray-100 flex flex-col items-center relative"
                  >
                    <DraggableFile
                      file={file}
                      setPages={setPages}
                      isdelete={false}
                    />
                    <button
                      className="absolute top-1 right-1 text-xs text-red-500"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFileFromFolder(file.id);
                      }}
                    >
                      <FaTrash />
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-center col-span-2">
                  No files in this folder
                </p>
              )}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};
