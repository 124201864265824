import { FiBook } from "react-icons/fi";
import React, { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../constant";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import htmlToPlainText from "../htmltotext";
import FlipCountdown from "@rumess/react-flip-countdown";
import { endAt } from "firebase/firestore";

const Bundles = () => {
  const [bundles, setBundles] = useState([]);
  const {
    cart,
    addBundleToCart,
    removeFromCart,
    addToCart,
    currentUser,
    getUserData,
  } = useContext(AuthContext);
  const [symbol, setSymbol] = useState("$");
  const [price, setPrice] = useState();
    const { i18n } = useTranslation();
    const isRtl = i18n.dir() === "rtl";

  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("language") || "en"
  );
  const [savedCurrency, setSavedCurrency] = useState(
    localStorage.getItem("currency") || "$"
  );
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setSaveLang(svLang);
    const svcCrrency = localStorage.getItem("currency");
    // setSaveLang(svcCrrency);
    
    // console.log(savedLang);
    setSymbol(svcCrrency !== "ils" ? "$" : "₪");
  //   if (bundles.length > 0) {
  //  setPrice(svcCrrency !== "ils" ?bundle.price : bundle.price_is);
  //   }
  });
  useEffect(() => {
    fetchBundles();
  }, []);
  const navigate = useNavigate();
  const fetchBundles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_book.php/bundlebooks`);
      const bookData = response.data;

      if (Array.isArray(bookData)) {
        // Filter out books with status 'deactive'
         const activeBooks = bookData.filter(book => book.status !== 'deactive');
        setBundles(activeBooks);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };
  const BundleCountdown = ({ end_at }) => {
    const [showMonth, setShowMonth] = useState(false);
    const [showDay, setShowDay] = useState(false);
  
    useEffect(() => {
      console.log(end_at);
      if (!end_at) return;
  
      const endDate = new Date(end_at);
      const now = new Date();
      console.log(formatDateTime(now));
      const timeDiff = endDate - now;
      console.log(timeDiff);
      const daysRemaining = timeDiff / (1000 * 60 * 60 * 24);
      setShowMonth(daysRemaining > 31);
      setShowDay(daysRemaining > 2);
    }, [end_at]);
    const formatDateTime = (dateString) => {
      if (!dateString) return "";
      
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
    // console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`)
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    return (
      <div>
        {/* If both month and day are available, show month countdown */}
        {(showMonth && showDay) && (
          <FlipCountdown 
            theme="light"
            size="small"
            endAt={formatDateTime(end_at)}
            hideYear
            hideHour
            hideMinute
            hideSecond
          />
        )}
    
        {/* If only day is available, show day countdown */}
        {showDay && !showMonth && (
          <FlipCountdown 
            theme="light"
            size="small"
            endAt={formatDateTime(end_at)}
            hideYear
            hideMonth
            // hideHour
            // hideMinute
            hideSecond
          />
        )}
    
        {/* If no month or day is available, only show time countdown */}
        {!showMonth && !showDay && (
          <FlipCountdown 
            theme="light"
            size="small"
            endAt={formatDateTime(end_at)}
            hideYear
            hideMonth
            hideDay
          />
        )}
      </div>
    );
  };
  const purchase = async (e, pro) => {
    e.preventDefault();
    if (currentUser) {
      const data = {
        product: {
          ...pro,
          id: `bdl-${pro.id}`,
          title: `Bundle :${pro.bundle_name}`,
        },
        quantity: 1,
        price: pro.price,
      };
      addToCart(data);
      // navigate('/pages/Stripe',{state:{
      //   product:pro,
      //   amount:pro.price,
      //   type:"bundle"
      //  }} );
    } else {
      toast.error(`Your need to login first`);
    }
    // addBundleToCart(pro);
  };
  const { t } = useTranslation();
  // const filteredBundles = bundles.filter((bundle) => {
    const filteredBundles = bundles.filter((bundle) => {
      if (bundle.end_at !== null) {
        const endDate = new Date(bundle.end_at);
        endDate.setHours(23, 59, 59, 999); // Ensure the date includes full day validity
    
        // Skip expired bundles by returning false
        if (endDate.getTime() <= Date.now()) {
          return false; // Exclude expired bundles
        }
      }
    
      // Include bundles without an end date
      return true;
    });
    
    // if (bundle.end_at !== null) {
    
    //   const endDate = new Date(bundle.end_at);
    //   endDate.setHours(23, 59, 59, 999); // Ensure the date includes full day validity
  
    //   return endDate.getTime() < Date.now(); // Only include valid bundles
    // }
  //   return true; // Include bundles without an end date
  // });
  return (
    <div className="container w-[90%] md:min-h-[800px] mx-auto ">
      {/* // <ToastContainer/> */}
      <h1 className="text-3xl font-bold text-center mt-8 mb-4">
        {" "}
        {t("book_bundle")}
      </h1>
     

  {filteredBundles.length === 0 ? (
    <div className="text-center text-xl font-semibold mt-8">{t("no_deals_found")}</div>
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {filteredBundles.map((bundle, index) => (
    <div
      key={bundle.id}
      className="flex flex-col rounded-2xl bg-white text-red-900 shadow-xl w-full"
    >
      <div
        className="rounded-t-2xl bg-[#fce5c4c4] w-full h-40"
        style={{
          boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
        }}
      >
        <img
          src="https://oizvehadar.com/api/uploads/images/logo.png"
          className="rounded-t-2xl w-full h-full object-contain"
          alt="logo"
        />
      </div>
      <div className="flex flex-col p-8 h-full">
        <div className="text-3xl font-bold pb-6">{bundle.bundle_name}</div>

        {bundle.end_at ? (
          <div className="flex flex-row justify-between items-center">
            <h2>{t("deal_expire")}</h2>
            <h2 className="mb-2 tilt-neon text-gray-600" style={{ direction: "ltr" }}>
            <BundleCountdown
            end_at={bundle.end_at} />
              {/* <FlipCountdown 
               hideYear
                //hideMonth
              theme="light" size="small" endAt={bundle.end_at} /> */}
            </h2>
          </div>
        ) : (
          <div className="flex flex-col p-8 text-3xl">
            <p>{""}</p>
            <p>{""}</p>
          </div>
        )}

        <div className="flex flex-row justify-between items-center">
          <div className={`text-lg font-semibold mb-2 ${isRtl ? "text-right" : "text-left"}`}>
            {t("no_books")}:
          </div>
          <div className={`text-m font-semibold mb-2 ${isRtl ? "text-left" : "text-right"}`}>
            {bundle.books.length}
          </div>
        </div>

        <div className="flex flex-row justify-between items-center">
          <div className={`text-lg font-semibold mb-2 ${isRtl ? "text-right" : "text-left"}`}>
            {t("price")}:
          </div>
          <div className={`text-lg font-semibold mb-2 ${isRtl ? "text-right" : "text-left"}`}>
            <p className="mb-2 ml-2 text-2l tilt-neon font-bold">
              {bundle.percentage ? (
                <div className="flex" dir={savedLang !== "en" ? "rtl" : "ltr"}>
                  <span className="line-through text-red-500 mr-2">
                    {savedLang !== "en" ? (
                      <>
                        {((symbol !== "₪" ? bundle.price : bundle.price_is) / (1 - bundle.percentage / 100)).toFixed(2)}
                        <span className="text-l">{symbol}</span>
                      </>
                    ) : (
                      <>
                        <span className="text-l">{symbol}</span>{" "}
                        {((symbol !== "₪" ? bundle.price : bundle.price_is) / (1 - bundle.percentage / 100)).toFixed(2)}
                      </>
                    )}
                  </span>
                  <span className="ml-4">
                    {savedLang !== "en" ? (
                      <>
                        {symbol !== "₪" ? bundle.price : bundle.price_is}
                        <span className="text-l">{symbol}</span>
                      </>
                    ) : (
                      <>
                        <span className="text-l">{symbol}</span> {symbol !== "₪" ? bundle.price : bundle.price_is}
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <>
                  <span className="text-l">{symbol}</span> {symbol !== "₪" ? bundle.price : bundle.price_is}
                </>
              )}
            </p>
          </div>
          <div className="mb-2 tilt-neon text-white bg-red-400 px-2 py-1 rounded inline-block">
            {bundle.percentage}% {t("OFF")}
          </div>
        </div>

        <div className="flex flex-col gap-3 text-base overflow-y-auto" style={{ height: "150px" }}>
          {bundle.books.map((book, index) => (
            <div key={index} className="flex flex-row gap-2 items-center">
              <img src={book.images[0]} alt={htmlToPlainText(book.title)} className="w-16 h-16 object-contain" />
              <div className="text-sm">{htmlToPlainText(book.title)}</div>
            </div>
          ))}
        </div>

        <div className="flex pt-10">
          <button
            className="w-full bg-red-700 text-white font-bold text-base p-3 rounded-lg hover:bg-red-800 active:scale-95 transition-transform"
            onClick={(e) => purchase(e, bundle)}
          >
            {t("buy_bundles")}
          </button>
        </div>
      </div>
    </div>

))}
</div>
)}

      
    </div>
  );
};

export default Bundles;
