import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { FaStar } from "react-icons/fa";

import { IoSettings } from "react-icons/io5";
import { FcOpenedFolder } from "react-icons/fc";
import { FaBook } from "react-icons/fa6";
import { BiSolidBookHeart } from "react-icons/bi";
import { MdOutlineRecentActors } from "react-icons/md";
import { LuBookPlus } from "react-icons/lu";
import { BiSolidHide } from "react-icons/bi";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import BASE_URL from "../constant";

import { ImSpinner2 } from "react-icons/im"; // Import spinner icon

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import htmlToPlainText from "../htmltotext";
// Import pdfjs-dist and set the workerSrc
import { version as pdfjsVersion } from "pdfjs-dist";
import * as pdfjs from "pdfjs-dist";

import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import Section2 from "./PdfSection2";

import NODE_BASE_URL from "../node_constant";
import { AuthContext } from "../context/AuthContext";

import { BiError } from "react-icons/bi";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;

const PDFViewer = () => {
  const { getUserData } = useContext(AuthContext);
  const [isAlerttabOpen, setAlertItabOpen] = useState(false);
  const { id, title } = useParams();
  // const pdfId = parseInt(id, 10);
  const { t } = useTranslation();
  const closeAlertTabModal = () => {
    setAlertItabOpen(false);
    window.history.pushState(null, "", `/pdfViewer/`);
    window.location.reload();
  };
  const openAlertTabModal = () => {
    setAlertItabOpen(true);
  };
  const [product, setProduct] = useState({});
  useEffect(() => {
    let pdfId;
    let url;
    if (id && id.includes("vol-")) {
      // pdfId = id; // Keep as string if it contains "vol-"
      url = `${BASE_URL}/api_volumes.php?id=${parseInt(
        id.replace("vol-", ""),
        10
      )}`;
    } else {
      pdfId = parseInt(id, 10);
      url = `${BASE_URL}/api_book.php/books/${pdfId}`; // Convert to integer otherwise
    }

    axios
      .get(url)
      .then((res) => {
        // console.log(
        //    { id: `vol-${res.data.id}`, ...res.data }
        // );
        
        if (res.data) {
          console.log("res.data:", res.data); // Debugging
          console.log("res.data.id:", res.data.id); // Debugging
          console.log("res.data.book_id:", res.data.book_id); // Debugging
        
          const updatedData =
          res.data.book_id ? { ...res.data, id: `vol-${res.data.id}` } : res.data;
        
        console.log("Original Data:", res.data);
        console.log("Updated Data:", updatedData);
        
          setProduct(updatedData);
        
          setProduct(updatedData);
        } else {
          console.error("res.data is null or undefined");
          setAlertItabOpen(true);
        }
        
      })
      .catch((err) => console.log(err));
  }, [id]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  return (
    <>
      {isEmptyObject(product) ? (
        <>
          <div>Loading...</div>
          {/* Alert modal box */}
          {isAlerttabOpen && (
            <div className="fixed  inset-0 flex items-center justify-center z-[199]">
              {/* Background overlay */}
              <div className="absolute inset-0 bg-gray-500 opacity-95"></div>

              {/* Modal dialog */}
              <div className="relative w-auto overflow-auto bg-white h-auto bg-white w-1/2  rounded-lg shadow-lg  pt-0">
                {/* Header */}

                {/* Content */}
                <div dir="rtl" className="modal-books bg-white">
                  <div class="flex flex-grow items-center  justify-center">
                    <div class="rounded-lg  p-8 text-center shadow-xl">
                      <BiError
                        style={{
                          height: "150px",
                          width: "150px",
                          color: "red",
                        }}
                        className="m-auto"
                      />
                      <p class="text-gray-600">{t("oops_page_not_foun")}</p>
                      <button
                        onClick={closeAlertTabModal}
                        type="button"
                        class="text-white m-4 bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                      >
                        {t("Close")}
                      </button>
                    </div>
                  </div>
                </div>
                {/* Footer */}
              </div>
            </div>
          )}
          {/* Alert modal box end  */}
        </>
      ) : (
        <Splitter
          product={product}
          getUserData={getUserData}
          isAlerttabOpen={isAlerttabOpen}
        />
      )}
      {/* <Splitter product={product} /> */}
    </>
  );
};
const Splitter = ({ product, getUserData, isAlerttabOpen }) => {
  const containerRef = React.useRef();
  const firstHalfRef = React.useRef();
  const secondHalfRef = React.useRef();
  const resizerRef = React.useRef();
  const [addbook, setbook] = useState({});
  const [multibook, setmultibook] = useState({});
  const [firstbook, setfirstbook] = useState([]);

  useEffect(() => {
    setfirstbook([product]);
  }, [product]);

  const handleMouseDown = React.useCallback((e: React.MouseEvent) => {
    const startPos = {
      x: e.clientX,
      y: e.clientY,
    };
    const currentLeftWidth = firstHalfRef.current.getBoundingClientRect().width;

    const handleMouseMove = (e: React.MouseEvent) => {
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      updateWidth(currentLeftWidth, dx);
      updateCursor();
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      resetCursor();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }, []);

  const handleTouchStart = React.useCallback((e: React.TouchEvent) => {
    const touch = e.touches[0];
    const startPos = {
      x: touch.clientX,
      y: touch.clientY,
    };
    const currentLeftWidth = firstHalfRef.current.getBoundingClientRect().width;

    const handleTouchMove = (e: React.TouchEvent) => {
      const touch = e.touches[0];
      const dx = touch.clientX - startPos.x;
      const dy = touch.clientY - startPos.y;
      updateWidth(currentLeftWidth, dx);
      updateCursor();
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
      resetCursor();
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  }, []);

  const updateWidth = (currentLeftWidth, dx) => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;

    if (!container || !firstHalfEle) {
      return;
    }

    const containerWidth = container.getBoundingClientRect().width;
    const delta = currentLeftWidth + dx;
    const newFirstHalfWidth = (delta * 100) / containerWidth;
    firstHalfEle.style.width = `${newFirstHalfWidth}%`;
  };

  const updateCursor = () => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;
    const resizerEle = resizerRef.current;
    const secondHalfEle = secondHalfRef.current;

    if (!container || !firstHalfEle || !resizerEle || !secondHalfEle) {
      return;
    }

    resizerEle.style.cursor = "ew-resize";
    document.body.style.cursor = "ew-resize";
    firstHalfEle.style.userSelect = "none";
    firstHalfEle.style.pointerEvents = "none";
    secondHalfEle.style.userSelect = "none";
    secondHalfEle.style.pointerEvents = "none";
  };

  const resetCursor = () => {
    const container = containerRef.current;
    const firstHalfEle = firstHalfRef.current;
    const resizerEle = resizerRef.current;
    const secondHalfEle = secondHalfRef.current;

    if (!container || !firstHalfEle || !resizerEle || !secondHalfEle) {
      return;
    }

    resizerEle.style.removeProperty("cursor");
    document.body.style.removeProperty("cursor");
    firstHalfEle.style.removeProperty("user-select");
    firstHalfEle.style.removeProperty("pointer-events");
    secondHalfEle.style.removeProperty("user-select");
    secondHalfEle.style.removeProperty("pointer-events");
  };

  return (
    <div dir="ltr" className="splitter" ref={containerRef}>
      <div className="splitter__first" ref={firstHalfRef}>
        {/* <CustomToolbar/> */}
        <Section2
          firsttab={firstbook}
          addTabs={addbook}
          multiSearchResult={multibook}
          setmultibook={setmultibook}
          alerttab={isAlerttabOpen}
        />
      </div>
      <div
        className="splitter__resizer"
        ref={resizerRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      />

      <div className="splitter__second w-full" ref={secondHalfRef}>
        <CustomVerticalSplitter
          setbook={setbook}
          setmultibook={setmultibook}
          getUserData={getUserData}
        />
      </div>
      {/*  */}
    </div>
  );
};
export default PDFViewer;

const CustomVerticalSplitter = ({ setbook, setmultibook, getUserData }) => {
  const { t } = useTranslation();
  const [word, setWord] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const [ocrCorrection, setOcrCorrection] = useState(false);
  const [ktivMaleChaser, setKtivMaleChaser] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [adjacent, setAdjacent] = useState(false);
  const [prefix, setPrefix] = useState(false);
  const [ordered, setOrdered] = useState(false);
  const [distance, setDistance] = useState(0);
  const [alternatives, setAlternatives] = useState("");
  const [exclude, setExclude] = useState("");
  const [wordCompletion, setWordCompletion] = useState("");
  const [firstPageOnly, setFirstPageOnly] = useState(false);
  const [searchDuration, setSearchDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const filteredBooks = pdfFiles.filter((book) => {
    const title = htmlToPlainText(book.title)?.toLowerCase() || "";
    const author = htmlToPlainText(book.auther)?.toLowerCase() || ""; // Assuming the correct spelling is 'author'
    return (
      title.includes(searchInput.toLowerCase()) ||
      author.includes(searchInput.toLowerCase())
    );
  });
  //   search interval
  const resetSearchState = () => {
    setResults([]);
    setProgress(0);
    setSearchDuration(0);
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };
  // search interval
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  const [category, setCategory] = useState([]);
  const [my_collection, setMyCollection] = useState([]);

  useEffect(() => {
    // Fetch filter options (authors, categories, topics, volumes) from the API

    fetchallCategory();
    fetchFilterOptions();
    const userdata = getUserData();
    fetchmycollectionlist(userdata);
    checkIfFavorite();
  }, []);
  const fetchallCategory = async () => {
    try {
      let url = "";

      // Check if value is null or undefined

      // Use the else case for fetching all books
      url = `${BASE_URL}/api_categories.php`;

      // Fetch data from the constructed URL
      const response = await axios.get(url);

      // Determine the correct book data based on value.type
      const bookData = response.data;

      // Log the book data for debugging purposes
      // console.log(bookData);

      // Validate that bookData is an array, then update the state
      // if (Array.isArray(bookData)) {
      setCategory(bookData);
      // } else {
      //   console.error("Expected an array of books, but received:", bookData);
      // }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };
  const fetchFilterOptions = async (value) => {
    try {
      const userdata = getUserData();
      let url = "";

      // Check if value is null or undefined
      if (value == null) {
        // Use the else case for fetching all books
        url = `${BASE_URL}/api_book.php/pdfbooks`;
      } else {
        // Dynamically construct URL based on the value type
        if (value.type === "my_collection") {
          url = `${BASE_URL}/api_mycollection.php?user_id=${userdata.id}&collection=mycollection`;
        } else if (value.type === "sub") {
          url = `${BASE_URL}/api_mycollection.php?user_id=${userdata.id}&subscription_id=${value.val}`;
        } else if (value.type == "category") {
          url = `${BASE_URL}/api_book.php/pdfbooks?category=${value.val}`;
        } else {
          url = `${BASE_URL}/api_book.php/pdfbooks`;
        }
      }

      // Fetch data from the constructed URL
      const response = await axios.get(url);

      // Determine the correct book data based on value.type
      const bookData =
        value && (value.type === "my_collection" || value.type === "sub")
          ? response.data.books
          : response.data;

      // Log the book data for debugging purposes
      // console.log(bookData);

      // Validate that bookData is an array, then update the state
      if (Array.isArray(bookData)) {
        setPdfFiles(bookData);
      } else {
        console.error("Expected an array of books, but received:", bookData);
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  const fetchmycollectionlist = async (userdata) => {
    try {
      let url = `${BASE_URL}/api_mycollection.php?user_id=${userdata.id}`;

      const response = await axios.get(url);
      const bookData = response.data.my_collection;
      // console.log(bookData);
      if (Array.isArray(bookData)) {
        setMyCollection(bookData);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };
  const handleWordChange = (event) => {
    setWord(event.target.value);
  };
  const [pdfData, setPdfData] = useState(null);
  const calculateTotalOccurrences = (res) => {
    return res.reduce((totalSum, item) => {
      const itemOccurrences = item.pages.reduce(
        (sum, page) => sum + page.occurrences,
        0
      );
      return totalSum + itemOccurrences;
    }, 0);
  };

  const [totalocc, settotaloc] = useState(0);
  const handleSearch = async () => {
    if (!word) {
      alert("Please enter a word to search");
      return;
    }
    resetSearchState();
    setLoading(true);
    setError("");

    const startTime = Date.now();

    const newIntervalId = setInterval(() => {
      setSearchDuration(((Date.now() - startTime) / 1000).toFixed(2));
    }, 1000);
    setIntervalId(newIntervalId);
    try {
      const v = await axios.post(`${NODE_BASE_URL}/search`, {
        word: word,
      });
        console.log(`results:`,v);
       setResults(v.data.results);
      settotaloc((v.data.totalOccurrences));
    } catch (err) {
      console.error("Error searching PDF files:", err);
      setError("An error occurred while searching the PDF files.");
    } finally {
      setLoading(false);
      clearInterval(newIntervalId); // Clear the interval when the search is complete
    }
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear interval on component unmount
      }
    };
  }, [intervalId]);

  const applyFilters = (text, word, filters) => {
    let matches = [];

    if (filters.ktivMaleChaser) {
      text = ktivMaleChaserTransform(text);
      word = ktivMaleChaserTransform(word);
    }

    if (filters.alternatives) {
      const alternatives = filters.alternatives.split("|");
      for (const alt of alternatives) {
        matches = matches.concat(searchWordInText(text, alt, filters));
      }
    } else {
      matches = searchWordInText(text, word, filters);
    }

    if (filters.exclude) {
      const excludeWords = filters.exclude.split(" ");
      matches = matches.filter((match) => {
        return !excludeWords.some((excludeWord) =>
          match.context.includes(excludeWord)
        );
      });
    }

    return matches;
  };

  const searchWordInText = (text, word, filters) => {
    let matches = [];
    let regexString = word;

    if (filters.wordCompletion) {
      regexString = regexString.replace(/\*/g, "\\w*").replace(/\?/g, "\\w");
    }

    if (filters.prefix) {
      regexString = "\\b" + regexString;
    }

    if (filters.adjacent) {
      regexString = "\\b" + regexString + "\\b";
    }

    const regex = new RegExp(regexString, "g");

    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push({
        index: match.index,
        context: text.substring(
          Math.max(0, match.index - 30),
          match.index + 30
        ),
        matchText: match[0], // The exact matched text
      });
    }

    return matches;
  };
  const ktivMaleChaserTransform = (text) => {
    const chaserToMaleMap = {
      א: "א", // Aleph
      ב: "ב", // Bet
      ג: "ג", // Gimel
      ד: "ד", // Dalet
      ה: "ה", // He
      ו: "ו", // Vav
      ז: "ז", // Zayin
      ח: "ח", // Het
      ט: "ט", // Tet
      י: "י", // Yod
      כ: "כ", // Kaf
      ך: "ך", // Final Kaf
      ל: "ל", // Lamed
      מ: "מ", // Mem
      ם: "ם", // Final Mem
      נ: "נ", // Nun
      ן: "ן", // Final Nun
      ס: "ס", // Samekh
      ע: "ע", // Ayin
      פ: "פ", // Pe
      ף: "ף", // Final Pe
      צ: "צ", // Tsadi
      ץ: "ץ", // Final Tsadi
      ק: "ק", // Qof
      ר: "ר", // Resh
      ש: "ש", // Shin
      ת: "ת", // Tav
    };

    let transformedText = text;
    for (const [chaser, male] of Object.entries(chaserToMaleMap)) {
      transformedText = transformedText.replace(new RegExp(chaser, "g"), male);
    }

    return transformedText;
  };

  const containerRef = useRef();
  const topPaneRef = useRef();
  const bottomPaneRef = useRef();
  const resizerRef = useRef();

  const handleMouseDown = useCallback((e) => {
    const startPos = {
      x: e.clientX,
      y: e.clientY,
    };
    const currentTopHeight = topPaneRef.current.getBoundingClientRect().height;

    const handleMouseMove = (e) => {
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      updateHeight(currentTopHeight, dy);
      updateCursor();
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      resetCursor();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }, []);

  const handleTouchStart = useCallback((e) => {
    const touch = e.touches[0];
    const startPos = {
      x: touch.clientX,
      y: touch.clientY,
    };
    const currentTopHeight = topPaneRef.current.getBoundingClientRect().height;

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const dx = touch.clientX - startPos.x;
      const dy = touch.clientY - startPos.y;
      updateHeight(currentTopHeight, dy);
      updateCursor();
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
      resetCursor();
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  }, []);

  const updateHeight = (currentTopHeight, dy) => {
    const container = containerRef.current;
    const topPane = topPaneRef.current;

    if (!container || !topPane) {
      return;
    }

    const containerHeight = container.getBoundingClientRect().height;
    const delta = currentTopHeight + dy;
    const newTopPaneHeight = (delta * 100) / containerHeight;
    topPane.style.height = `${newTopPaneHeight}%`;
  };

  const updateCursor = () => {
    const container = containerRef.current;
    const topPane = topPaneRef.current;
    const resizer = resizerRef.current;
    const bottomPane = bottomPaneRef.current;

    if (!container || !topPane || !resizer || !bottomPane) {
      return;
    }

    resizer.style.cursor = "ns-resize";
    document.body.style.cursor = "ns-resize";
    topPane.style.userSelect = "none";
    topPane.style.pointerEvents = "none";
    bottomPane.style.userSelect = "none";
    bottomPane.style.pointerEvents = "none";
  };
  const clearAllFilters = () => {
    setOcrCorrection(false);
    setKtivMaleChaser(false);
    setAdjacent(false);
    setPrefix(false);
    setOrdered(false);
    setDistance(0);
    setAlternatives("");
    setExclude("");
    setWordCompletion("");
    setFirstPageOnly(false);
  };
  const handleVolumeSelect = (selectedVolume) => {
    // Handle the volume selection logic here
    //  onClick={() => {
    //
    //  }}
    setmultibook([]);
    setbook(selectedVolume);
    // console.log("Selected Volume:", selectedVolume);
    // You can also update the state or perform other actions
  };

  const resetCursor = () => {
    const container = containerRef.current;
    const topPane = topPaneRef.current;
    const resizer = resizerRef.current;
    const bottomPane = bottomPaneRef.current;

    if (!container || !topPane || !resizer || !bottomPane) {
      return;
    }

    resizer.style.removeProperty("cursor");
    document.body.style.removeProperty("cursor");
    topPane.style.removeProperty("user-select");
    topPane.style.removeProperty("pointer-events");
    bottomPane.style.removeProperty("user-select");
    bottomPane.style.removeProperty("pointer-events");
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isthreeModalOpen, setIsthreeModalOpen] = useState(false);
  const [isfourModalOpen, setIsfourModalOpen] = useState(false);

  const openModal = () => {
    // setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const openBookModal = () => {
    setIsModalOpen(true);
    // Additional logic to perform when modal opens
  };

  const closeBookModal = () => {
    setIsModalOpen(false);
    // Additional logic to perform when modal closes
  };
  const openthreeBookModal = () => {
    setIsthreeModalOpen(true);
    // Additional logic to perform when modal opens
  };

  const closethreeBookModal = () => {
    setIsthreeModalOpen(false);
    // Additional logic to perform when modal closes
  };

  const openfourBookModal = () => {
    setIsfourModalOpen(true);
    // Additional logic to perform when modal opens
  };

  const closefourBookModal = () => {
    setIsfourModalOpen(false);
    // Additional logic to perform when modal closes
  };
  const [showDescription, setShowDescription] = useState("");

  const toggleDescription = (id) => {
    if (showDescription === id) {
      setShowDescription("");
    } else {
      setShowDescription(id);
    }
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [moredropdownOpen, setmoreDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const moretoggleDropdown = () => {
    setmoreDropdownOpen(!moredropdownOpen);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hovered1Index, setHovered1Index] = useState(null);

  const [contentIndex, setContentIndex] = useState(null);
  const [content1Index, setContent1Index] = useState(null);
  const handleMouseEnter = (index) => {
    setContentIndex(index);
  };

  const handleMouseLeave = () => {
    setContentIndex(null);
  };
  const handleMouse1Enter = (index) => {
    setContent1Index(index);
  };

  const handleMouse1Leave = () => {
    setContent1Index(null);
  };

  const filters = [
    {
      id: "Adjacent",
      label: t("Adjacent"),
      description: t("Adjacent_desc"),
      checked: adjacent,
      onChange: (e) => setAdjacent(e.target.checked),
    },
    {
      id: "Prefixes",
      label: t("prefix"),
      description: t("prefix_desc"),
      checked: prefix,
      onChange: (e) => setPrefix(e.target.checked),
    },
    {
      id: "Keepwords",
      label: t("keeps_words_in_order"),
      description: t("keep_desc"),
      checked: ordered,
      onChange: (e) => setOrdered(e.target.checked),
    },
    {
      id: "Distancebetween",
      label: t("distance_between"),
      description: t("distance_between_dsc"),
      input_desc: t("enter_max_dis"),
      checked: distance > 0,
      value: distance,
      onChange: (e) => setDistance(e.target.value),
    },
    {
      id: "Alternative",
      label: t("Alternative_words"),
      description: t("Alternative_words_desc"),
      checked: !!alternatives,
      value: alternatives,
      onChange: (e) => setAlternatives(e.target.value),
    },
    {
      id: "Exclude",
      label: t("Exclude_words"),
      description: t("Exclude_words_desc"),
      input_desc: t("Exclude_words_inpt"),
      checked: !!exclude,
      value: exclude,
      onChange: (e) => setExclude(e.target.value),
    },
    {
      id: "completion",
      label: t("Word_completion"),
      description: t("Word_completion_desc"),
      checked: !!wordCompletion,
      value: wordCompletion,
      onChange: (e) => setWordCompletion(e.target.value),
    },
    {
      id: "OCR",
      label: t("OCR_corrections"),
      description: t("OCR_corrections_desc"),
      checked: ocrCorrection,
      onChange: (e) => setOcrCorrection(e.target.checked),
    },
    {
      id: "Ktiv",
      label: t("Ktiv_Male"),
      description: t("Ktiv_Male_desc"),
      checked: ktivMaleChaser,
      onChange: (e) => setKtivMaleChaser(e.target.checked),
    },
    {
      id: "eachbook",
      label: t("Search_in_first_page"),
      description: t("Search_in_first_page_desc"),
      checked: firstPageOnly,
      onChange: (e) => setFirstPageOnly(e.target.checked),
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(null); // State for collapsible table
  const handleToggleCollapse = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle visibility
  };
  const [isFavorite, setIsFavorite] = useState({});
  const checkIfFavorite = async () => {
    const userData = getUserData();

    try {
      const res = await axios.get(
        `${BASE_URL}/api_book.php/favoritebooks?user_id=${userData.id}`
      );
      const favoriteBooks = res.data.reduce((acc, book) => {
        acc[book.book_id] = true;
        return acc;
      }, {});

      setIsFavorite(favoriteBooks);
    } catch (err) {
      console.error(err);
    }
  };
  const handleToggleFavorite = async (bookId) => {
    const userData = getUserData();

    if (isFavorite[bookId]) {
      // Remove from favorites logic
      try {
        const response = await axios.delete(
          `${BASE_URL}/api_book.php/favoritebooks/${bookId}`
        );

        if (response.status === 200) {
          alert("Book removed from favorites!");
          setIsFavorite((prevState) => ({ ...prevState, [bookId]: false }));
        } else {
          alert("Failed to remove book from favorites");
        }
      } catch (error) {
        alert("Failed to remove book from favorites. Please try again later.");
        console.error("Error removing book from favorites:", error);
      }
    } else {
      // Add to favorites logic
      try {
        const formData = new FormData();
        formData.append("user_id", userData.id);
        formData.append("book_id", bookId);

        const config = {
          method: "post",
          url: `${BASE_URL}/api_book.php/favoritebooks`,
          headers: { "Content-Type": "multipart/form-data" },
          data: formData,
        };

        const response = await axios(config);

        alert("Book added to favorites!");
        setIsFavorite((prevState) => ({ ...prevState, [bookId]: true }));
      } catch (error) {
        alert("Failed to add book to favorites. Please try again later.");
        console.error("Error adding book to favorites:", error);
      }
    }
  };

  return (
    <div className="custom-vertical-splitter splitter" ref={containerRef}>
      <div
        className="custom-vertical-splitter__top-pane splitter__pane"
        ref={topPaneRef}
      >
        <div className="w-full border-b-4">
          <div className="bg-gradient-to-r p-4 from-[#6D3815] to-[#3b200f] w-full">
            <div class="flex  w-full rounded-full">
              <div class=" w-[60%] ">
                <div className="relative ">
                  <label htmlFor="Search" className="sr-only">
                    {" "}
                    {t("search")}{" "}
                  </label>
                  <input
                    type="text"
                    id="Searchbooks"
                    placeholder={t("search_for")}
                    dir="rtl"
                    value={word}
                    onChange={handleWordChange}
                    className="w-full penn rounded-l-full focus-visible:outline-none	  py-2.5 px-2 shadow-sm sm:text-sm"
                  />

                  <span className="absolute left-0 inset-y-0 end-0 grid w-10 place-content-center">
                    <button
                      type="button"
                      className="text-red-600 hover:text-gray-700"
                      onClick={handleSearch}
                      disabled={loading}
                    >
                      <span className="sr-only">{t("search")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </button>
                  </span>
                </div>
              </div>
              <div class=" w-[40%] ">
                <select className="w-full rounded-r-full  border-black py-2.5 ocus-visible:outline-none  shadow-sm sm:text-sm">
                  <option>{t("search_in_all_book")}</option>
                  {/* <option>
        in the current list
      </option>
      <option>
        In indexes and keys 
      </option>
      <option>
        In indexes and keys 
      </option> */}
                </select>
              </div>
              {/* Button to open modal */}
            </div>
            <div className="flex w-full">
              <div className="w-[40%] ">
                <button onClick={openModal} className=" text-white  rounded">
                  {t("advance_search")}
                </button>
              </div>
              <div className="w-[60%] flex justify-end	">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                    checked={prefix}
                    onChange={() => setPrefix(!prefix)}
                  />
                  <label for="vehicle1" className="text-white text-sm mr-2">
                    {t("prefix")}
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                  />
                  <label for="vehicle1" className=" text-white text-sm ">
                    {t("Adjacent")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div className="mb-4 w-[80%] m-auto mt-2">
              <p>
                {" "}
                {t("Searching")}... {searchDuration} {t("seconds")}....
              </p>
              <div className="w-full bg-[#3b200f] h-2 rounded">
                <div
                  className="bg-[#3b200f] h-2 rounded"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          )}
          <div className="overflow-auto h-auto max-h-[200px]">
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <ImSpinner2 className="animate-spin text-gray-500 text-3xl" />
              </div>
            ) : results.length > 0 ? (
              <>
                {results.map((result, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedResult(result); // Update selected result
                    }}
                    onMouseEnter={() => setHoveredIndex(index)} // Track hovered index
                    onMouseLeave={() => setHoveredIndex(null)} // Reset hover state
                    className={`book-detail bg-[#e9e9e9] px-4 py-1 flex items-center justify-between hover:bg-gray-300 transition duration-300 ease-in-out relative ${
                      selectedResult === result ? "bg-gray-500 text-white" : ""
                    } ${index < results.length - 1 ? "border-b border-gray-300" : ""}`}
                  >
                    {/* Hover Separators */}
                    {hoveredIndex === index && (
                      <>
                        <div className="absolute top-0 left-0 w-full border-t border-gray-400"></div>
                        <div className="absolute bottom-0 left-0 w-full border-t border-gray-400"></div>
                      </>
                    )}

                    {/* Icons on the left */}
                    <div className="flex items-center space-x-2">
                      {/* <div className="relative">
                        <MdOutlinePrivacyTip
                          className="text-gray-600 hover:text-gray-800 cursor-pointer"
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        />
                        {contentIndex === index && (
                           <div className="absolute bg-white border border-gray-300 p-2 shadow-md rounded-md" style={{
                            top: "100%", // Position below the icon
                            left: 0, // Align with the left edge of the icon
                            zIndex: 9999, // Ensure it is on top of everything
                          }}>
                          
                           <p>
                             <strong>{t("book_name")}: </strong>
                             {htmlToPlainText(result.title)}
                           </p>
                           <p>
                             <strong>{t("author_name")}: </strong>
                             {htmlToPlainText(result.author) ?? " "}
                           </p>
                           <p>
                             <strong>{t("book_id")}:</strong>
                             {result.id ?? " "}
                           </p>
                         </div>
                        )}
                      </div> */}
                      {isFavorite[result.id] ? (
                        <FaStar
                          className="text-yellow-500 hover:text-yellow-800 cursor-pointer"
                          onClick={() => handleToggleFavorite(result.id)} // Remove from favorites
                        />
                      ) : (
                        <FaStar
                          className="text-yellow-200 hover:text-yellow-600 cursor-pointer"
                          onClick={() => handleToggleFavorite(result.id)} // Add to favorites
                        />
                      )}
                    </div>

                    {/* Book title */}
                    <button
                      dir="rtl"
                      className=""
                      onClick={() => {
                        setmultibook([]);
                        setbook(result);
                        setmultibook(result);
                      }}
                    >
                      {htmlToPlainText(result.title)} [{" "}
                      {result.pages.reduce(
                        (sum, page) => sum + page.occurrences,
                        0
                      )}{" "}
                      ]
                    </button>
                  </div>
                ))}

                {/* Summary of total results */}
              </>
            ) : (
              !loading &&
              results.length === 0 && (
                <p className="no-drop text-center p-4">
                  <span className="relative">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                  </span>
                  {t("no_result")}
                </p>
              )
            )}
          </div>

          <div className="text-gray-600 p-2 text-center">
            {totalocc} {t("results_in")} {results.length} {t("books")} |{" "}
            {results.length > 0 ? results[0].text : ""}
          </div>
        </div>

        {/* Modal */}
        {isOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Background overlay */}
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

            {/* Modal dialog */}
            <div className="relative w-[80%]  bg-white w-1/2 rounded-lg shadow-lg  pt-0">
              {/* Header */}
              <div
                dir="rtl"
                className="flex bg-[#E9E9E9] justify-between p-4 items-center "
              >
                <h2 className="text-lg font-semibold">{t("advance_search")}</h2>
                <button
                  onClick={closeModal}
                  className="text-gray-700 hover:text-gray-900"
                >
                  <svg
                    className="h-6 w-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              {/* Content */}
              <div
                dir="ltr"
                className="flex bg-gradient-to-r p-6 from-[#6D3815] to-[#3b200f] rounded-md w-full"
              >
                <div className=" w-[60%]">
                  {/* Search Input */}
                  <div className="relative">
                    <label htmlFor="Search" className="sr-only">
                      {t("search")}
                    </label>
                    <input
                      type="text"
                      id="Searchbooks"
                      placeholder={t("search_for")}
                      value={word}
                      onChange={handleWordChange}
                      dir="rtl"
                      className="w-full  rounded-l-full focus-visible:outline-none py-2.5 px-2 shadow-sm sm:text-sm"
                    />
                    <span className="absolute left-0 inset-y-0 end-0 grid w-10 place-content-center">
                      <button
                        type="button"
                        className="text-gray-600 hover:text-gray-700"
                      >
                        <span className="sr-only"> {t("search")}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </button>
                    </span>
                  </div>
                </div>
                <div className=" w-[40%]">
                  {/* Select Dropdown */}
                  <select className="w-full rounded-r-full border-black py-2.5 focus-visible:outline-none shadow-sm sm:text-sm">
                    <option>{t("search_in_all_book")}</option>
                    {/* <option>In the current list</option>
                    <option>In indexes and keys</option>
                    <option>In indexes and keys</option> */}
                  </select>
                </div>
              </div>
              <div dir="rtl" className="p-4 pb-0">
                <h2>{t("optimized_search_by")}</h2>
              </div>
              <div dir="rtl" className="overflow-auto h-[400px]">
                {filters.map((filter) => (
                  <Filter
                    key={filter.id}
                    id={filter.id}
                    label={filter.label}
                    toggleDescription={toggleDescription}
                    showDescription={showDescription}
                    description={filter.description}
                    input_desc={filter.input_desc}
                    onChange={filter.onChange}
                    checked={filter.checked}
                    value={filter.value}
                  />
                ))}
              </div>
              {/* Footer */}
              <div className="mt-4 bg-[#E9E9E9] p-4 flex justify-center">
                <button
                  className="px-4 py-2 rounded-full bg-gray-200 text-white hover:bg-red-800 bg-red-500 rounded-md focus:outline-none"
                  onClick={() => {
                    setIsOpen(false);
                    handleSearch();
                  }}
                  disabled={loading}
                >
                  {t("search")}
                </button>
                <button
                  className="px-4 py-2 ml-4 rounded-full bg-gray-200 text-white hover:bg-red-800 bg-red-500 rounded-md focus:outline-none"
                  onClick={clearAllFilters}
                >
                  {t("clear")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="custom-vertical-splitter__resizer splitter__resizer"
        ref={resizerRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      />
      <div
        className="custom-vertical-splitter__bottom-pane splitter__pane"
        ref={bottomPaneRef}
      >
        <div className="bg-gradient-to-r p-4 from-[#6D3815] to-[#3b200f] w-full">
          <div className="flex w-full rounded-full">
            {/* Button to open modal */}
            {/* <button
              type="button"
              onClick={openBookModal}
              className="text-white hover:text-gray-300 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
              <span className="sr-only">Open Modal</span>
            </button> */}

            <div className=" w-full">
              <div className="relative">
                <label htmlFor="Search" className="sr-only">
                  {t("search")}
                </label>
                <input
                  type="text"
                  id="Searchbooks"
                  placeholder={t("search_by_title")}
                  dir="rtl"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  className="w-full penn rounded-full focus:outline-none py-2.5 px-2 pr-8 shadow-sm sm:text-sm"
                />

                <span className="absolute right-0 inset-y-0 end-0 grid w-10 place-content-center">
                  <button
                    type="button"
                    className="text-red-600 hover:text-gray-700"
                  >
                    <span className="sr-only">Search</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </button>
                </span>
              </div>
            </div>

            {/* Modal component */}
            {isModalOpen && (
              <>
                {/* Background blur */}
                <div
                  className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm z-50"
                  onClick={closeBookModal}
                ></div>

                {/* Modal dialog */}
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="bg-white  rounded-lg shadow-lg w-full max-w-lg">
                    {/* Modal header */}
                    <div className="flex justify-between bg-[#E9E9E9] p-4 items-center mb-4">
                      <h2 className="text-lg font-semibold">
                        {" "}
                        {t("advance_details_book")}
                      </h2>
                      <button
                        type="button"
                        onClick={closeBookModal}
                        className="text-gray-600 hover:text-gray-700 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <span className="sr-only">{t("Close")}</span>
                      </button>
                    </div>

                    {/* Modal content */}
                    <div>
                      <p className="px-4 py-2">{t("enter_require_fields")}</p>
                      <div className="search-input p-4">
                        <div className="space-y-4">
                          {/* Book Name */}
                          <div>
                            <input
                              type="text"
                              placeholder={t("book_name")}
                              className="w-full rounded-full border border-gray-300 focus:border-gray-400 focus:outline-none py-2 px-4"
                            />
                          </div>

                          {/* Author Name */}
                          <div>
                            <input
                              type="text"
                              placeholder={t("arthor_name")}
                              className="w-full rounded-full border border-gray-300 focus:border-gray-400 focus:outline-none py-2 px-4"
                            />
                          </div>

                          {/* Year of Publication */}
                          <div>
                            <input
                              type="text"
                              placeholder={t("year_publication")}
                              className="w-full rounded-full border border-gray-300 focus:border-gray-400 focus:outline-none py-2 px-4"
                            />
                          </div>

                          {/* Place of Publication */}
                          <div>
                            <input
                              type="text"
                              placeholder={t("place_publication")}
                              className="w-full rounded-full border border-gray-300 focus:border-gray-400 focus:outline-none py-2 px-4"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Modal footer */}
                    <div className="mt-4 bg-[#E9E9E9] p-4 flex justify-center">
                      <button className="px-4 py-2 rounded-full bg-gray-200 text-white hover:bg-red-800 bg-red-500 rounded-md focus:outline-none">
                        {t("search")}
                      </button>
                      <button className="px-4 py-2 ml-4 rounded-full bg-gray-200 text-white hover:bg-red-800 bg-red-500 rounded-md focus:outline-none">
                        {t("clear")}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <hr className="mt-2"></hr>
          <div className="search-btm">
            {/* Button to open modal */}
            <button
              type="button"
              onClick={openthreeBookModal}
              className="text-white hover:text-gray-300 focus:outline-none"
            >
              <span className="sr-only">Open Modal</span>
            </button>
            <div className="flex mt-[-10px]">
              {/* <div className="ml-2 text-white">
                <button
                  type="button"
                  onClick={openfourBookModal}
                  className="text-white hover:text-gray-300 focus:outline-none"
                >
                  <FaFolderOpen />
                </button>
              </div> */}
              <div className="ml-2 border-r-2 pr-2 text-white relative">
                <IoIosArrowDropdownCircle
                  onClick={moretoggleDropdown}
                  className="cursor-pointer"
                />

                {/* Dropdown Menu */}
                {moredropdownOpen && (
                  <div className="absolute z-10 mt-2 w-48 bg-white rounded-lg shadow-lg">
                    <span className="text-black p-2">{t("sort_list_by")}</span>
                    <div className="py-1">
                      <p
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                        onClick={() => {
                          // console.log("All");
                          fetchFilterOptions({});
                          setmoreDropdownOpen(false);
                        }}
                      >
                        {t("all")}
                      </p>
                      {my_collection.map((item, index) => (
                        <p
                          key={index}
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                          onClick={() => {
                            // console.log(item);
                            fetchFilterOptions({
                              type:"sub",
                              val: item.id,
                            });
                            setmoreDropdownOpen(false);
                          }}
                        >
                          {item.title}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="ml-2 border-r-2 pr-2 text-white relative">
                <IoSettings
                  onClick={toggleDropdown}
                  className="cursor-pointer"
                />

                {/* Dropdown Menu */}
                {dropdownOpen && (
                  <div className="absolute z-10 mt-2 w-48 bg-white rounded-lg shadow-lg">
                    <span className="text-black p-2">
                      {t("sort_list_by_category")}
                    </span>
                    <div className="py-1 max-h-60 overflow-y-auto">
                      {" "}
                      {/* Added max-height and overflow */}
                      <p
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                        onClick={() => {
                          // console.log("All");
                          fetchFilterOptions({});
                          setDropdownOpen(false);
                          // setmoreDropdownOpen(false);
                        }}
                      >
                        {t("all")}
                      </p>
                      {category.map((item, index) => (
                        <p
                          key={index}
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                          onClick={() => {
                            // console.log(item);
                            fetchFilterOptions({
                              type: "category",
                              val: item.name,
                            });
                            // setmoreDropdownOpen(false);
                            setDropdownOpen(false);
                          }}
                        >
                          {item.name}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="m-auto">
                <p className="text-white">{t("full_list_of_books")}</p>
              </div>
              <div className="absolute border-l-2 pl-2 right-[20px]">
                {/* <input type="checkbox" name="select_all" /> */}
              </div>
            </div>

            {isthreeModalOpen && (
              <>
                {/* Background blur */}
                <div
                  className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm z-50"
                  onClick={closethreeBookModal}
                ></div>

                {/* Modal dialog */}
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="bg-white  rounded-lg shadow-lg w-full max-w-lg">
                    {/* Modal header */}
                    <div className="flex justify-between bg-[#E9E9E9] p-4 items-center mb-4">
                      <h2 className="text-lg font-semibold">
                        {" "}
                        {t("advance_details_book")}
                      </h2>
                      <button
                        type="button"
                        onClick={closethreeBookModal}
                        className="text-gray-600 hover:text-gray-700 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <span className="sr-only">Close Modal</span>
                      </button>
                    </div>

                    {/* Modal content */}
                    <div>
                      <p className="px-4 py-2">{t("enter_require_fields")}</p>
                      <div className="search-input p-4">
                        <div className="space-y-4"></div>
                      </div>
                    </div>

                    {/* Modal footer */}
                    <div className="mt-4 bg-[#E9E9E9] p-4 flex justify-center">
                      <button className="px-4 py-2 rounded-full bg-gray-200 text-white hover:bg-red-800 bg-red-500 rounded-md focus:outline-none">
                        {t("search")}
                      </button>
                      <button className="px-4 py-2 ml-4 rounded-full bg-gray-200 text-white hover:bg-red-800 bg-red-500 rounded-md focus:outline-none">
                        {t("clear")}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {isfourModalOpen && (
              <>
                {/* Background blur */}
                <div
                  className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm z-50"
                  onClick={closefourBookModal}
                ></div>

                {/* Modal dialog */}
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="bg-white  rounded-lg shadow-lg w-full max-w-2xl">
                    {/* Modal header */}
                    <div className="flex justify-between bg-[#E9E9E9] p-4 items-center mb-4">
                      <h2 className="text-lg font-semibold">
                        {" "}
                        {t("open_book_list")}
                      </h2>

                      <button
                        type="button"
                        onClick={closefourBookModal}
                        className="text-gray-600 hover:text-gray-700 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <span className="sr-only">Close</span>
                      </button>
                    </div>

                    {/* Modal content */}
                    <div>
                      <div className="relative mb-4 w-[80%] m-auto">
                        <label htmlFor="Search" className="sr-only">
                          {t("search")}
                        </label>
                        <input
                          type="text"
                          id="Searchbooks"
                          placeholder={t("search_for_book_or_author")}
                          dir="rtl"
                          className="w-full rounded-full border-2 focus:outline-none py-2.5 px-2 pr-8 shadow-sm sm:text-sm"
                        />

                        <span className="absolute right-0 inset-y-0 end-0 grid w-10 place-content-center">
                          <button
                            type="button"
                            className="text-red-600 hover:text-gray-700"
                          >
                            <span className="sr-only">Search</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="h-4 w-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </button>
                        </span>
                      </div>

                      <div className="bg-[#F4F4F4] p-4">
                        <span className="text-[12px]">
                          {t("System_folders_and_files")}
                        </span>
                        <div className="flex flex-wrap justify-center">
                          <div className="file-resp mx-4 my-2 text-center">
                            <FcOpenedFolder className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2">{t("basic_books")}</p>
                          </div>
                          <div className="file-resp mx-4 my-2 text-center">
                            <FcOpenedFolder className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2">{t("Meforshim")}</p>
                          </div>
                          <div className="file-resp mx-4 my-2 text-center">
                            <FcOpenedFolder className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2">{t("Subjects")}</p>
                          </div>
                          <div className="file-resp mx-4 my-2 text-center">
                            <FcOpenedFolder className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2"> {t("Repositories")}</p>
                          </div>
                          <div className="file-resp mx-4 my-2 text-center">
                            <FaBook className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2"> {t("Textbooks")}</p>
                          </div>
                          {/* Add more divs as needed to fill up the row */}
                        </div>
                      </div>

                      <div className=" mt-4 p-4">
                        <span className="text-[12px]">
                          {t("Personal_folders_and_files")}
                        </span>
                        <div className="flex flex-wrap my-4 justify-center">
                          <div className="file-resp mx-4 my-2 text-center">
                            <BiSolidBookHeart className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2">{t("Favorites")}</p>
                          </div>
                          <div className="file-resp mx-4 my-2 text-center">
                            <MdOutlineRecentActors className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2"> {t("Recent_books")}</p>
                          </div>
                          <div className="file-resp mx-4 my-2 text-center">
                            <LuBookPlus className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2">{t("Quick_access")}</p>
                          </div>
                          <div className="file-resp mx-4 my-2 text-center">
                            <BiSolidHide className="text-5xl hover:bg-[#ECECEC] hover:p-1 hover:rounded-md m-auto" />
                            <p className="mt-2"> {t("Hidden_books")}</p>
                          </div>
                          {/* Add more divs as needed to fill up the row */}
                        </div>
                      </div>

                      <div className="search-input p-4">
                        <div className="space-y-4"></div>
                      </div>
                    </div>

                    {/* Modal footer */}
                    <div className="mt-4 bg-[#E9E9E9] p-4 flex justify-center">
                      <button className="px-4 py-2 rounded-full bg-gray-200 text-white hover:bg-red-800 bg-red-500 rounded-md focus:outline-none">
                        {t("search")}
                      </button>
                      <button className="px-4 py-2 ml-4 rounded-full bg-gray-200 text-white hover:bg-red-800 bg-red-500 rounded-md focus:outline-none">
                        {t("clear")}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="max-h-96 overflow-y-auto">
          {filteredBooks.length > 0
            ? filteredBooks.map((result, index) => (
                <>
                  <div
                    key={index}
                    onClick={() => setSelectedResult(result)}
                    onMouseEnter={() => setHovered1Index(index)} // Track hovered index
    onMouseLeave={() => setHovered1Index(null)} // Reset hover state
                    className={`book-detail bg-[#e9e9e9] px-4 py-1 flex items-center justify-between hover:bg-gray-300 transition duration-300 ease-in-out relative ${
                      selectedResult === result ? "bg-gray-500 text-white" : ""
                    } ${index < filteredBooks.length - 1 ? "border-b border-gray-300" : ""}`}
                  >
                    {hovered1Index === index && (
      <>
        <div className="absolute top-0 left-0 w-full border-t border-gray-400"></div>
        <div className="absolute bottom-0 left-0 w-full border-t border-gray-400"></div>
      </>
    )}
                    {/* Icons on the left */}
                    <div className="flex items-center space-x-2">
                      <div>
                        <MdOutlinePrivacyTip
                          className="text-gray-600 hover:text-gray-800 cursor-pointer"
                          onMouseEnter={() => handleMouse1Enter(index)}
                          onMouseLeave={handleMouse1Leave}
                        />
                        {content1Index === index && (
                          <div
                            dir="rtl"
                            className="absolute  bg-white border border-gray-300 p-2 shadow-md rounded-md"
                            style={{
                              top: "100%", // Position below the icon
                              left: 0, // Align with the left edge of the icon
                              zIndex: 9999, // Ensure it is on top of everything
                            }}
                          >
                            {/* Content of your small dialog */}
                            <p>
                              <strong>{t("book_name")}: </strong>
                              {htmlToPlainText(result.title)}
                            </p>
                            <p>
                              <strong>{t("arthor_name")}: </strong>
                              {htmlToPlainText(result.auther) ?? " "}{" "}
                            </p>
                            <p>
                              <strong>pdf_location</strong>oz
                            </p>

                            <p>
                              <strong>{t("book_id")}:</strong>
                              {result.id ?? " "}
                            </p>
                          </div>
                        )}
                      </div>
                      {isFavorite[result.id] ? (
                        <FaStar
                          className="text-yellow-500 hover:text-yellow-800 cursor-pointer"
                          onClick={() => handleToggleFavorite(result.id)} // Remove from favorites on click
                        />
                      ) : (
                        <FaStar
                          className="text-yellow-200 hover:text-yellow-600 cursor-pointer"
                          onClick={() => handleToggleFavorite(result.id)} // Add to favorites on click
                        />
                      )}
                      {/* Toggle Button */}
                      {Array.isArray(result.volumes) &&
                      result.volumes.length > 0 ? (
                        <button
                          className="ml-2 p-1 text-gray-600 hover:text-gray-800 transition duration-300"
                          onClick={() => handleToggleCollapse(index)}
                        >
                          {expandedIndex === index ? (
                            <IoMdArrowDropup className="text-xl" />
                          ) : (
                            <IoMdArrowDropdown className="text-xl" />
                          )}
                        </button>
                      ) : (
                        <span className="text-gray-400"></span>
                      )}
                    </div>

                    {/* Book title */}
                    {Array.isArray(result.volumes) &&
                    result.volumes.length > 0 ? (
                      <span
                        dir="rtl"
                        className="flex-1 mr-2 ml-2"
                        onClick={() => handleToggleCollapse(index)}
                      >
                        <button>{htmlToPlainText(result.title)}</button>
                      </span>
                    ) : (
                      <span
                        dir="rtl"
                        className="flex-1 mr-2 ml-2"
                        onClick={() => {
                          setmultibook([]);
                          setbook(result);
                        }}
                      >
                        <button>{htmlToPlainText(result.title)}</button>
                      </span>
                    )}
                  </div>
                  {/* Collapsible Section */}
                  {expandedIndex === index && (
                    <div
                      dir="rtl"
                      className="px-4 py-1 items-center grid justify-between bg-gray-300 transition duration-300 ease-in-out"
                    >
                      {result.volumes.map((volume, volIndex) => (
                        <div className="hover:text-gray-500">
                          <span
                            key={volIndex}
                            className="volume-item "
                            onClick={() => handleVolumeSelect(volume)}
                          >
                            {htmlToPlainText(volume.title)}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ))
            : !loading && (
                <>
                  <p class=" text-center p-4">
                    <span className="relative">
                      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                      <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                    </span>
                    {t("no_result")}
                  </p>
                </>
              )}
        </div>
      </div>
    </div>
  );
};
const Filter = ({
  id,
  label,
  description,
  input_desc,
  toggleDescription,
  showDescription,
  onChange,
  checked,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="overflow-auto">
        <div className="filter-list border-b-4">
          <div className="filterz p-4 pb-2 flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="m-auto "
                id={id}
                name={id}
                checked={checked}
                onChange={onChange}
              />
              <label htmlFor={id} className="text-red ml-2 mr-2">
                {label}
              </label>
            </div>
            <div>
              <button onClick={() => toggleDescription(id)} className="ml-2">
                {showDescription === id ? (
                  <>{t("hide")}</>
                ) : (
                  <>{t("Learn_More")}</>
                )}
              </button>
            </div>
          </div>
          {showDescription === id && (
            <div className="p-4">
              <p className="text-[12px] ml-8 mt-2">{description}</p>
              {(id === "Exclude" ||
                id === "Distancebetween" ||
                id === "Alternative") && (
                <div className="p-4">
                  <p className="text-[12px] ml-8 mt-2">{input_desc}</p>
                  <input
                    type={id === "Distancebetween" ? "number" : "text"}
                    name={id}
                    className="ml-8 mt-2"
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                  />
                  <button className="px-2 py-1 rounded-full mt-2 text-white bg-[#6D3815]">
                    {t("submit")}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
// const Filter = ({ id, label, description, input_desc, onChange, checked, value }) => (
//     <div>
//       <label>
//         <input
//           type="checkbox"
//           id={id}
//           onChange={onChange}
//           checked={checked}
//         />
//         {label}
//       </label>
//       <p>{description}</p>
//       {input_desc && <p>{input_desc}</p>}
//       {id === 'Distancebetween' && (
//         <input
//           type="number"
//           value={value}
//           onChange={onChange}
//           placeholder="Enter maximum distance"
//         />
//       )}
//       {id === 'Alternative' && (
//         <input
//           type="text"
//           value={value}
//           onChange={onChange}
//           placeholder="Enter alternative words"
//         />
//       )}
//       {id === 'Exclude' && (
//         <input
//           type="text"
//           value={value}
//           onChange={onChange}
//           placeholder="Enter words to exclude"
//         />
//       )}
//     </div>
//   );
// const Filter = ({
//   id,
//   label,
//   description,
//   input_desc,
//   toggleDescription,
//   showDescription,
// }) => (
//   <div className="overflow-auto">
//     <div className="filter-list border-b-4">
//       <div className="filterz  p-4 pb-2 flex items-center justify-between">
//         <div className="flex items-center">
//           <input
//             type="checkbox"
//             className="m-auto"
//             id={id}
//             name={id}
//             value={id}
//           />
//           <label htmlFor={id} className="text-red ml-2">
//             {label}
//           </label>
//         </div>
//         <div>
//           <button onClick={() => toggleDescription(id)} className="ml-2">
//             {showDescription === id ? "hide" : "Learn More"}
//           </button>
//         </div>
//       </div>
//       {showDescription === id && (
//         <div className="p-4">
//           <p className="text-[12px] ml-8 mt-2">{description}</p>
//           {id === "Exclude" && id === "Distancebetween" (
//             <div className="p-4">
//               <p className="text-[12px] ml-8 mt-2">
//                {input_desc}
//               </p>
//               <input
//                 type="text"
//                 name={id}
//                 className="ml-8 mt-2"
//                 placeholder={label}
//               />
//               <button className="px-2 py-1 rounded-full mt-2 text-white bg-[#6D3815]">
//                 submit
//               </button>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   </div>
// );
