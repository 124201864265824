import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import { t } from "i18next";
import { PiCursorClick } from "react-icons/pi";
import { Markup } from "interweave";
import BASE_URL from "./constant";

import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ProductCard = ({product, id, image, title, price, symbol, discount, rating }) => {
  return (
    <div className="group flex flex-col items-center w-full gap-4 p-5 sm:p-6 border border-gray-300 shadow-md rounded-lg hover:shadow-lg transition-transform duration-300 transform hover:scale-105 bg-white">
      {/* Badge for Discount */}
      {discount && (
        <span className="absolute top-3 right-3 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
          {discount}% OFF
        </span>
      )}

      {/* Product Image */}

      <div>
        {image ? (
          <img
            src={image}
            alt={title || "Product Image"}
            loading="lazy"
            className="w-full h-[210px] object-contain rounded-lg hover:opacity-90 transition-opacity duration-300"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-[210px] bg-gray-100 text-gray-500 rounded-lg">
            <svg
              fill="#000000"
              width="800px"
              height="800px"
              viewBox="0 0 32 32"
              className="mr-[10px] ml-[10px] w-[90%] h-[210px] ml-7 mt-1.5 object-contain"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <style>{`.cls-1{fill:none;}`}</style>
              </defs>
              <title>no-image</title>
              <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z" />
              <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z" />
              <rect
                id="_Transparent_Rectangle_"
                data-name="&lt;Transparent Rectangle&gt;"
                className="cls-1"
                width="32"
                height="32"
              />
            </svg>
          </div>
        )}
      </div>
      {/* </Link> */}

      {/* Product Info */}
      <div className="px-4 py-3 w-full">
        {/* Title */}
        <div
          className={`group-hover:text-gray-600 transition-colors duration-300 overflow-hidden ${
            title.length < 20
              ? "text-lg"
              : title.length < 50
              ? "text-base"
              : "text-sm"
          }`}
          style={{
            height: title.length > 50 ? "3em" : "2.8em",
            lineHeight: "1.5em",
          }}
          dir="rtl"
        >
          <Markup content={title} />
        </div>

        {/* Price & Discount */}
        <div className="flex items-center justify-between mt-1">
          <p className="text-lg font-semibold text-gray-900" dir="ltr">
             {product.volumes_available ? (
                  <><span className="text-sm text-gray-500" dir="ltr">{`${t("starting_from")} `}{symbol} </span>{price}</>
                  ):(<><span className="text-sm text-gray-500" >{symbol}</span> {price}</>)}
          </p>
          {discount && (
            <p className="text-sm text-red-500 font-bold line-through">
              <span className="text-xs">{symbol}</span>{" "}
              {(price / (1 - discount / 100)).toFixed(2)}
            </p>
          )}
        </div>

        {/* Rating */}
        {rating && (
          <div className="flex items-center mt-2 text-yellow-500">
            {"★".repeat(rating)}
            <span className="ml-2 text-sm text-gray-600">
              {rating}/5 Ratings
            </span>
          </div>
        )}

        <div className="flex flex-col items-center gap-4">
          {/* View Button */}
          <div className="flex justify-center w-full">
            <Link to={`/pages/Product/${id}`} className="w-full max-w-[300px]">
              <button
                className="flex flex-row items-center justify-center text-center 
          cursor-pointer whitespace-nowrap rounded-[10px] gap-2 border border-red-800 sm:px-5 py-2 text-sm text-red-900 font-semibold hover:bg-red-800 hover:text-white transition-colors duration-300 w-full"
              >
                {t("view")}
                <PiCursorClick className="text-lg" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Component
const NewArrivalsSlider = () => {
  //   SwiperCore.use([Autoplay]); // Ensure Autoplay is globally applied

  // const swiperRef = useRef(null);

  // useEffect(() => {
  //   if (swiperRef.current) {
  //     console.log("Start Autoplay")
  //     swiperRef.current.swiper.autoplay.start();
  //   }
  // }, []);
  const [newArrivals, setNewArrivals] = useState([]);
  const [savedLangs, setSaveLangs] = useState(
    localStorage.getItem("language") || "he"
  );
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("currency") || "usd"
  );
  const [symbol, setSymbol] = useState("$");
  useEffect(() => {
    const svLang = localStorage.getItem("currency");
    setSaveLang(svLang);
    const svLangs = localStorage.getItem("language");
    setSaveLangs(svLangs);
    //  console.log(savedLang);
    //  setSymbol(svLang !== "ils" ? "$" : "₪");
    //  if (items.length == 0) {
    //    setPrice(svLang !== "ils" ? product.price : product.price_is);
    //  }
  }, []);
  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow next" onClick={onClick}>
      <FaChevronRight />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev" onClick={onClick}>
      <FaChevronLeft />
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 3840, settings: { slidesToShow: 8, slidesToScroll: 1 } }, // 8K
      { breakpoint: 2560, settings: { slidesToShow: 6, slidesToScroll: 1 } }, // 4K
      { breakpoint: 1440, settings: { slidesToShow: 5, slidesToScroll: 1 } }, // 2K
      { breakpoint: 1920, settings: { slidesToShow: 5, slidesToScroll: 1 } },
      { breakpoint: 1280, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 640, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 320, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  // useEffect(() => {
  //   console.log(SwiperCore);
  // }, []);

  useEffect(() => {
    const fetchNewArrivals = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api_book.php/newArrival`);
        if (Array.isArray(response.data.data)) {
          setNewArrivals(response.data.data);
        } else {
          console.error("Expected an array of books");
        }
      } catch (error) {
        console.error("Error fetching new arrivals:", error);
      }
    };

    fetchNewArrivals();
  }, []);

  return (
    <div
      className="container mx-auto py-6 "
      dir={savedLangs === "he" ? "rtl" : "ltr"}
    >
      <h2 className="text-2xl font-bold text-center mb-6 text-[#6D3815]">
        {t("new_arrival")}
      </h2>

      {/* <Swiper
     onSwiper={(swiper) => swiper.autoplay.start()}
     ref={swiperRef}
             modules={[Navigation, Pagination, Autoplay]}
            //  spaceBetween={20}
            //  slidesPerView={5}
             loop={true}
             navigation={true}
             pagination={{ clickable: true }}
             autoplay={{ delay: 3000, disableOnInteraction: false }}
             breakpoints={{
               320: { slidesPerView: 1,spaceBetween: 20 },
               640: { slidesPerView: 2,spaceBetween: 20 },
               1024: { slidesPerView: 3,spaceBetween: 20 },
               1280: { slidesPerView: 4,spaceBetween: 20 },
               1920: { slidesPerView: 5,spaceBetween: 20 },
               1440: { slidesPerView: 6, spaceBetween: 30 }, // 2K (1440p)
               2560: { slidesPerView: 7, spaceBetween: 35 }, // 4K (2160p)
               3840: { slidesPerView: 8, spaceBetween: 40 }, // 8K (4320p)

             }}
           >
        {newArrivals.map((product) => (
          <SwiperSlide key={product.id}>
            <ProductCard  
          key={product.id}
          id={product.book_id}
          image={product.images[0]}
          title={product.title}
          price={savedLang !== "ils" ? product.price : product.price_is}
          symbol={savedLang !== "ils" ? "$" : "₪"}
          />
          </SwiperSlide>
        ))}
      </Swiper> */}
      <Slider {...settings} className="custom-slider w-[95%] md:w-full m-auto" >

        {newArrivals.map((product) => (
          <div key={product.id} className="slide-item">
            <ProductCard
            product={product}
              id={product.book_id}
              image={product.images[0]}
              title={product.title}
              price={savedLang !== "ils" ? product.price : product.price_is}
              symbol={savedLang !== "ils" ? "$" : "₪"}
            />
          </div>
        ))}
        
      </Slider>
      
    </div>
  );
};

export default NewArrivalsSlider;
