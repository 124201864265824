import React, { useState, useContext, useEffect } from "react";
import { FaBookReader, FaReadme } from "react-icons/fa";
import { useTranslation } from 'react-i18next'; 
import axios from 'axios';
import BASE_URL from '../constant';
import { AuthContext } from "../context/AuthContext";
import { useNavigate} from "react-router-dom";
import htmlToPlainText from "../htmltotext";
import FlipCountdown from '@rumess/react-flip-countdown';
import { BsViewStacked } from "react-icons/bs";
import { FcViewDetails } from "react-icons/fc";
import { BiChevronDown, BiChevronUp, BiUpArrowCircle } from "react-icons/bi";
const MySubs = () => {
  const history = useNavigate();
  const { getUserData } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("purchased");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  
  const [purchasedBooks,setPurchaseBook ]= useState([ ]);
 
  useEffect(() => {
   const  userdata = getUserData();
    GetOrder(userdata);
  }, [getUserData]);

  const GetOrder = async (orderData) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_mycollection.php?user_id=${orderData.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const filteredCollection = response.data.my_collection.filter(item => item.id !== 0);
    console.log(filteredCollection);
      setPurchaseBook(filteredCollection);
      // setPurchaseBook(response.data.my_collection);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const  userdata = getUserData();
      const response = await axios.get(`${BASE_URL}/api_mycollection.php?user_id=${userdata.id}&subscription_id=${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order details fetched:', response.data);
      setSubscriptionDetails(response.data); // Set the specific order details
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const openModal =(subscription) => {
    setSelectedSubscription(subscription);
    setModalOpen(true);
    fetchOrderDetails(subscription.id); // Fetch the order details
    // setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSubscriptionDetails(null); // Clear order details when closing the modal
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const getTextAlignmentClass = () => {
    return i18n.language === 'he' ? 'text-right' : 'text-left';
  };
  return (
    <>
      <div className="flex px-4 justify-between mb-4">
        <h2 className="text-2xl font-bold">{t('my_subscriptions')}</h2>
      </div>

      {/* <div className="mb-8 px-4">
        <div className="flex">
          <button
            className={`mr-4 focus:outline-none ${activeTab === "purchased" ? "text-blue-600 text-xl border-b-2 border-blue-600 " : "text-l text-gray-500"}`}
            onClick={() => handleTabClick("purchased")}
          >
           {t('subscriptions')}
          </button>
        </div>
      </div> */}

      <div className="grid px-4 grid-cols-1 gap-8">
        {/* Purchased Books */}
        {activeTab === "purchased" && (
          <div>
            
            <div class="relative max-h-[600px] overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-black ">
      <thead class="text-xs text-black uppercase bg-[#fdebd1]">
          <tr className="bg-[#fdebd1] text-left">
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('name')}</th>
                  {/* <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('no_books')}</th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('category')}</th> */}
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('actions')}</th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('time_left')}</th> 
                </tr>
              </thead>
              <tbody>
  {purchasedBooks && purchasedBooks.length > 0 ? (
    purchasedBooks.map((subscription) => (
      <tr key={subscription.id} className="border-b border-black-800">
        <td className="py-2 px-4">{subscription.title}</td>
       
        <td className="py-4 px-4 flex">
          <button
            className="flex items-center hover:bg-[#733d17] justify-center focus:ring-4 rounded-lg shadow bg-[#3b200f] text-white px-3 py-1 rounded"
            onClick={() => openModal(subscription)}
          >
            <FaBookReader className="mr-1" />
            {t('read_books')}
          </button>

        </td>
        <td className="py-2 px-4"  style={{ direction: "ltr" }}><FlipCountdown
         size='small' 
         yearTitle='Year'
         hideYear
         hideMonth
                endAt={`${subscription.endDate}`} // Date/Time
            /></td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="5" className="text-center py-4">
        {t('noitemfound')}
      </td>
    </tr>
  )}
</tbody>

            </table>
            </div>
          </div>
        )}

        {/* Modal for displaying books */}
        {modalOpen && selectedSubscription && (
//           <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
//             <div className="bg-white p-8 rounded-lg w-3/4 max-w-3xl">
//               <h3 className="text-xl font-semibold mb-4">{selectedSubscription.title} Subscription </h3>
//               <div className="max-h-96 overflow-y-auto">
//               <ul>
//               {subscriptionDetails ? (
//               <div>
              
//                 <ul>
//                 {subscriptionDetails.books && subscriptionDetails.books.length > 0 ? ( // Check if books exists and has items
//               subscriptionDetails.books.map((book,index) => (
              
//                 <li key={book.id} className="py-2 px-4 border-b border-gray-300">
//                 <div className="flex justify-between items-center">
//                   <span>{htmlToPlainText(book.title)}</span>
//                   <div className="flex gap-2">
                   
//                     {book.volumes && book.volumes.length > 0? (
//                       <button
//                         className="bg-gray-500 text-white px-2 py-1 rounded"
//                         onClick={() => setIsExpanded(!isExpanded)}
//                       >
//                         {isExpanded ? <BiChevronUp/> : <BiChevronDown/>}
//                       </button>
//                     ): <button
//                     className="bg-red-300 text-white px-3 py-1 rounded"
//                     onClick={() => history(`/pdfViewer/${book.id}/${htmlToPlainText(book.title)}`)}
//                   >
// <FaReadme/>
//                   </button>}
//                   </div>
//                 </div>
          
//                 {/* Expandable List for Volumes */}
//                 {isExpanded && book.volumes && book.volumes.length > 0 && (
//                   <ul className="mt-2 ml-4 border-l border-gray-400">
//                     {book.volumes.map((volume, index) => (
//                       <li
//                         key={volume.id || index}
//                         className="py-1 px-2 flex justify-between items-center"
//                       >
//                         <span>{htmlToPlainText(volume.title)}</span>
//                         <button
//                           className="bg-red-300 text-white px-3 py-1 rounded"
//                           onClick={() => history(`/pdfViewer/${volume.id}/${htmlToPlainText(volume.title)}`)}
//                         >
//                          <FaReadme/>
//                         </button>
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </li>
//               ))
//               ) : (
//               <li>{t('noitemfound')}</li> // Fallback if no books are found
//               )}
//              </ul>
           
               
//               </div>
//                 ) : (
//               <p>{t('lodaingdetals')}</p>
//                )}
//                 {/* {subscriptionDetails.books.map((book, index) => (
//                   <li key={index} className="py-2 px-4 border-b border-gray-300 flex justify-between items-center">
//                     <span>{book}</span>
//                     <button className="bg-blue-500 text-white px-3 py-1 rounded">{t('read_books')}</button>
//                   </li>
//                 ))} */}
//               </ul>
//               </div>
//               <button className="mt-4 bg-gray-300 text-gray-800 px-4 py-2 rounded" onClick={closeModal}>
//                 {t('Close')}
//               </button>
//             </div>
//           </div>
<div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white shadow-xl rounded-xl p-6 w-full max-w-3xl">
        {/* Modal Header */}
        <div className="flex justify-between items-center border-b pb-3 mb-4">
          <h3 className="text-2xl font-bold text-gray-800">{selectedSubscription.title} </h3>
          <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
            ✖
          </button>
        </div>

        {/* Subscription Details */}
        <div className="max-h-96 overflow-y-auto">
          {subscriptionDetails ? (
            <ul className="space-y-3">
              {subscriptionDetails.books && subscriptionDetails.books.length > 0 ? (
                subscriptionDetails.books.map((book) => (
                  <li key={book.id} className="p-4 bg-gray-100 rounded-lg shadow-sm">
                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-800">{htmlToPlainText(book.title)}</span>
                      <div className="flex gap-2">
                        {book.volumes && book.volumes.length > 0 ? (
                          <button
                            className="bg-gray-600 text-white px-3 py-1 rounded-lg flex items-center transition-all hover:bg-gray-700"
                            onClick={() => setIsExpanded(isExpanded === book.id ? null : book.id)}
                          >
                            {isExpanded === book.id ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
                          </button>
                        ) : (
                          <button
                            className="bg-red-900 text-white px-3 py-1 rounded-lg flex items-center transition-all hover:bg-red-600"
                            onClick={() => history(`/pdfViewer/${book.id}/${htmlToPlainText(book.title)}`)}
                          >
                            <FaReadme size={18} /> 
                          </button>
                        )}
                      </div>
                    </div>

                    {/* Expandable List for Volumes */}
                    {isExpanded === book.id && book.volumes && book.volumes.length > 0 && (
                      <ul className="mt-2 ml-6 border-l-2 border-gray-300 pl-3 space-y-2">
                        {book.volumes.map((volume) => (
                          <li key={volume.id} className="flex justify-between items-center p-2 bg-gray-200 rounded-lg">
                            <span className="text-gray-700">{htmlToPlainText(volume.title)}</span>
                            <button
                              className="bg-red-900 text-white px-3 py-1 rounded-lg flex items-center transition-all hover:bg-red-600"
                              onClick={() => history(`/pdfViewer/${volume.id}/${htmlToPlainText(volume.title)}`)}
                            >
                              <FaReadme size={18}  /> 
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))
              ) : (
                <li className="text-center text-gray-500">{t('noitemfound')}</li>
              )}
            </ul>
          ) : (
            <p className="text-center text-gray-500">{t('lodaingdetals')}</p>
          )}
        </div>

        {/* Close Button */}
        <div className="mt-5 text-center">
          <button className="bg-gray-600 text-white px-5 py-2 rounded-lg transition-all hover:bg-gray-700" onClick={closeModal}>
            {t('Close')}
          </button>
        </div>
      </div>
    </div>
        )}
      </div>
    </>
  );
};

export default MySubs;
