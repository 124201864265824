import {
  FaUser,
  FaPhone,
  FaEnvelope,
  FaLock,
  FaCalendarAlt,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";
import React, { useState, useContext } from "react";
import { auth, firestore, provider } from "../firebase";

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  registerUser,
  handleGoogleLogin,
  saveUserDataToFirestore,
  getCountryFromIP,
  verifyUser,
} from "../services/AuthServices";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { t } from "i18next";

const Signup = () => {
  const navigate = useNavigate();
  const { saveUserData } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showconPassword, setShowconformPassword] = useState(false);

  // const [user, setUser] = useState(null);
  const [contact, setContact] = useState("+1");

  // const [otp,setOtp] = useState('');
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conpassword, setConPassword] = useState("");
  const [name, setName] = useState("");
  const handleGoogleLogins = async () => {
    await handleGoogleLogin(navigate, saveUserData);
  };
  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSend = async (event) => {
    event.preventDefault();

    if (!name) {
      toast.error("Please fill in the name field");
      return;
    }
    if (!email) {
      toast.error("Please fill in the email field");
      return;
    }
    if (!isValidEmail(email)) {
      toast.error("Please fill in a valid email address");
      return;
    }
    if (!password) {
      toast.error("Please fill in the password field");
      return;
    }

    if (!contact) {
      toast.error("Please fill in the phone field");
      return;
    }

    // setHasFilled(true);
    // generateRecaptcha();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("User registered successfully:", userCredential.user);

      // Save additional user data to Firestore
      let user = userCredential.user;
      // sendEmailVerification(user)
      // .then(() => {
      //   console.log('Email verification sent successfully');

      // })
      // .catch((error) => {
      //   console.error('Error sending email verification:', error);
      // });

      const userData = {
        action: "register",
        username: name,
        password: password,
        email: email,
        contact: contact,
        fb_id: userCredential.user.uid,
        address: address,
      };
      // console.log(userData);
      registerUser(userData)
        .then((response) => {
          if (response && response.status === 205) {
            console.log("Email already registered:", response.data.message);
            toast.error("Email already registered:", response.data.message);
          } else {
            console.log("User registered successfully:", response);
            saveUserDataToFirestore({
              contact: contact,
              email: email,
              name: name,
              uid: userCredential.user.uid,
            });
            sendWelcomeEmail({ email: email, name: name });
            saveUserData({ id: response.id, ...userData });
            toast.success("User registered successfully");
            navigate("/User");
          }
        })
        .catch((error) => {
          console.error("User registration failed:", error);
        });
      // registerUser(userData);
    } catch (error) {
      console.error("User registration failed:", error);
      if (error.code === "auth/email-already-in-use") {
        toast.error(
          "Email is already in use. Please try again with any other email ."
        ); // Handle specific error message
      } else {
        toast.error("User registration failed. Please try again.");
      }
    }
  };
  const sendWelcomeEmail = async (data) => {
    const emaildata = JSON.stringify({
      email: data.email,
      name: data.name,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://oizvehadar.com/mailjs/send-welcome-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: emaildata,
    };

    try {
      const response = await axios.request(config);
      console.log(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <div className="block md:flex items-center">
     
    
        <ToastContainer />
        <div className="md:flex hidden  items-center md:flex-col">
          {/* Image */}
          <img
            src="../images/login.svg"
            alt="login"
            className="w-full  h-full lg::w-[44%] lg:h-[832px] object-contain md:w-full"
          />
        </div>
        <div className="w-[95%] lg:w-1/2 bg-[#ffffff] rounded-2xl shadow-xl border border-gray-300 rounded-lg shadow-md p-4 m-4">
        <div
                  className="w-full h-[100px] "
                  // style={{
                  //   boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                  // }}
                >
                  <img
                    src="https://oizvehadar.com/api/uploads/images/logo.png"
                    className="rounded-t-2xl w-full h-full object-contain  "
                    alt="logo"
                  />
                  </div>
          <h1 className="text-center text-[40px]">{t("sign")}</h1>
          <div className="mt-4">
            {/* Full Name input with icon */}
            <div className="relative">
              <FaUser className="absolute top-5 left-3 text-red-900" />
              <input
                type="text"
                placeholder={t("full_name")}
                className="pl-10 w-full h-14 px-4 mb-3  border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/* Contact Number input with icon */}
            <div className="relative">
              <FaPhone className="absolute top-5 left-3 text-red-900" />
              <input
                type="text"
                placeholder={t("contact_no")}
                className="pl-10 w-full h-14 px-4 mb-3  border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
                onChange={(e) => setContact(e.target.value)}
              />
            </div>
            {/* Email input with icon */}
            <div className="relative">
              <FaEnvelope className="absolute top-5 left-3 text-red-900" />
              <input
                type="email"
                placeholder={t("email")}
                className="pl-10 w-full h-14 px-4 mb-3  border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* Password input with icon */}
            <div className="relative">
              <FaLock  className="absolute top-5 left-3 text-red-900" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder={t("password")}
                className="pl-10 w-full h-14 px-4 mb-3  border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Show password toggle button */}
              <button
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-5 right-3 focus:outline-none"
              >
                {showPassword ? <FaEye className=" text-red-900" /> : <FaEyeSlash />}
              </button>
            </div>
            {/* Confirm Password input with icon */}
            <div className="relative">
              <FaLock className="absolute top-5 left-3 text-red-900" />
              <input
                type={showconPassword ? "text" : "password"}
                placeholder={t("com_pass")}
                onChange={(e) => setConPassword(e.target.value)}
                className="pl-10 w-full h-14 px-4 mb-3  border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
              />
              {/* Show password toggle button */}
              <button
                onClick={() => setShowconformPassword(!showconPassword)}
                className="absolute top-6 right-3 focus:outline-none"
              >
                {showconPassword ? <FaEye className=" text-red-900" /> : <FaEyeSlash />}
              </button>
            </div>
            {/* Address input with icon */}
            <div className="relative">
              <FaMapMarkerAlt className="absolute top-5 left-3 text-red-900" />
              <input
                type="text"
                placeholder={t("address")}
                className="pl-10 w-full h-14 px-4 mb-3 border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            {/* Submit button */}
            {/* <button */}
            <p className="mb-0 mt-2 pt-1 text-sm font-semibold text-center">
              {t("already_account")}{" "}
              <Link
                to="/Login"
                className="text-red-500 transition duration-150 ease-in-out hover:text-red-600 focus:text-red-600 active:text-red-700"
              >
                {t("login")}
              </Link>
            </p>
            <br></br>
            <div className="flex justify-center items-center ">
            <button
              className="bg-red-800 text-white rounded-[14px] hover:bg-red-900 min-w-[154px] tracking-[0.44px] px-[34px] py-4"
              onClick={handleSend}
            >
              {t("submit")}
            </button>
          </div>
            {/* Login link */}
           
          </div>
        </div>
        <div className="md:hidden block  items-center md:flex-col">
     
     {/* Image */}
     <img
       src="../images/login.svg"
       alt="login"
       className="w-full  h-full lg::w-[44%] lg:h-[832px] object-contain md:w-full"
     />
   </div>
      </div>
    </>
  );
};

export default Signup;
