// import React, { useEffect, useState, useContext } from "react";
// import BASE_URL from "../constant";
// import axios from "axios";
// import { AuthContext } from "../context/AuthContext";
// import htmlToPlainText from "../htmltotext";
// import { useNavigate } from "react-router-dom";

// import { ToastContainer, toast } from "react-toastify";
// import { t } from "i18next";

// const Checkout = () => {
//   const navigate = useNavigate();
//   const [products, setProducts] = useState([]);
//   const [total, setTotal] = useState("0.00");
//   const [subtotal, setSubTotal] = useState("0.00");
//   const [shipping, setShipping] = useState("0.00");
//   const [product_cart, setProductCart] = useState([]);
//   const { cart, addToCart, removeFromCart, currentUser, getUserData } =
//     useContext(AuthContext);
//   const [savedLang, setSaveLang] = useState(
//     localStorage.getItem("currency") || "usd"
//   );
//   const [symbol, setSymbol] = useState("$");
//   const [direction, setDirection] = useState(
//     localStorage.getItem("language") === "he" ? "rtl" : "ltr"
//   );

//   useEffect(() => {
//     const svLangdir = localStorage.getItem("language");
//     const svLang = localStorage.getItem("currency");
//     setDirection(svLangdir === "he" ? "rtl" : "ltr");
//     setSaveLang(svLang);

//     setSymbol(svLang !== "ils" ? "$" : "₪");
//     fetchCart();
//   }, []);
//   const fetchCart = async () => {
//     try {
//       const userData = getUserData();
//       const response = await axios.get(
//         `${BASE_URL}/api_cart.php?action=detailed_cart&user_id=${userData.id}&currency=${savedLang}`
//       );
//       const bookData = response.data;
//       //console.log(response.data);

//       setProductCart(bookData.cart);
//       setProducts(bookData.cart.details);

//       const totalSum = bookData.cart.details.reduce((total, item) => {
//         const itemPrice = item.warehouse_quantity > 0 ? item.price : 0; // Choose the price based on language
//         return total + item.quantity * parseFloat(itemPrice); // Multiply quantity by price and add to total
//       }, 0);
//       setShipping(parseFloat(bookData.cart.base_shipping).toFixed(2));

//       setSubTotal(parseFloat(totalSum).toFixed(2));

//       setTotal(parseFloat(totalSum + bookData.cart.base_shipping).toFixed(2));
//       //  setTotal(bookData.cart.final_amount);
//     } catch (error) {
//       setProducts([]);

//       setTotal("0.00");
//       console.error("Error fetching books:", error);
//     }
//   };
//   const purchase = () => {
//     if (total > 0) {
//       // e.preventDefault();
//       console.log(product_cart);
//       if (currentUser) {
//         navigate("/pages/Stripe", {
//           state: {
//             product: product_cart,
//             amount: total,
//             type: "cart",
//             currency: savedLang,
//           },
//         });
//       } else {
//         toast.error(`Your need to login first`);
//       }
//     } else {
//       toast.error(`The total amount can not be processed`);
//     }
//     // addBundleToCart(pro);
//   };
//   return (
//     <div className="bg-gray-100 h-screen py-8">
//       <div className="container mx-auto px-4">
//         <h1 className="text-2xl font-semibold mb-4">{t("summry")}</h1>
//         <div className="flex flex-col md:flex-row gap-4">
//           <div className="md:w-3/4">
//             <div className="bg-white rounded-lg shadow-md p-6 mb-4">
//               <div className="overflow-x-auto max-h-96">
//                 <table className="w-full">
//                   <thead>
//                     <tr>
//                       <th
//                         className={`font-semibold ${
//                           direction === "rtl"
//                             ? "text-right px-2"
//                             : "text-left px-4"
//                         } `}
//                       >
//                         {t("product")}
//                       </th>
//                       <th
//                         className={`font-semibold ${
//                           direction === "rtl" ? "text-right" : "text-left"
//                         } px-4`}
//                       >
//                         {t("price")}
//                       </th>
//                       <th
//                         className={`font-semibold ${
//                           direction === "rtl" ? "text-right" : "text-left"
//                         } px-4`}
//                       >
//                         {t("quantity")}
//                       </th>
//                       <th
//                         className={`font-semibold ${
//                           direction === "rtl" ? "text-right" : "text-left"
//                         } px-4`}
//                       >
//                         {t("total")}
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {products.map((product) => (
//                       <tr key={product.id}>
//                         <td className="py-4">
//                           <div className="flex items-center">
//                             <img
//                               className="h-16 w-16 mr-4"
//                               src={product.images[0]}
//                               alt="Product image"
//                             />
//                             <span className="font-semibold">
//                               {htmlToPlainText(product.title)}
//                             </span>
//                           </div>
//                         </td>
//                         <td>
//                           <p
//                             className={`ml-4 ${
//                               product.warehouse_quantity > 0
//                                 ? ""
//                                 : "text-red-500"
//                             } text-sm`}
//                           >
//                             {product.warehouse_quantity > 0
//                               ? `${symbol} ${product.price}`
//                               : t("outofstock")}
//                           </p>
//                         </td>
//                         <td className="py-4">
//                           <div className="flex items-center">
//                             <span className="text-center w-8">
//                               {product.quantity}
//                             </span>
//                           </div>
//                         </td>
//                         <td>
//                           <p
//                             className={`ml-4 ${
//                               product.warehouse_quantity > 0
//                                 ? ""
//                                 : "text-red-500"
//                             } text-sm`}
//                           >
//                             {product.warehouse_quantity > 0
//                               ? `${symbol} ${(
//                                   product.quantity * product.price
//                                 ).toFixed(2)}`
//                               : t("outofstock")}
//                           </p>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//           <div className="md:w-1/4">
//             <div className="bg-white rounded-lg shadow-md p-6">
//               {/* <h2 className="text-lg font-semibold mb-4">Summary</h2> */}
//               <div className="flex justify-between mb-2">
//                 <span>{t("subtotal")}</span>
//                 <span>
//                   {symbol} {subtotal}
//                 </span>
//               </div>

//               {shipping > 0 && (
//                 <div className="flex justify-between mb-2">
//                   <span>{t("shipping_cost")}</span>
//                   <span>
//                     {symbol} {shipping}
//                   </span>
//                 </div>
//               )}

//               <hr className="my-2" />
//               <div className="flex justify-between mb-2">
//                 <span className="font-semibold">{t("total")}</span>
//                 <span className="font-semibold">
//                   {symbol} {total}
//                 </span>
//               </div>
//               <button
//                 className="bg-[#3b200f] text-white py-2 px-4 rounded-lg mt-4 w-full hover:bg-red-600"
//                 onClick={purchase}
//               >
//                 {t("pay_now")}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Checkout;
import React, { useEffect, useState, useContext } from "react";
import BASE_URL from "../constant";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import htmlToPlainText from "../htmltotext";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { t } from "i18next";
import { Input, Button } from "../component/theme";
import LoadingPopup from "./LoadingPopup";

const Checkout = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState("0.00");
  const [subtotal, setSubTotal] = useState("0.00");
  const [shipping, setShipping] = useState("0.00");
  const [product_cart, setProductCart] = useState([]);
  const { saveUserData, currentUser, getUserData, generateGuestCartId } =
    useContext(AuthContext);
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("currency") || "usd"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState("$");
  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "he" ? "rtl" : "ltr"
  );

  useEffect(() => {
    const svLangdir = localStorage.getItem("language");
    const svLang = localStorage.getItem("currency");
    setDirection(svLangdir === "he" ? "rtl" : "ltr");
    setSaveLang(svLang);

    setSymbol(svLang !== "ils" ? "$" : "₪");
    fetchCart();
  }, []);
  const fetchCart = async () => {
    try {
      const userData = getUserData();
      // console.log(userData.id);
      const userId = userData.id || generateGuestCartId();
      console.log(userId);

      const response = await axios.get(
        `${BASE_URL}/api_cart.php?action=detailed_cart&user_id=${userId}&currency=${savedLang}`
      );
      const bookData = response.data;
      //console.log(response.data);

      setProductCart(bookData.cart);
      setProducts(bookData.cart.details);

      const totalSum = bookData.cart.details.reduce((total, item) => {
        const itemPrice = item.warehouse_quantity > 0 ? item.price : 0; // Choose the price based on language
        return total + item.quantity * parseFloat(itemPrice); // Multiply quantity by price and add to total
      }, 0);
      setShipping(parseFloat(bookData.cart.base_shipping).toFixed(2));

      setSubTotal(parseFloat(totalSum).toFixed(2));

      setTotal(parseFloat(totalSum + bookData.cart.base_shipping).toFixed(2));
      //  setTotal(bookData.cart.final_amount);
    } catch (error) {
      setProducts([]);

      setTotal("0.00");
      console.error("Error fetching books:", error);
    }
  };
  useEffect(() => {
    const userData = getUserData();

    // Split shippingAddress and address into multiple fields using ',' as a separator
    const shippingParts = userData.shippingAddress
      ? userData.shippingAddress.split(".")
      : ["", "", "", ""];
    const billingParts = userData.address
      ? userData.address.split(".")
      : ["", "", "", ""];

    setFormData({
      id: userData.id,
      shippingStreet: shippingParts[0] || "",
      shippingCity: shippingParts[1] || "",
      shippingState: shippingParts[2] || "",
      shippingZip: shippingParts[3] || "",
      billingStreet: billingParts[0] || "",
      billingCity: billingParts[1] || "",
      billingState: billingParts[2] || "",
      billingZip: billingParts[3] || "",
      shippingAddress: userData.shippingAddress,
      address: userData.address,
      country: userData.country || "USA",
    });
  }, [getUserData]);

  const [formData, setFormData] = useState({
    id: "",
    shippingAddress: "",
    address: "",
    country: "USA",
    phone: "",
    firstName: "",
    lastName: "",
  });
  const purchase = async () => {
    if (parseFloat(subtotal) > 0) {
      // e.preventDefault();

      // console.log(product_cart);
      if (formData.country.toLowerCase() === "usa" && savedLang === "ils") {
        toast.error(
          "Orders from the USA cannot be placed in ILS (Israeli Shekel). Please select USD and Refreah the Page"
        );
        return;
      }

      if (formData.country.toLowerCase() === "israel" && savedLang === "usd") {
        toast.error(
          "Orders from Israel cannot be placed in USD. Please select ILS (Israeli Shekel) and Refreah the Page"
        );
        return;
      }

      if (currentUser) {
        if (product_cart.details.every((detail) => detail.book_id === null)) {
          console.log("All book_id values are null");
          navigate("/pages/Stripe", {
            state: {
              product: product_cart,
              amount: total,
              type: "cart",
              currency: savedLang,
            },
          });
          // Perform action when all book_id values are null
        } else {
          console.log("At least one book_id is not null");
          const isEmpty = (value) => !value || !value.trim();

          if (
            isEmpty(formData.shippingStreet) &&
            isEmpty(formData.shippingCity) &&
            isEmpty(formData.shippingState) &&
            isEmpty(formData.shippingZip) &&
            isEmpty(formData.billingStreet) &&
            isEmpty(formData.billingCity) &&
            isEmpty(formData.billingState) &&
            isEmpty(formData.billingZip)
          ) {
            alert(t("bothMissing"));
          } else if (
            isEmpty(formData.shippingStreet) ||
            isEmpty(formData.shippingCity) ||
            isEmpty(formData.shippingState) ||
            isEmpty(formData.shippingZip) ||
            isEmpty(formData.billingStreet) ||
            isEmpty(formData.billingCity) ||
            isEmpty(formData.billingState) ||
            isEmpty(formData.billingZip)
          ) {
            alert(t("someMissing"));
          } else {
            setIsLoading(true);
            console.log("Both addresses are provided.");
            setIsLoading(false);
            navigate("/pages/Stripe", {
              state: {
                product: product_cart,
                amount: total,
                type: "cart",
                currency: savedLang,
              },
            });
          }
          // Perform action when at least one book_id is not null
        }
      } else {
        setIsLoading(true);
        const isEmpty = (value) => !value || !value.trim();
        if (isEmpty(formData.firstName)) {
          alert("First name is required.");
          return;
        }

        if (isEmpty(formData.lastName)) {
          alert("Last name is required.");
          return;
        }

        if (isEmpty(formData.phone)) {
          alert("Phone number is required.");
          return;
        }

        if (
          isEmpty(formData.shippingStreet) &&
          isEmpty(formData.shippingCity) &&
          isEmpty(formData.shippingState) &&
          isEmpty(formData.shippingZip) &&
          isEmpty(formData.billingStreet) &&
          isEmpty(formData.billingCity) &&
          isEmpty(formData.billingState) &&
          isEmpty(formData.billingZip)
        ) {
          alert(t("bothMissing"));
          return;
        }

        if (
          isEmpty(formData.shippingStreet) ||
          isEmpty(formData.shippingCity) ||
          isEmpty(formData.shippingState) ||
          isEmpty(formData.shippingZip) ||
          isEmpty(formData.billingStreet) ||
          isEmpty(formData.billingCity) ||
          isEmpty(formData.billingState) ||
          isEmpty(formData.billingZip)
        ) {
          alert(t("someMissing"));
          return;
        }
        const userId = generateGuestCartId();
        // console.log("Both addresses are provided.");
        // navigate("/pages/Stripe", {
        //   state: {
        //     product: product_cart,
        //     amount: total,
        //     type: "cart",
        //     currency: savedLang,
        //   },
        // });
        // }
        const username = `GuestUser ${formData.firstName} ${formData.lastName}`;

        const combinedShippingAddress = `${formData.shippingStreet}. ${formData.shippingCity}. ${formData.shippingState}. ${formData.shippingZip}`;
        const combinedBillingAddress = `${formData.billingStreet}. ${formData.billingCity}. ${formData.billingState}. ${formData.billingZip}`;
        // const userData = ();
        // Update the formData object before sending the request
        const updatedFormData = {
          username: username,
          email: formData.email || "",
          contact: formData.phone,
          shippingAddress: combinedShippingAddress,
          address: combinedBillingAddress,
          country: formData.country,
          action: "guest_create",
        };

        const config = {
          method: "post",
          url: `${BASE_URL}/api_users.php`,
          data: updatedFormData,
        };
        axios(config)
          .then((response) => {
            console.log(response.data);

            migrateGuestCart(userId, response.data.id);

            // return response.data;
            // saveUserData(updatedFormData);
            // toast.success("User Updated Successfully");
          })
          .catch((error) => {
            console.error(error);
            toast.error("An error occurred while proceeding try again later");
            // return 0;
          });
        //  const v=   await   handlecreateSave();
        //  console.log(v);
        // toast.error(`Your need to login first`);
      }
    } else {
      toast.error(`The total amount can not be processed`);
    }
    // addBundleToCart(pro);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlecreateSave = () => {
    // e.preventDefault();
    const username = `${formData.firstName} ${formData.lastName}`;

    const combinedShippingAddress = `${formData.shippingStreet}. ${formData.shippingCity}. ${formData.shippingState}. ${formData.shippingZip}`;
    const combinedBillingAddress = `${formData.billingStreet}. ${formData.billingCity}. ${formData.billingState}. ${formData.billingZip}`;

    // Update the formData object before sending the request
    const updatedFormData = {
      username: username,
      email: formData.email || "",
      contact: formData.phone,
      shippingAddress: combinedShippingAddress,
      address: combinedBillingAddress,
      country: formData.country,
      action: "guest_create",
    };

    const config = {
      method: "post",
      url: `${BASE_URL}/api_users.php`,
      data: updatedFormData,
    };
    axios(config)
      .then((response) => {
        console.log(response);
        return response.data;
        // saveUserData(updatedFormData);
        // toast.success("User Updated Successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "An error occurred while updating the profile. Please try again."
        );
        return 0;
      });
  };
  const migrateGuestCart = async (guestCartId, userId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api_cart.php?action=migrate_cart`,
        {
          guest_cart_id: guestCartId,
          user_id: userId,
        }
      );

      if (response.data.status === "success") {
        const username = `${formData.firstName} ${formData.lastName}`;

        localStorage.setItem("guest_cart_id", userId);
        localStorage.setItem("guest_email", formData.email || "");
        localStorage.setItem("guest_username", username);
        console.log(
          localStorage.getItem("guest_cart_id"),
          localStorage.getItem("guest_email"),
          localStorage.getItem("guest_username")
        );
        setIsLoading(false);
        navigate("/pages/Stripe", {
          state: {
            product: product_cart,
            amount: total,
            type: "cart",
            currency: savedLang,
          },
        });

        // localStorage.removeItem("guest_cart_id");
        // fetchUserCart({ id: userId });
      }
    } catch (error) {
      console.error("Error migrating guest cart:", error);
    }
  };
  const handleSave = (event) => {
    // Save or process the user data as needed
    // const userDatas = {
    //   action:"update",
    //   id:uid,
    //   username: name,
    //   contact: mobileNumber,
    //   address: address
    // };
    event.preventDefault();

    const combinedShippingAddress = `${formData.shippingStreet}. ${formData.shippingCity}. ${formData.shippingState}. ${formData.shippingZip}`;
    const combinedBillingAddress = `${formData.billingStreet}. ${formData.billingCity}. ${formData.billingState}. ${formData.billingZip}`;

    // Update the formData object before sending the request
    const updatedFormData = {
      ...formData,
      shippingAddress: combinedShippingAddress,
      address: combinedBillingAddress,
      action: "update",
    };

    const config = {
      method: "post",
      url: `${BASE_URL}/api_users.php`,
      data: updatedFormData,
    };
    axios(config)
      .then((response) => {
        console.log(response);
        saveUserData(updatedFormData);
        toast.success("User Updated Successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "An error occurred while updating the profile. Please try again."
        );
      });
  };
  return (
    <div className="bg-gray-100  py-8">
   {isLoading && <LoadingPopup />}
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-semibold mb-4">{t("summry")}</h1>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="md:w-3/4">
            <div className="bg-white rounded-lg shadow-md py-4 px-2 md:p-6 mb-4">
              <div className="overflow-x-auto max-h-96">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th
                        className={`font-semibold ${
                          direction === "rtl"
                            ? "text-right px-2 md:px-2"
                            : "text-left px-2 md:px-4"
                        } `}
                      >
                        {t("product")}
                      </th>
                      <th
                        className={`font-semibold ${
                          direction === "rtl" ? "text-right" : "text-left"
                        } px-2 md:px-4`}
                      >
                        {t("price")}
                      </th>
                      <th
                        className={`font-semibold ${
                          direction === "rtl" ? "text-right" : "text-left"
                        } px-2 md:px-4`}
                      >
                        {t("quantity")}
                      </th>
                      <th
                        className={`font-semibold ${
                          direction === "rtl" ? "text-right" : "text-left"
                        } px-2 md:px-4`}
                      >
                        {t("total")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product) => (
                      <tr key={product.id}>
                        <td className="py-4">
                          <div className="flex items-center">
                            <img
                              className="h-16 w-16 mr-4"
                              src={product.images[0]}
                              alt="Product image"
                            />
                            <span className="font-semibold">
                              {htmlToPlainText(product.title)}
                            </span>
                          </div>
                        </td>
                        <td>
                          <p
                            className={`ml-4 ${
                              product.warehouse_quantity > 0
                                ? ""
                                : "text-red-500"
                            } text-sm`}
                          >
                            {product.warehouse_quantity > 0
                              ? `${symbol} ${product.price}`
                              : t("outofstock")}
                          </p>
                        </td>
                        <td className="py-4">
                          <div className="flex items-center">
                            <span className="text-center w-8">
                              {product.quantity}
                            </span>
                          </div>
                        </td>
                        <td>
                          <p
                            className={`ml-4 ${
                              product.warehouse_quantity > 0
                                ? ""
                                : "text-red-500"
                            } text-sm`}
                          >
                            {product.warehouse_quantity > 0
                              ? `${symbol} ${(
                                  product.quantity * product.price
                                ).toFixed(2)}`
                              : t("outofstock")}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {product_cart?.details?.length > 0 &&
              product_cart.details.some(
                (detail) => detail.book_id !== null // Ensure it's not an empty string
              ) &&
              (getUserData().id ? (
                <>
                  <h1 className="text-2xl font-semibold mb-4">
                    {t("shipping&billing")}
                  </h1>
                  <div className="bg-white rounded-lg shadow-md p-6 mb-4">
                    <div className="overflow-x-auto max-h-96"></div>
                    <form className="space-y-4">
                      <fieldset className="border p-4 rounded-md">
                        <legend className="font-medium">
                          {t("selectCountry")}
                        </legend>

                        <select
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        >
                          {/* <option value="">{t("selectCountry")}</option> */}
                          <option value="USA">USA</option>
                          <option value="Israel">Israel</option>
                        </select>
                      </fieldset>

                      {/* Shipping Address */}
                      <fieldset className="border p-4 rounded-md">
                        <legend className="font-medium">
                          {t("shippingAddress")}
                        </legend>

                        <Input
                          name="shippingStreet"
                          placeholder={t("street")}
                          value={formData.shippingStreet}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="shippingCity"
                          placeholder={t("city")}
                          value={formData.shippingCity}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="shippingState"
                          placeholder={t("state")}
                          value={formData.shippingState}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="shippingZip"
                          placeholder={t("postalCode")}
                          value={formData.shippingZip}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                      </fieldset>

                      {/* Billing Address */}
                      <fieldset className="border p-4 rounded-md">
                        <legend className="font-medium">
                          {t("billingAddress")}
                        </legend>

                        <Input
                          name="billingStreet"
                          placeholder={t("street")}
                          value={formData.billingStreet}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="billingCity"
                          placeholder={t("city")}
                          value={formData.billingCity}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="billingState"
                          placeholder={t("state")}
                          value={formData.billingState}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="billingZip"
                          placeholder={t("postalCode")}
                          value={formData.billingZip}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                      </fieldset>

                      {/* Buttons */}
                      <div className="flex justify-end space-x-2 mt-4">
                        <Button
                          onClick={handleSave}
                          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                        >
                          {t("save")}
                        </Button>
                      </div>
                    </form>

                    {/* <form className="space-y-4">

                          <label className="block font-medium">{t("shippingAddress")}</label>
                          <Input
                            name="shippingAddress"
                            value={formData.shippingAddress}
                            onChange={handleChange}
                          //   disabled={!isEditing}
                            className="p-2 border border-gray-300 rounded-md w-full"
                          />
              
                          <label className="block font-medium">{t("billingAddress")}</label>
                          <Input
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                          //   disabled={!isEditing}
                            className="p-2 border border-gray-300 rounded-md w-full"
                          />
                          <div className="flex justify-end space-x-2 mt-4">
                                        <Button
                                          onClick={handleSave}
                                          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                                        >
                                          {t("save")}
                                        </Button>
                                        
                                       
                                      </div>
                                      </form> */}
                  </div>{" "}
                </>
              ) : (
                <>
                  {/* <h1 className="text-2xl font-semibold mb-4">
                    {t("customerDetail")}
                  </h1> */}
                  <div className="bg-white rounded-lg shadow-md p-6 mb-4">
                    <div className="overflow-x-auto max-h-96"></div>
                    <form className="space-y-4">
                      {/* CUSTOMER Details*/}
                      <fieldset className="border p-4 rounded-md">
                        <legend className="font-medium">
                          {t("customerdetails")}
                        </legend>

                        <Input
                          name="firstName"
                          placeholder={t("firstName")}
                          value={formData.firstName}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="lastName"
                          placeholder={t("lastName")}
                          value={formData.lastName}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="email"
                          placeholder={t("email_optional")}
                          value={formData.email}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="phone"
                          placeholder={t("phone")}
                          value={formData.phone}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                      </fieldset>

                      {/* </form> */}

                      {/* <form className="space-y-4">

                          <label className="block font-medium">{t("shippingAddress")}</label>
                          <Input
                            name="shippingAddress"
                            value={formData.shippingAddress}
                            onChange={handleChange}
                          //   disabled={!isEditing}
                            className="p-2 border border-gray-300 rounded-md w-full"
                          />
              
                          <label className="block font-medium">{t("billingAddress")}</label>
                          <Input
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                          //   disabled={!isEditing}
                            className="p-2 border border-gray-300 rounded-md w-full"
                          />
                          <div className="flex justify-end space-x-2 mt-4">
                                        <Button
                                          onClick={handleSave}
                                          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                                        >
                                          {t("save")}
                                        </Button>
                                        
                                       
                                      </div>
                                      </form> */}

                      <fieldset className="border p-4 rounded-md">
                        <legend className="font-medium">
                          {t("selectCountry")}
                        </legend>

                        <select
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        >
                          {/* <option value="">{t("selectCountry")}</option> */}
                          <option value="USA">USA</option>
                          <option value="Israel">Israel</option>
                        </select>
                      </fieldset>

                      {/* Shipping Address */}
                      <fieldset className="border p-4 rounded-md">
                        <legend className="font-medium">
                          {t("shippingAddress")}
                        </legend>

                        <Input
                          name="shippingStreet"
                          placeholder={t("street")}
                          value={formData.shippingStreet}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="shippingCity"
                          placeholder={t("city")}
                          value={formData.shippingCity}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="shippingState"
                          placeholder={t("state")}
                          value={formData.shippingState}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="shippingZip"
                          placeholder={t("postalCode")}
                          value={formData.shippingZip}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                      </fieldset>

                      {/* Billing Address */}
                      <fieldset className="border p-4 rounded-md">
                        <legend className="font-medium">
                          {t("billingAddress")}
                        </legend>

                        <Input
                          name="billingStreet"
                          placeholder={t("street")}
                          value={formData.billingStreet}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="billingCity"
                          placeholder={t("city")}
                          value={formData.billingCity}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="billingState"
                          placeholder={t("state")}
                          value={formData.billingState}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                        <Input
                          name="billingZip"
                          placeholder={t("postalCode")}
                          value={formData.billingZip}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 mt-2 rounded-md w-full"
                        />
                      </fieldset>

                      {/* Buttons */}
                      {/* <div className="flex justify-end space-x-2 mt-4">
                        <Button
                          onClick={handlecreateSave}
                          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                        >
                          {t("save")}
                        </Button>
                      </div> */}
                    </form>

                    {/* <form className="space-y-4">

                          <label className="block font-medium">{t("shippingAddress")}</label>
                          <Input
                            name="shippingAddress"
                            value={formData.shippingAddress}
                            onChange={handleChange}
                          //   disabled={!isEditing}
                            className="p-2 border border-gray-300 rounded-md w-full"
                          />
              
                          <label className="block font-medium">{t("billingAddress")}</label>
                          <Input
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                          //   disabled={!isEditing}
                            className="p-2 border border-gray-300 rounded-md w-full"
                          />
                          <div className="flex justify-end space-x-2 mt-4">
                                        <Button
                                          onClick={handleSave}
                                          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                                        >
                                          {t("save")}
                                        </Button>
                                        
                                       
                                      </div>
                                      </form> */}
                  </div>{" "}
                </>
              ))}
          </div>
          <div className="md:w-1/4">
            <div className="bg-white rounded-lg shadow-md p-6">
              {/* <h2 className="text-lg font-semibold mb-4">Summary</h2> */}
              <div className="flex justify-between mb-2">
                <span>{t("subtotal")}</span>
                <span>
                  {symbol} {subtotal}
                </span>
              </div>

              {shipping > 0 && (
                <div className="flex justify-between mb-2">
                  <span>{t("shipping_cost")}</span>
                  <span>
                    {symbol} {shipping}
                  </span>
                </div>
              )}

              <hr className="my-2" />
              <div className="flex justify-between mb-2">
                <span className="font-semibold">{t("total")}</span>
                <span className="font-semibold">
                  {symbol} {total}
                </span>
              </div>
              <button
                className="bg-[#3b200f] text-white py-2 px-4 rounded-lg mt-4 w-full hover:bg-red-600"
                onClick={purchase}
              >
                {t("pay_now")}
              </button>
            </div>
          </div>
        </div>
      </div>
   
    </div>
  );
};

export default Checkout;
