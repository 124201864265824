import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import BASE_URL from "./constant";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import htmlToPlainText from "./htmltotext";
import { AuthContext } from "./context/AuthContext";

const MyBooks = () => {
  const { getUserData } = useContext(AuthContext);
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [slideData, setSlideData] = useState([]);
   const { i18n } = useTranslation();
    const isRtl = i18n.dir() === "rtl";
  

  useEffect(() => {
    const userdata = getUserData();
    fetchBooks(userdata);
  }, []);

  const fetchBooks = async (userdata) => {
    try {
      const url = `${BASE_URL}/api_book.php/favoritebooks?user_id=${userdata.id}`;
      const response = await axios.get(url);
      const bookData = response.data;
      if (Array.isArray(bookData)) {
        setSlideData(bookData);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  // const sliderData1 = (books) => {
  //   const slideData = books.map((book) => ({
  //     id: book.id,
  //     image: "../images/download.png",
  //     title: book.book_name,
  //     book_id: book.book_id,
  //   }));
  //   setSlideData(slideData);
  // };

  const { t } = useTranslation();

  return (
    <div className="relative px-0 md:px-8 my-5">
     

  
     
          
             <div
              
               className="flex flex-col rounded-2xl bg-white text-red-900 shadow-xl w-full "
             >
               {/* <img
             src={""}
             alt={subscription.title}
             className="rounded-t-2xl w-full h-40 object-cover"
           /> */}
               {/* <div
                 className="rounded-t-2xl bg-[#fce5c4c4] w-full h-40"
                 style={{
                   boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                 }}
               >
                 <img
                   src="https://oizvehadar.com/api/uploads/images/logo.png"
                   className="rounded-t-2xl w-full h-full object-contain"
                   alt="logo"
                 />
               </div> */}
               <div class="flex flex-col p-8 h-full">
                 <div class="text-3xl  font-bold pb-6">
                   {" "}
                   {t("my_books")}
                 </div>
                
               
                 <div className="flex flex-row justify-between items-center">
                   <div
                     className={`text-lg font-semibold mb-2 ${
                       isRtl ? "text-right" : "text-left"
                     }`}
                   >
                     {t("no_books")}:
                   </div>
                   {/* <br></br> */}
                   < Link to={`/User/favorites`} 
                     className={`text-m font-semibold mb-2 ${
                       isRtl ? "text-left" : "text-right"
                     }`}
                   >
                     {slideData.length}
                   </Link>
                 </div>

               
                 <div
                   className="flex flex-col gap-3 text-base overflow-y-auto "
                   style={{ height: "150px" }}
                 >
                   {slideData.map((book, index) => (
                     <Link to={`/pages/Product/${book.book_id}`} 
                    
                       key={index}
                       className="flex flex-row gap-2 items-center"
                     >
                       <img
                         src={book.images[0]}
                         alt={htmlToPlainText(book.book_name)}
                         className="w-16 h-16 object-contain" // Smaller image size
                       />
                       <button className="text-sm">
                         {htmlToPlainText(book.book_name)}
                       </button>{" "}
                     
                    
                     </Link>
                   ))}
                 </div>

                 <div class="flex flex-grow"></div>
                
               </div>
             </div>
             
          
        
 
  
    </div>
    
  );
};

export default MyBooks;
