import axios from "axios";
import { getAuth } from "firebase/auth";
 import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { FaEnvelope, FaKey, FaPassport } from 'react-icons/fa';
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import BASE_URL from "../constant";
import { t } from "i18next";


const Reset = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const oobCode = searchParams.get("oobCode");
    const email = searchParams.get("email");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
  
    useEffect(() => {
      const verifyCode = async () => {
        const auth = getAuth();
        console.log(email);
        try {
         await verifyPasswordResetCode(auth, oobCode);
        } catch (err) {
            toast.error("Invalid or expired reset code.");
        //   setError("Invalid or expired reset code.");
        }
      };
  
      if (oobCode) verifyCode();
      else toast.error("Missing reset code.");
      
    }, [oobCode]);
  


 
    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
          setError("Passwords do not match.");
          toast.error("Passwords do not match.");
          return;
        }
        const auth = getAuth();
      
      //   const handleUpdateProfile = () => {
          // Save or process the user data as needed
         
        await confirmPasswordReset(auth, oobCode, newPassword);
        const emaildata = JSON.stringify({
            action:"update_pass",
          email: email,
        //   oobCode:oobCode, 
          password:newPassword
        
        });
    
        
        const config = {
          method: 'post',
          url: `${BASE_URL}/api_users.php`,
          data: emaildata,
        };
      
        axios(config)
          .then((response) => {
              console.log(response.data);
              toast.success('Password reset successfully! Redirecting to login...');
                  setMessage("Password reset successfully! Redirecting to login...");
                  setTimeout(() => navigate("/Login"), 3000);
          })
          .catch((error) => {
            console.error(error);
            toast.error('Error occured while updating password');
          });
         
       
      };

 

  return (
    <>
        <div className="flex items-center">
        <ToastContainer />
     <div className="flex  items-center md:flex-col">
    
         {/* Image */}
         <img
           src="../images/resetpass.svg"
           alt="login"
           className="w-[44%] h-[832px] object-contain md:w-full"
         />
       </div>
        <div className="w-1/2 bg-[#ffffff] rounded-2xl shadow-xl border border-gray-300 rounded-lg shadow-md p-4 m-4">
        <div
                  className="w-full h-[100px] "
                  // style={{
                  //   boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                  // }}
                >
                  <img
                    src="https://oizvehadar.com/api/uploads/images/logo.png"
                    className="rounded-t-2xl w-full h-full object-contain  "
                    alt="logo"
                  />
                </div>
          <h1 className="text-center text-[40px]">{t("reset_pass")}</h1>
          <div className="mt-4">
            {/* Email input with icon */}
            <div className="relative">
              <FaKey className="absolute top-5 left-3 text-red-900" />
              <input
                type="password"
                placeholder={t("newpass")}
                name="email_One"
                    
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
               className="pl-10 w-full h-14 px-4 mb-3 border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
              />
            </div>
            <div className="relative">
              <FaKey className="absolute top-5 left-3 text-red-900" />
              <input
                type="password"
                placeholder={t("com_pass")}
                name="email_One"
                    
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
               className="pl-10 w-full h-14 px-4 mb-3 border border-red-900 shadow-xl gap-4 trackin[0.4px] rounded-[10px] py-2"
              />
            </div>
           
            {/* Submit button */}
            <div className="flex justify-center items-center ">
            <button
              className="bg-red-800 text-white rounded-[14px] hover:bg-red-900 min-w-[154px] tracking-[0.44px] px-[34px] py-4" onClick={ handleResetPassword}>
             {t("submit")}
            </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
