import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { IoSearch } from "react-icons/io5";
import _ from "lodash"; // Import lodash for debouncing
import BASE_URL from "./constant";
import htmlToPlainText from "./htmltotext";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
const SearchComponent = ({setclose}) => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [alllang, setAllLang] = useState(
    localStorage.getItem("language") || "en"
  );
  useEffect(() => {
    if (!query.trim()) {
      setSearchResults([]);
      setShowDropdown(false);
      return;
    }

    debouncedFetchBooks(query);

    return () => debouncedFetchBooks.cancel();
  }, [query]);
  // Fetch books with debounce (optimized)
  const fetchBooks = async (q) => {
    if (!q.trim()) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api_book.php/search-book?name=${q}`
      );
      const books = response.data.map((book) => ({
        id: book.id,
        name: htmlToPlainText(book.title),
        image: book.images?.[0] || "https://placehold.co/400",
      }));

      setSearchResults(books);
      setShowDropdown(true);
    } catch (error) {
      console.error("Error fetching books:", error);
    }
    setLoading(false);
  };

  // Debounce API calls
  const debouncedFetchBooks = _.debounce(fetchBooks, 300);

  useEffect(() => {
    debouncedFetchBooks(query);
    return () => debouncedFetchBooks.cancel(); // Cleanup on unmount
  }, [query]);
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setAllLang(svLang);
    console.log(svLang);
  });
  // Handle input change
  const handleChange = (e) => {
    console.log(e.target.value);
    setQuery(e.target.value);
    setSelectedIndex(-1);
  };

  // Handle selection
  const handleSelect = (book) => {
    navigate(`/pages/Product/${book.id}`);
    console.log(book.name);
    setclose(false);
    setQuery(book.name);
    setShowDropdown(false);
    console.log("Selected Book:", book);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prev) => Math.min(prev + 1, searchResults.length - 1));
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prev) => Math.max(prev - 1, 0));
    } else if (e.key === "Enter" && selectedIndex >= 0) {
      handleSelect(searchResults[selectedIndex]);
    }
  };

  // Close dropdown on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative w-full mx-auto mt-5" ref={dropdownRef}>
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t("search")}
          styling={{
            borderRadius: "12px", //
            hoverBackgroundColor: "#eee",
            boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
          }}
          className="w-full p-2 text-black border border-gray-300 rounded-lg focus:outline-none"
        />
        <IoSearch
          className={`absolute text-black top-3 ${
            alllang === "en" ? "right-2" : "left-2"
          }`}
        />
      </div>
      {loading && <p className="text-sm text-gray-500 mt-1">{t("loading")}</p>}

      {showDropdown && searchResults.length > 0 && (
        <ul className="absolute w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-60 overflow-y-auto shadow-lg">
          {searchResults.map((book, index) => (
            <li
              key={book.id}
              onClick={() => handleSelect(book)}
              className={`p-2 flex items-center cursor-pointer ${
                selectedIndex === index ? "bg-gray-200" : ""
              }`}
            >
              <img
                src={book.image}
                alt={book.name}
                className="w-10 h-10 mr-2 rounded"
              />
              <span className="text-black">{book.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

//   export default CustomAutocomplete;;

export default SearchComponent;
